export const VALID_GUESSES = [

    'abiejų',
    'abiems',
    'adreso',
    'adresu',
    'adresą',
    'agentė',
    'agentų',
    'aguonų',
    'agurko',
    'aikštė',
    'aikštę',
    'aistrų',
    'aiškus',
    'aiškūs',
    'akciją',
    'akcijų',
    'akcine',
    'akcinė',
    'akcinį',
    'akcizų',
    'akmens',
    'akmenį',
    'akmenų',
    'aktorė',
    'aktyvi',
    'aktyvą',
    'aktyvų',
    'albite',
    'aliejų',
    'alinės',
    'alkūne',
    'alučio',
    'alyvos',
    'ampulę',
    'amteli',
    'amžino',
    'amžinu',
    'amžiną',
    'amžinų',
    'amžius',
    'angelo',
    'angelą',
    'angelų',
    'anglis',
    'anglių',
    'anketą',
    'anketų',
    'ankstų',
    'ankšti',
    'ankštą',
    'anokie',
    'anomis',
    'antims',
    'antroj',
    'antros',
    'antrus',
    'anuose',
    'anūkių',
    'anūkus',
    'apdegė',
    'apdėjo',
    'apeigą',
    'apeigų',
    'apeitų',
    'apgins',
    'apgulė',
    'apgynė',
    'apimtį',
    'apmirė',
    'apniko',
    'apnikę',
    'aprimo',
    'aprims',
    'aprišk',
    'aprodė',
    'apsuko',
    'apsukę',
    'apsėdo',
    'apsėti',
    'aptemo',
    'aptiko',
    'aptikę',
    'apvers',
    'apvogė',
    'apvožė',
    'apėjom',
    'apėmęs',
    'apžėlę',
    'ardymo',
    'ardyti',
    'arenos',
    'arešto',
    'arklio',
    'arkliu',
    'arklių',
    'arklys',
    'armiją',
    'artelę',
    'artimo',
    'artimu',
    'artimą',
    'artimų',
    'artojų',
    'artumo',
    'artumą',
    'artėjo',
    'artėju',
    'asmenį',
    'asmenų',
    'atbuko',
    'atbėgo',
    'atbėgs',
    'ateinu',
    'ateivė',
    'ateivį',
    'atgijo',
    'atgiję',
    'atgimė',
    'atgins',
    'atguls',
    'atgulė',
    'atidus',
    'atidūs',
    'atkišo',
    'atkišę',
    'atkūrė',
    'atleis',
    'atlenk',
    'atliko',
    'atliks',
    'atlikę',
    'atlošę',
    'atlygį',
    'atlėgo',
    'atlėks',
    'atmeni',
    'atmenu',
    'atmynė',
    'atneši',
    'atnešk',
    'atnešė',
    'atnešę',
    'atomus',
    'atrodė',
    'atsegė',
    'atsekė',
    'atspėk',
    'atsuko',
    'atvedė',
    'atvedę',
    'atvejo',
    'atveju',
    'atvejį',
    'atvejų',
    'atvers',
    'atvesk',
    'atvežk',
    'atvežė',
    'atviru',
    'atvirą',
    'atvirų',
    'atvyko',
    'atvykę',
    'atvėrę',
    'atvėso',
    'atėjom',
    'atėjus',
    'atėjęs',
    'atšilo',
    'atšilę',
    'atšipo',
    'audinį',
    'audros',
    'audėją',
    'audėjų',
    'augimo',
    'augimą',
    'augino',
    'augint',
    'auglių',
    'auglys',
    'auklės',
    'aukoti',
    'aukšte',
    'aukšti',
    'aukšto',
    'aukšto',
    'aukštą',
    'aukštą',
    'aukštį',
    'aukštų',
    'aukštų',
    'aulėje',
    'austrų',
    'avieną',
    'avinus',
    'ašmenų',
    'aštrią',
    'aštrių',
    'aštrus',
    'aštrūs',
    'bacilų',
    'baigtį',
    'baigus',
    'baigėm',
    'baigęs',
    'bailys',
    'baimės',
    'bajorų',
    'bakūžė',
    'baldus',
    'baleto',
    'baletą',
    'balinė',
    'balius',
    'balsio',
    'balsių',
    'balsui',
    'baltom',
    'baltos',
    'baltus',
    'balute',
    'bandos',
    'bandyk',
    'bandys',
    'bandėm',
    'bandęs',
    'bangos',
    'bankui',
    'bankus',
    'barsto',
    'barzdą',
    'baržos',
    'basoną',
    'baudos',
    'baugūs',
    'bausme',
    'bausmė',
    'bausmę',
    'bausti',
    'bazinė',
    'bazinę',
    'bazėje',
    'bazėms',
    'bazėse',
    'begali',
    'begalė',
    'begalę',
    'beimąs',
    'bekojį',
    'bekonų',
    'belgas',
    'beliko',
    'belikę',
    'bendra',
    'bendri',
    'bendro',
    'bendru',
    'bendrą',
    'bendrų',
    'bendrų',
    'bepigu',
    'bernai',
    'bernas',
    'bernui',
    'beržai',
    'beržas',
    'beržus',
    'betoną',
    'bijoma',
    'bijome',
    'bijota',
    'biraus',
    'biržas',
    'biržoj',
    'biržos',
    'bitelė',
    'biuras',
    'bjauri',
    'bjaurų',
    'blizga',
    'bliūdu',
    'blogam',
    'blogas',
    'blogis',
    'blogus',
    'blokas',
    'blokui',
    'blokus',
    'blužnį',
    'blyksi',
    'blynas',
    'blynus',
    'blėsta',
    'blėsti',
    'blūdui',
    'bokalą',
    'bokšte',
    'bokštu',
    'bokštą',
    'bokštų',
    'bortus',
    'bosnių',
    'botagą',
    'braižo',
    'braižė',
    'brangi',
    'brangu',
    'brangų',
    'brenda',
    'bričką',
    'brokas',
    'brolis',
    'broliu',
    'brolių',
    'bronza',
    'bronzą',
    'bruožą',
    'bruožų',
    'brydės',
    'brėško',
    'brėžia',
    'bręsta',
    'budina',
    'budrūs',
    'budėti',
    'bufeto',
    'bukais',
    'bukina',
    'bulves',
    'bulvių',
    'bulvėm',
    'bulvės',
    'buožės',
    'burnai',
    'burnas',
    'burnoj',
    'burnos',
    'buroke',
    'burtai',
    'burtis',
    'butelį',
    'buvimo',
    'buvimą',
    'buvome',
    'bylant',
    'byloja',
    'byloje',
    'byrėti',
    'bėdoję',
    'bėgant',
    'bėgius',
    'bėglių',
    'bėglys',
    'bėgsim',
    'bėgsiu',
    'bėgyje',
    'būdais',
    'būdami',
    'būdavo',
    'būdavę',
    'būdelė',
    'būgnai',
    'būkime',
    'būkite',
    'būklei',
    'būklės',
    'būname',
    'būrelį',
    'būrimo',
    'būryje',
    'būsena',
    'būseną',
    'būsenų',
    'būsima',
    'būsime',
    'būsimo',
    'būsimu',
    'būsimą',
    'būsimų',
    'būsite',
    'būstai',
    'būstui',
    'būties',
    'būtina',
    'būtiną',
    'būtinų',
    'būtume',
    'būtyje',
    'būčiau',
    'centai',
    'centro',
    'centru',
    'centrą',
    'centrų',
    'centus',
    'chaosą',
    'choras',
    'chorui',
    'chorus',
    'ciklas',
    'ciklus',
    'cirkas',
    'cirozė',
    'colinę',
    'cypimo',
    'daigus',
    'daigus',
    'daikto',
    'daiktu',
    'daiktą',
    'daiktų',
    'dailus',
    'dailės',
    'dainoj',
    'dainom',
    'dainos',
    'dairos',
    'dalgis',
    'dalgiu',
    'dalgių',
    'dalies',
    'dalijo',
    'dalims',
    'daline',
    'dalino',
    'dalins',
    'dalybų',
    'dalyje',
    'dalyko',
    'dalyku',
    'dalyką',
    'dalykų',
    'dalyse',
    'dalyti',
    'dalyve',
    'dangos',
    'dangtį',
    'danguj',
    'dangum',
    'dangus',
    'dantis',
    'dantys',
    'darbui',
    'darbus',
    'darius',
    'darius',
    'darnių',
    'darnus',
    'daromi',
    'daromą',
    'darosi',
    'darymą',
    'darysi',
    'daryti',
    'darytų',
    'darėsi',
    'daržus',
    'datyvo',
    'daubos',
    'daužei',
    'daužyk',
    'davinį',
    'davusi',
    'davėme',
    'davėsi',
    'dažnio',
    'dažnis',
    'dažniu',
    'dažnos',
    'dažnus',
    'dažosi',
    'dažysi',
    'dažyti',
    'dažytą',
    'debitą',
    'degalų',
    'degant',
    'degimų',
    'degina',
    'degino',
    'degint',
    'deguto',
    'degėsi',
    'deivės',
    'dejonė',
    'dejonę',
    'dejuos',
    'dekano',
    'delnai',
    'delnus',
    'delsia',
    'delsti',
    'deltos',
    'demonų',
    'dengia',
    'dengti',
    'dengtų',
    'dengęs',
    'deramo',
    'deramą',
    'dergsi',
    'dergęs',
    'derina',
    'derint',
    'derinu',
    'derlių',
    'dermės',
    'dervos',
    'derybų',
    'derėjo',
    'derėtų',
    'detalė',
    'detalę',
    'devyni',
    'dešimt',
    'dešinę',
    'dešinę',
    'dešinį',
    'dešros',
    'dienas',
    'dienom',
    'dienos',
    'dietas',
    'dietos',
    'dievas',
    'dievop',
    'dievus',
    'dilema',
    'dimsty',
    'dinarą',
    'dinarų',
    'dingtų',
    'dingęs',
    'dirbam',
    'dirbat',
    'dirbau',
    'dirbom',
    'dirbta',
    'dirbto',
    'dirbtų',
    'dirbtų',
    'dirbęs',
    'dirvos',
    'diržas',
    'diržus',
    'dobilų',
    'dolerį',
    'domina',
    'dorais',
    'dorinį',
    'dovaną',
    'dovanų',
    'dramos',
    'drauge',
    'draugo',
    'draugą',
    'draugė',
    'draugę',
    'draugų',
    'drebia',
    'driežų',
    'drimba',
    'drobių',
    'drobės',
    'drovus',
    'drožti',
    'drugio',
    'drugių',
    'drugys',
    'druska',
    'druską',
    'druskų',
    'druzgo',
    'drąsiu',
    'drąsių',
    'drėgmę',
    'drėgna',
    'drėgni',
    'drėgno',
    'drėgną',
    'drūtas',
    'dubens',
    'dubenį',
    'duetas',
    'dugnas',
    'dujoms',
    'dukras',
    'dukrom',
    'dukros',
    'dulkes',
    'dulkių',
    'dulkės',
    'dumble',
    'dumblo',
    'dunksi',
    'dunkso',
    'duobes',
    'duobti',
    'duobėn',
    'duobės',
    'duokit',
    'duoklė',
    'duoklę',
    'duotas',
    'durklą',
    'durnas',
    'durpes',
    'durpių',
    'durpės',
    'duryse',
    'duslių',
    'dušais',
    'dvasią',
    'dvasių',
    'dvejas',
    'dvejus',
    'dvelkė',
    'dviejų',
    'dvynio',
    'dvynių',
    'dygaus',
    'dygimo',
    'dykuma',
    'dykumą',
    'dykumų',
    'dėjome',
    'dėjosi',
    'dėkoja',
    'dėkoju',
    'dėmelę',
    'dėmesį',
    'dėsnio',
    'dėsniu',
    'dėsnių',
    'dėtyje',
    'dėtųsi',
    'dėžutę',
    'dūksta',
    'dūlinu',
    'dūlėja',
    'dūmais',
    'dūmiau',
    'dūsavo',
    'dūzgia',
    'džiazo',
    'džinas',
    'džinsų',
    'džiugų',
    'džiūva',
    'eglutė',
    'eglutę',
    'egzotų',
    'eidavo',
    'eigulį',
    'eilutė',
    'eilutę',
    'eilėms',
    'ekipos',
    'ekrano',
    'ekraną',
    'ekranų',
    'elgtis',
    'elgtis',
    'elgėsi',
    'emalio',
    'emaliu',
    'epocha',
    'epochą',
    'epochų',
    'erdvių',
    'erdvės',
    'ertmių',
    'erzina',
    'esanti',
    'esantį',
    'eskimų',
    'esminę',
    'esminį',
    'esybių',
    'etapui',
    'etapus',
    'etikos',
    'etinės',
    'failus',
    'fakelu',
    'faktus',
    'fasoną',
    'fazinę',
    'fazėje',
    'fermas',
    'fermos',
    'figūra',
    'figūrą',
    'figūrų',
    'filmas',
    'filmus',
    'filtre',
    'filtro',
    'filtrą',
    'filtrų',
    'finale',
    'finalo',
    'fiordų',
    'firmas',
    'firmos',
    'fobiją',
    'fokusą',
    'fondai',
    'fondas',
    'fondui',
    'fondus',
    'fordas',
    'fordui',
    'formai',
    'formas',
    'fortai',
    'fortas',
    'forumą',
    'forumų',
    'frankų',
    'frazių',
    'frazės',
    'fricas',
    'fricus',
    'fronte',
    'furija',
    'gabeno',
    'gabens',
    'gabumų',
    'gadinu',
    'gadynė',
    'gadynę',
    'gaidys',
    'gaires',
    'gairės',
    'gaisro',
    'gaisrą',
    'gaisrų',
    'gaivus',
    'gaivūs',
    'galime',
    'galimą',
    'galimų',
    'galine',
    'galinė',
    'galinę',
    'galinį',
    'galios',
    'galios',
    'galite',
    'galvoj',
    'galvok',
    'galvom',
    'galvon',
    'galvos',
    'galvot',
    'galybė',
    'galybę',
    'galėjo',
    'galėję',
    'galėsi',
    'galėtų',
    'galūnė',
    'galūnę',
    'gamino',
    'gamins',
    'gaminį',
    'gamtoj',
    'gamton',
    'gamtos',
    'gamybą',
    'gandro',
    'gandrų',
    'gandus',
    'ganyti',
    'garbei',
    'garbės',
    'gardus',
    'garnių',
    'garnys',
    'garsią',
    'garsių',
    'gatvei',
    'gatves',
    'gatvių',
    'gatvėj',
    'gatvėm',
    'gatvėn',
    'gatvės',
    'gaubto',
    'gaubtą',
    'gaubtą',
    'gaujos',
    'gausim',
    'gausių',
    'gausmą',
    'gautos',
    'gavimo',
    'gavome',
    'gavosi',
    'gavusi',
    'gavėjo',
    'gašlių',
    'gedimo',
    'gedimu',
    'gedimų',
    'gedulo',
    'geibus',
    'geismų',
    'gelbėk',
    'gelbės',
    'gelbėt',
    'geldos',
    'gelmių',
    'gelmėn',
    'gelsti',
    'gelsva',
    'gelsvu',
    'gelsvą',
    'gelsvų',
    'gemalo',
    'genijų',
    'gentys',
    'genčių',
    'gerais',
    'gerbia',
    'gerbiu',
    'gerbti',
    'gerbėm',
    'gerina',
    'gerklė',
    'gerklę',
    'gerklų',
    'geroji',
    'geroka',
    'gerovė',
    'gerovę',
    'gersit',
    'gerumo',
    'gerumą',
    'gervių',
    'gervės',
    'gerąja',
    'gerąjį',
    'gerėja',
    'gesint',
    'giedra',
    'giedrą',
    'giesmė',
    'giesmę',
    'gilaus',
    'gilumo',
    'gilumą',
    'gilužę',
    'gilėja',
    'gimdos',
    'gimsta',
    'gimtas',
    'gimtos',
    'gimtus',
    'gimusį',
    'ginklo',
    'ginklu',
    'ginklą',
    'ginklų',
    'ginčas',
    'ginčus',
    'girdyk',
    'girdės',
    'girdėt',
    'girnas',
    'girnos',
    'gitarą',
    'glaudi',
    'glaudė',
    'glaudų',
    'glebus',
    'gležni',
    'gležną',
    'glismo',
    'glismu',
    'globai',
    'globia',
    'glokai',
    'glostė',
    'glėbin',
    'glėbio',
    'godulį',
    'gojaus',
    'gorila',
    'gotika',
    'govėda',
    'grafos',
    'graikų',
    'graužė',
    'gražiu',
    'gražią',
    'gražių',
    'gražus',
    'gražūs',
    'greita',
    'greito',
    'greitu',
    'greitą',
    'greitį',
    'gresia',
    'gretas',
    'gretos',
    'griebk',
    'griebs',
    'griebė',
    'grieką',
    'griežt',
    'griežė',
    'grindų',
    'griovą',
    'griovė',
    'griovį',
    'griovų',
    'gripas',
    'griuvo',
    'griūna',
    'griūtį',
    'griūva',
    'grobas',
    'grobis',
    'grojau',
    'grokit',
    'grosiu',
    'grotas',
    'grožis',
    'grožiu',
    'grubūs',
    'grunto',
    'gruntą',
    'gruntų',
    'gruodą',
    'gruodį',
    'grupei',
    'grupes',
    'grupių',
    'grupėj',
    'grupės',
    'gruzda',
    'gružlį',
    'grybai',
    'grybas',
    'grybus',
    'grynas',
    'grynos',
    'grąžos',
    'grąžto',
    'grėsme',
    'grėsmę',
    'grėsti',
    'gręžia',
    'gręžti',
    'grįsto',
    'grįstą',
    'grįšim',
    'grįšiu',
    'grįžai',
    'grįžau',
    'grįžot',
    'grįžta',
    'grįžti',
    'grįžti',
    'grįžtu',
    'grįžtų',
    'grįžus',
    'grįžęs',
    'grūdai',
    'grūdus',
    'grūšio',
    'gudrūs',
    'gulbių',
    'gulbės',
    'gulkis',
    'gultai',
    'gultis',
    'gulėjo',
    'gulėsi',
    'gulėti',
    'gumbai',
    'guminę',
    'guminį',
    'guolis',
    'guolių',
    'gydant',
    'gydoma',
    'gydome',
    'gydomi',
    'gydosi',
    'gydėsi',
    'gynimo',
    'gynėjų',
    'gynėsi',
    'gyrimą',
    'gyrėsi',
    'gyvais',
    'gyvatė',
    'gyvatę',
    'gyvena',
    'gyveni',
    'gyvenk',
    'gyveno',
    'gyvens',
    'gyvent',
    'gyvenu',
    'gyvenę',
    'gyvius',
    'gyvoji',
    'gyvulį',
    'gyvuok',
    'gyvuos',
    'gyvąja',
    'gyvūno',
    'gyvūną',
    'gyvūnų',
    'gėlyne',
    'gėlynų',
    'gėlyte',
    'gėralo',
    'gėriau',
    'gėrimo',
    'gėrimu',
    'gėrimą',
    'gėrimų',
    'gūbrys',
    'gūdžią',
    'gūnios',
    'gūžtos',
    'herojė',
    'herojų',
    'hienos',
    'hindus',
    'hiperį',
    'husarų',
    'idealo',
    'idealu',
    'idėjas',
    'idėjos',
    'ieškau',
    'ieškos',
    'ieškot',
    'ilgesį',
    'ilgoje',
    'ilgumo',
    'ilgumą',
    'ilgėja',
    'imdavo',
    'indams',
    'indelį',
    'indėlį',
    'inkaro',
    'inkarą',
    'inkarų',
    'inksto',
    'inkstų',
    'intakų',
    'irzlus',
    'islamo',
    'ispanų',
    'išalkę',
    'išaugo',
    'išaugs',
    'išbalo',
    'išbers',
    'išburs',
    'išbuvo',
    'išbyra',
    'išbėga',
    'išbėgo',
    'išbūna',
    'išbūrė',
    'išdavė',
    'išdavę',
    'išduos',
    'išdygo',
    'išdygs',
    'išdygt',
    'išdūmė',
    'išgerk',
    'išgers',
    'išgert',
    'išgrūs',
    'išgėrę',
    'išjunk',
    'iškalt',
    'iškasė',
    'iškels',
    'iškepa',
    'iškeps',
    'iškept',
    'iškyla',
    'išlesa',
    'išlupa',
    'išlygų',
    'išlūžo',
    'išmano',
    'išmanė',
    'išmesk',
    'išmest',
    'išmeta',
    'išmetė',
    'išmetę',
    'išmoks',
    'išmokt',
    'išmokė',
    'išmokę',
    'išmokų',
    'išmonę',
    'išmėžt',
    'išnyko',
    'išnyks',
    'išnykę',
    'išnyra',
    'išorėn',
    'išorės',
    'išplak',
    'išpuvo',
    'išpylė',
    'išrado',
    'išrovė',
    'išsakė',
    'išsamų',
    'išseko',
    'išsuko',
    'ištakų',
    'ištars',
    'ištarė',
    'išteka',
    'išteku',
    'ištepa',
    'ištyrė',
    'ištyrę',
    'ištįso',
    'išugdė',
    'išvadą',
    'išvadų',
    'išvalė',
    'išvarė',
    'išvedė',
    'išveža',
    'išvežu',
    'išvežė',
    'išvežę',
    'išvogė',
    'išvydo',
    'išvydę',
    'išvyko',
    'išvyks',
    'išvykę',
    'išvyta',
    'išvytą',
    'išėjau',
    'išėjus',
    'išėjęs',
    'išžudė',
    'jankių',
    'japonų',
    'jaspio',
    'jaukių',
    'jaunos',
    'jausmo',
    'jausmą',
    'jausmų',
    'jausti',
    'jaustų',
    'jautei',
    'jautri',
    'jaučio',
    'jaučių',
    'jodavę',
    'jogais',
    'jokiam',
    'jokias',
    'jokius',
    'josite',
    'jovalu',
    'jovalą',
    'judesį',
    'judimo',
    'judino',
    'judrūs',
    'judėti',
    'judėtų',
    'jungia',
    'jungti',
    'jungtį',
    'junkis',
    'juodam',
    'juodas',
    'juokai',
    'juokas',
    'juokės',
    'juosta',
    'juostą',
    'juostų',
    'juslių',
    'jutimo',
    'jutimų',
    'jėgoms',
    'kabiną',
    'kadrus',
    'kaimus',
    'kainos',
    'kairys',
    'kairėj',
    'kairės',
    'kairės',
    'kaitos',
    'kajutę',
    'kalboj',
    'kalbos',
    'kalbės',
    'kalbėt',
    'kalcio',
    'kalcis',
    'kalijų',
    'kalinį',
    'kalnus',
    'kaltes',
    'kaltos',
    'kaltus',
    'kaltės',
    'kalvio',
    'kalvių',
    'kalvon',
    'kalvos',
    'kalėjo',
    'kalėsi',
    'kalėti',
    'kamerą',
    'kaminu',
    'kaminą',
    'kampui',
    'kampus',
    'kamštį',
    'kanojų',
    'kanopų',
    'kančių',
    'kaplių',
    'kaplys',
    'kaponę',
    'kapoti',
    'kapotą',
    'karmos',
    'karosą',
    'karste',
    'karsto',
    'karstą',
    'karstų',
    'kartis',
    'kartos',
    'kartui',
    'kartus',
    'kartūs',
    'karvei',
    'karves',
    'karvių',
    'karvės',
    'karčią',
    'karčių',
    'karšto',
    'karštu',
    'karštį',
    'karštų',
    'kasetė',
    'kasimo',
    'katino',
    'katinų',
    'katėms',
    'kaupti',
    'kauptų',
    'kausto',
    'kavinę',
    'kavutę',
    'kazusų',
    'kaštus',
    'keblią',
    'kedais',
    'kefyrą',
    'keista',
    'keistų',
    'kelias',
    'kelias',
    'keliom',
    'kelios',
    'kelius',
    'kelmai',
    'kelmas',
    'kelmus',
    'kelnių',
    'kelnėm',
    'kelnės',
    'keltas',
    'keltis',
    'kelutį',
    'kenčia',
    'kenčiu',
    'kepalą',
    'kepalų',
    'kepant',
    'kepimo',
    'kepina',
    'kepsnį',
    'keptas',
    'keptos',
    'kepurė',
    'kepurę',
    'kerams',
    'keroja',
    'kertėj',
    'kertėn',
    'kertės',
    'keršto',
    'kerštu',
    'keršys',
    'ketaus',
    'ketina',
    'ketino',
    'ketinu',
    'keturi',
    'kevalo',
    'kevalu',
    'kečujų',
    'kiaulė',
    'kiaulę',
    'kiaunė',
    'kiaunę',
    'kiaurą',
    'kiaurų',
    'kiaute',
    'kiautą',
    'kibucų',
    'kiemas',
    'kiemus',
    'kietas',
    'kietos',
    'kilmės',
    'kiloja',
    'kilpos',
    'kilusį',
    'kimšos',
    'kipšas',
    'kirpta',
    'kirsta',
    'kitame',
    'kitoje',
    'kitoms',
    'kitose',
    'kitybę',
    'kišenė',
    'kišenę',
    'klaidą',
    'klaidų',
    'klajos',
    'klampi',
    'klasei',
    'klasių',
    'klasėj',
    'klausi',
    'klauso',
    'klaust',
    'klausė',
    'klevai',
    'klevas',
    'kliudė',
    'kliuvo',
    'klizmą',
    'kliūtį',
    'kliūtų',
    'kliūva',
    'klodai',
    'klombe',
    'kloniu',
    'klubai',
    'klubas',
    'kluoną',
    'klusni',
    'klusnų',
    'klysta',
    'klysti',
    'klūpos',
    'kmynai',
    'knygas',
    'knygoj',
    'knygos',
    'kodais',
    'kolegė',
    'kolegę',
    'kolegų',
    'kometa',
    'kometų',
    'kompas',
    'kopija',
    'kopiją',
    'kopijų',
    'kopimą',
    'kopėme',
    'koralų',
    'kortas',
    'kotelį',
    'kovinė',
    'kovinį',
    'kovėsi',
    'kozirį',
    'kožnam',
    'krachą',
    'kraige',
    'kraipo',
    'kraipė',
    'kraitį',
    'kramtė',
    'krante',
    'kranto',
    'krantu',
    'krantą',
    'krantų',
    'kratos',
    'kraujo',
    'kraują',
    'krauti',
    'krašte',
    'krašto',
    'kraštu',
    'kraštą',
    'kraštų',
    'kreida',
    'kreiva',
    'kreivė',
    'kremai',
    'kremas',
    'kremus',
    'krenta',
    'krentu',
    'krepšį',
    'kresni',
    'krečia',
    'krinta',
    'kritus',
    'kritęs',
    'krivės',
    'krizes',
    'krizės',
    'kroatų',
    'kronus',
    'krosnį',
    'kruopų',
    'krušos',
    'kryptį',
    'kryžių',
    'krėsle',
    'krėslo',
    'krėslą',
    'krėsti',
    'krūmai',
    'krūmas',
    'krūmus',
    'krūtis',
    'krūvas',
    'krūvio',
    'krūvis',
    'krūvių',
    'krūvon',
    'krūvos',
    'kubilą',
    'kulbės',
    'kulnas',
    'kulsim',
    'kultai',
    'kultas',
    'kultis',
    'kumelė',
    'kumelę',
    'kumpis',
    'kumpių',
    'kumštį',
    'kunige',
    'kunigo',
    'kunigą',
    'kunigė',
    'kunigų',
    'kupetų',
    'kupina',
    'kupros',
    'kupsto',
    'kuriam',
    'kurias',
    'kuriem',
    'kurioj',
    'kuriom',
    'kurion',
    'kurios',
    'kurmio',
    'kursai',
    'kursto',
    'kurstė',
    'kurtis',
    'kurėnų',
    'kuršių',
    'kuteno',
    'kušetė',
    'kvaile',
    'kvailą',
    'kvapus',
    'kvarco',
    'kvepia',
    'kvietė',
    'kvitus',
    'kvortą',
    'kylant',
    'kąsniu',
    'kėbulo',
    'kėbulų',
    'kėdžių',
    'kėlimo',
    'kėlimu',
    'kėlimą',
    'kėlusį',
    'kėslai',
    'kūdros',
    'kūginė',
    'kūginę',
    'kūlimo',
    'kūnais',
    'kūnams',
    'kūnelį',
    'kūrenu',
    'kūrime',
    'kūrimo',
    'kūrimu',
    'kūrimą',
    'kūrinį',
    'kūryba',
    'kūrybą',
    'kūrėja',
    'kūrėjo',
    'kūrėju',
    'kūrėją',
    'kūrėjų',
    'kūrėsi',
    'labumo',
    'ladinų',
    'lagery',
    'laibos',
    'laidos',
    'laidus',
    'laikos',
    'laikot',
    'laikus',
    'laikys',
    'laikyt',
    'laikęs',
    'laimės',
    'laimės',
    'laiptų',
    'laisto',
    'laistė',
    'laisve',
    'laisvo',
    'laisvu',
    'laisvą',
    'laisvė',
    'laisvę',
    'laisvų',
    'laivus',
    'laiške',
    'laiško',
    'laišką',
    'laiškų',
    'laksto',
    'lakstė',
    'lakšte',
    'lakūno',
    'lakūną',
    'langus',
    'lankys',
    'lankyt',
    'latvio',
    'latvis',
    'latvių',
    'laukei',
    'laukit',
    'laukių',
    'lauksi',
    'laukti',
    'lauktų',
    'laukėm',
    'laukęs',
    'laužti',
    'laviną',
    'lavinų',
    'lavonu',
    'lavoną',
    'lavonų',
    'lavėti',
    'lazdos',
    'ledais',
    'ledams',
    'ledinė',
    'ledinį',
    'leidom',
    'leidus',
    'leidęs',
    'leista',
    'leistų',
    'lempos',
    'lemtis',
    'lendam',
    'lengva',
    'lengvi',
    'lengvo',
    'lengvu',
    'lengvą',
    'lenkai',
    'lenkas',
    'lenkia',
    'lenkiu',
    'lenkti',
    'lenkus',
    'lentas',
    'lentos',
    'lesina',
    'liaudį',
    'liaukų',
    'liejas',
    'liejau',
    'liejom',
    'liekam',
    'liekna',
    'liemuo',
    'liepos',
    'liepta',
    'liepto',
    'lieptą',
    'liepęs',
    'lietum',
    'lietus',
    'lietūs',
    'ligoje',
    'ligoms',
    'ligonė',
    'ligonį',
    'likome',
    'likusį',
    'linkme',
    'linkus',
    'linkęs',
    'lipdyk',
    'lipnus',
    'litams',
    'litras',
    'litrus',
    'litukų',
    'liudys',
    'lizdas',
    'lizdus',
    'liūdna',
    'liūdną',
    'liūdnų',
    'liūtys',
    'logika',
    'logiką',
    'loginė',
    'loginį',
    'lopais',
    'lopšys',
    'lordai',
    'lordas',
    'lotynų',
    'lovyje',
    'lovytė',
    'lubose',
    'luisto',
    'luistų',
    'luitas',
    'lukšto',
    'lukštą',
    'luomas',
    'luotas',
    'luošys',
    'lupami',
    'lupasi',
    'lupate',
    'lupeną',
    'lupenų',
    'lupkis',
    'lydavo',
    'lydeka',
    'lydekų',
    'lyderį',
    'lydėjo',
    'lydėti',
    'lygaus',
    'lygias',
    'lygina',
    'lygint',
    'lygios',
    'lygius',
    'lygmuo',
    'lygoje',
    'lyguma',
    'lygumų',
    'lyjant',
    'lynais',
    'lyrikų',
    'lyrinė',
    'lyties',
    'lytine',
    'lytinį',
    'lytėjo',
    'lėkštę',
    'lėtina',
    'lėtinę',
    'lėtinį',
    'lūžusi',
    'lūžusį',
    'magiją',
    'mainus',
    'maisto',
    'maistu',
    'maistą',
    'maišto',
    'maištą',
    'maišus',
    'maketo',
    'maketą',
    'maldos',
    'malkos',
    'maloni',
    'malonu',
    'malonė',
    'malonę',
    'malonų',
    'maltus',
    'malūne',
    'malūno',
    'malūną',
    'malūnų',
    'manosi',
    'manote',
    'mansių',
    'mantos',
    'mantrą',
    'manyje',
    'manyti',
    'manytų',
    'manąjį',
    'maorių',
    'margių',
    'margos',
    'marios',
    'markių',
    'markės',
    'marlės',
    'marčią',
    'masinė',
    'masinį',
    'masyve',
    'masyvo',
    'masyvu',
    'masyvą',
    'masyvų',
    'matiko',
    'matiku',
    'matosi',
    'matysi',
    'matėsi',
    'mausti',
    'mazgus',
    'mačius',
    'mašiną',
    'mašinų',
    'mažinu',
    'mažoje',
    'mažoji',
    'mažose',
    'mažute',
    'mažutė',
    'mažutę',
    'mažyli',
    'mažylė',
    'mažylę',
    'mažylį',
    'mažyti',
    'mažytė',
    'mažytį',
    'mažėjo',
    'mažėti',
    'medalį',
    'medaus',
    'medija',
    'mediko',
    'medikų',
    'medinė',
    'medinę',
    'medinį',
    'medžio',
    'medžiu',
    'medžių',
    'megzti',
    'meilės',
    'melavo',
    'melisų',
    'melski',
    'melsti',
    'melsva',
    'melsvo',
    'melsvu',
    'melsvą',
    'melžia',
    'melžti',
    'menkai',
    'menkas',
    'menkos',
    'mentės',
    'mergai',
    'mergas',
    'mergos',
    'merkia',
    'mesijo',
    'mesiją',
    'metais',
    'metalo',
    'metalą',
    'metalų',
    'metinė',
    'metinį',
    'metodu',
    'metodą',
    'metodų',
    'metrai',
    'metrus',
    'metukų',
    'metusį',
    'metėsi',
    'mečiau',
    'meškas',
    'meškos',
    'midaus',
    'miegas',
    'miegos',
    'miegot',
    'miegus',
    'mielas',
    'mielos',
    'mielus',
    'mielės',
    'miesto',
    'miestu',
    'miestą',
    'miestų',
    'miglas',
    'miglos',
    'miklus',
    'miltus',
    'mintys',
    'minusą',
    'minusų',
    'minute',
    'minutė',
    'minutę',
    'minėjo',
    'minėję',
    'minėta',
    'minėtu',
    'minėtų',
    'mirtys',
    'mirusį',
    'miršta',
    'mityba',
    'mitybą',
    'miškan',
    'miškas',
    'miškus',
    'mišrus',
    'mišrūs',
    'modelį',
    'modulį',
    'mokant',
    'mokaus',
    'mokinė',
    'mokinį',
    'moksle',
    'mokslu',
    'mokslą',
    'mokslų',
    'mokyti',
    'mokyti',
    'mokėsi',
    'mokėti',
    'mokėtų',
    'molinę',
    'moneta',
    'monetą',
    'monetų',
    'moralė',
    'moralę',
    'morkas',
    'mostai',
    'moters',
    'moterį',
    'moterų',
    'motina',
    'motiną',
    'motinų',
    'motulė',
    'motyvu',
    'motyvą',
    'motyvų',
    'movėsi',
    'mozūrą',
    'mozūrų',
    'mugėse',
    'muilas',
    'mulkis',
    'murkdė',
    'muzika',
    'muziką',
    'muzikų',
    'muškit',
    'muštas',
    'muštis',
    'mylėjo',
    'mylėję',
    'mylėti',
    'mąstai',
    'mąstau',
    'mėgina',
    'mėgink',
    'mėgino',
    'mėginu',
    'mėginį',
    'mėgsta',
    'mėgsti',
    'mėgstu',
    'mėlyna',
    'mėlyni',
    'mėlyną',
    'mėlynę',
    'mėlynų',
    'mėnesį',
    'mėsoje',
    'mėsytę',
    'mėtosi',
    'mėšlas',
    'mėšlui',
    'mėžiko',
    'mūrijo',
    'mūrysi',
    'mūryti',
    'mūšius',
    'mūšyje',
    'naftos',
    'naivus',
    'naivūs',
    'naktis',
    'nakvos',
    'namelį',
    'namuos',
    'narius',
    'narvus',
    'natelė',
    'natrio',
    'naudos',
    'naujos',
    'navikų',
    'našius',
    'našliu',
    'našlių',
    'našlės',
    'našumo',
    'našumą',
    'naščių',
    'neardo',
    'nebado',
    'nebark',
    'nebarė',
    'nebijo',
    'nebuvo',
    'nebuvę',
    'nebėgo',
    'nebėgs',
    'nebėra',
    'nebūsi',
    'nebūta',
    'nebūti',
    'nebūtį',
    'nebūtų',
    'nedaro',
    'nedarė',
    'nedavė',
    'nedora',
    'nedorų',
    'nedrįs',
    'neduok',
    'nedyla',
    'negali',
    'negaus',
    'negavo',
    'negavę',
    'negilu',
    'negimė',
    'negrai',
    'negrok',
    'negrus',
    'negyva',
    'negyvi',
    'negyvu',
    'negyvą',
    'negyvų',
    'neilgą',
    'neilgų',
    'nejuda',
    'nejutę',
    'nekilo',
    'nekils',
    'nekito',
    'nekris',
    'nekyla',
    'nelauk',
    'neliko',
    'neliks',
    'nelikę',
    'nelipk',
    'nemato',
    'nematė',
    'nemaži',
    'nemažą',
    'nemažų',
    'nemiga',
    'nemirs',
    'nemirė',
    'nemoka',
    'nemoki',
    'nemoku',
    'nemokė',
    'nemyli',
    'nemėgo',
    'nepuls',
    'nepūtų',
    'nerado',
    'neramu',
    'neramų',
    'nerasi',
    'nerimo',
    'nerimo',
    'nerimu',
    'nerimą',
    'nervai',
    'nervus',
    'neršia',
    'nerūpi',
    'nesako',
    'nesakė',
    'nesavi',
    'nesavu',
    'nesuka',
    'nesėdi',
    'netapo',
    'netiko',
    'netikę',
    'neturi',
    'neturį',
    'netyla',
    'neugdo',
    'nevalą',
    'nevaro',
    'nevarė',
    'nevyko',
    'nevykę',
    'nevėlu',
    'neįdės',
    'nešasi',
    'nešaus',
    'nešimu',
    'neškim',
    'neškis',
    'neškit',
    'nešmuo',
    'nešuos',
    'nešusi',
    'nešėją',
    'nešėsi',
    'nežavi',
    'nežuvo',
    'nežymų',
    'niekam',
    'niekas',
    'niekuo',
    'niekus',
    'niūrus',
    'noragų',
    'norais',
    'norams',
    'norima',
    'norime',
    'norimu',
    'norimą',
    'norimų',
    'norite',
    'normai',
    'normas',
    'norėję',
    'norėsi',
    'norėta',
    'norėti',
    'norėtų',
    'novelė',
    'novelę',
    'nubyra',
    'nubėga',
    'nubėgo',
    'nudažė',
    'nudega',
    'nudegė',
    'nudobė',
    'nudvės',
    'nudūrė',
    'nueita',
    'nueito',
    'nueitų',
    'nugaiš',
    'nugali',
    'nugarą',
    'nugarų',
    'nugirs',
    'nujoti',
    'nukasę',
    'nukovė',
    'nukris',
    'nukūrė',
    'nulipa',
    'nulipo',
    'nulūžo',
    'numato',
    'numatė',
    'numerį',
    'numeta',
    'numetė',
    'numirs',
    'numirė',
    'numoja',
    'numovė',
    'numykė',
    'nuodai',
    'nuogas',
    'nuomai',
    'nupieš',
    'nupirk',
    'nurijo',
    'nurimo',
    'nurodė',
    'nusako',
    'nusakė',
    'nusega',
    'nusekė',
    'nusėda',
    'nutapė',
    'nutarė',
    'nutarę',
    'nuteis',
    'nutiko',
    'nutiks',
    'nutilk',
    'nutilo',
    'nutolę',
    'nutyla',
    'nutęsė',
    'nutūpė',
    'nuvalė',
    'nuvalę',
    'nuveda',
    'nuvedė',
    'nuveja',
    'nuvežė',
    'nuvyko',
    'nuvylė',
    'nuėjom',
    'nuėjot',
    'nušovė',
    'nykias',
    'nykimo',
    'nykimu',
    'nykimą',
    'nyksta',
    'nykusi',
    'nėriau',
    'nėščia',
    'nėščių',
    'obelis',
    'obelys',
    'okeaną',
    'olandė',
    'optinė',
    'optinį',
    'orbita',
    'orbitą',
    'orbitų',
    'ordinų',
    'organu',
    'organą',
    'organų',
    'orgijų',
    'orumas',
    'orėmis',
    'ostiją',
    'ošesiu',
    'ožiuką',
    'ožkytę',
    'pabiro',
    'pabirų',
    'pabudo',
    'pabėgo',
    'pabėgt',
    'pabūnu',
    'pabūti',
    'padegt',
    'padegė',
    'padori',
    'paduok',
    'paduos',
    'padėjo',
    'padėję',
    'padėką',
    'padėsi',
    'padėti',
    'padėtį',
    'padėtų',
    'pagyti',
    'pailgo',
    'pailgu',
    'pailgą',
    'pailso',
    'paimtų',
    'painus',
    'paišęs',
    'pajuto',
    'pajutę',
    'pajėgi',
    'pajėgs',
    'pajėgų',
    'pajūrį',
    'pakeis',
    'pakels',
    'pakelt',
    'pakely',
    'pakelį',
    'paketo',
    'paketu',
    'paketą',
    'paketų',
    'pakibo',
    'pakilo',
    'pakils',
    'pakilę',
    'pakito',
    'pakitę',
    'pakišo',
    'pakišu',
    'pakorė',
    'pakylu',
    'paleis',
    'palesę',
    'palies',
    'paliko',
    'paliks',
    'palikt',
    'palikę',
    'paluby',
    'palydi',
    'palyti',
    'pameni',
    'pamenu',
    'pametė',
    'pametę',
    'pamilo',
    'pamirš',
    'pamoką',
    'pamokę',
    'pamokų',
    'panelė',
    'panelę',
    'paniką',
    'panikė',
    'pantis',
    'pančiu',
    'pančių',
    'pareis',
    'pareit',
    'paremt',
    'parodą',
    'parodė',
    'parodų',
    'paroms',
    'parveš',
    'parėjo',
    'pasekė',
    'pasenę',
    'paslėk',
    'pasuko',
    'pasukt',
    'pasėdi',
    'pasėjo',
    'pateko',
    'pateks',
    'patekę',
    'patelė',
    'patelę',
    'paties',
    'patiko',
    'patiks',
    'patino',
    'patinų',
    'patogi',
    'patogu',
    'patogų',
    'patosu',
    'patosą',
    'patyrė',
    'patyrę',
    'patūno',
    'pauzių',
    'pauzės',
    'pavedė',
    'pavirs',
    'pavogs',
    'pavogė',
    'pavogę',
    'pavojų',
    'pavydi',
    'pavydi',
    'pavydo',
    'pavydu',
    'pavyko',
    'pavyks',
    'pavykę',
    'pačioj',
    'pačios',
    'pačios',
    'pačius',
    'paėmei',
    'paėmęs',
    'pašovė',
    'paštui',
    'paštus',
    'pašėrę',
    'paūšim',
    'pažeis',
    'pažino',
    'pažint',
    'pažiro',
    'pažymi',
    'pažymą',
    'pažymį',
    'pažymų',
    'pelkių',
    'pelkės',
    'pelnas',
    'pelynų',
    'pelėda',
    'pelėdų',
    'pelėms',
    'pelėsį',
    'penkis',
    'penkių',
    'penkta',
    'penkti',
    'penkto',
    'penktą',
    'penktų',
    'perima',
    'perims',
    'perkam',
    'perkat',
    'perlai',
    'peruką',
    'perėjo',
    'perėti',
    'peršti',
    'pečius',
    'peštis',
    'piemuo',
    'pienas',
    'pienės',
    'pietus',
    'pietys',
    'pietūs',
    'pievas',
    'pievoj',
    'pievos',
    'pigaus',
    'pigųjį',
    'piketo',
    'piktam',
    'piktas',
    'piktus',
    'pilant',
    'pildyt',
    'pilkas',
    'pilkos',
    'pilkus',
    'pilnas',
    'pilnom',
    'pilnos',
    'pilnus',
    'pilnut',
    'pilsto',
    'pilstė',
    'pilvas',
    'pilyje',
    'pilyse',
    'pintas',
    'piratu',
    'pirkau',
    'pirkom',
    'pirktą',
    'pirktų',
    'pirmas',
    'pirmoj',
    'pirmos',
    'pirmus',
    'piršto',
    'pirštu',
    'pirštą',
    'pirštų',
    'pjauni',
    'pjauti',
    'pjesių',
    'pjovęs',
    'pjūklu',
    'pjūklą',
    'pjūvio',
    'pjūvis',
    'plakti',
    'plakti',
    'planui',
    'planus',
    'platus',
    'platūs',
    'plauko',
    'plauko',
    'plauks',
    'plauką',
    'plaukė',
    'plaukų',
    'plauni',
    'plauti',
    'plačiu',
    'plačią',
    'plačių',
    'pledas',
    'pledus',
    'plento',
    'plentu',
    'plečia',
    'pliaže',
    'pliekė',
    'plieno',
    'plikos',
    'plikės',
    'plinka',
    'plinta',
    'pliusų',
    'plomba',
    'plombą',
    'plombų',
    'plonas',
    'plotai',
    'plotas',
    'plotis',
    'plotmę',
    'plotui',
    'plotus',
    'plukės',
    'plutai',
    'plutos',
    'plynių',
    'plynus',
    'plytas',
    'plytos',
    'plyšių',
    'plyšta',
    'plyšti',
    'plėsti',
    'plėstų',
    'plėtra',
    'plėtrą',
    'plėšia',
    'plūgus',
    'plūsta',
    'plūsti',
    'pobūdį',
    'poelgį',
    'poemai',
    'poetai',
    'poetas',
    'poetus',
    'poetės',
    'poilsį',
    'pojūtį',
    'pokylį',
    'pokštų',
    'polinė',
    'pomėgį',
    'ponams',
    'porelė',
    'posakį',
    'postai',
    'posėdį',
    'posūkį',
    'požymį',
    'pradus',
    'pradės',
    'pradėt',
    'praeis',
    'praneš',
    'pranyk',
    'prasme',
    'prasmė',
    'prasmę',
    'prasti',
    'prasto',
    'prastą',
    'prastų',
    'praėjo',
    'praėję',
    'prašei',
    'prašyk',
    'prašyt',
    'prašęs',
    'praūžė',
    'pražus',
    'prekių',
    'prekės',
    'presai',
    'pridėk',
    'priedo',
    'priedu',
    'priedą',
    'priedų',
    'priekį',
    'priešo',
    'priešu',
    'priešą',
    'priešų',
    'princą',
    'prionų',
    'prizas',
    'prizmė',
    'prizmę',
    'priėjo',
    'progai',
    'progas',
    'protas',
    'protui',
    'protus',
    'prūsai',
    'pudros',
    'puikūs',
    'pulkai',
    'pulkas',
    'pultas',
    'punkte',
    'punkto',
    'punktą',
    'punktų',
    'puodai',
    'puodas',
    'puolęs',
    'puošia',
    'purena',
    'purkšt',
    'purslų',
    'purvas',
    'pusėje',
    'putiną',
    'putinų',
    'putlūs',
    'putnūs',
    'putoja',
    'puvenų',
    'pušies',
    'pušyne',
    'pušynų',
    'pykina',
    'pykosi',
    'pyksta',
    'pyksti',
    'pykstu',
    'pyktis',
    'pyktis',
    'pykčio',
    'pyliau',
    'pylimo',
    'pylimą',
    'pylimų',
    'pylėsi',
    'pynėsi',
    'pyrago',
    'pyragu',
    'pyragą',
    'pėdina',
    'pėdose',
    'pėsčia',
    'pūdinį',
    'pūdymo',
    'pūkais',
    'pūkelį',
    'pūkinę',
    'pūslių',
    'pūtiko',
    'pūtiką',
    'pūtikų',
    'pūtimu',
    'rabinų',
    'raciją',
    'radijo',
    'radiją',
    'radijų',
    'radome',
    'radosi',
    'radote',
    'radusi',
    'ragelį',
    'ragino',
    'raginu',
    'raides',
    'raidos',
    'raidės',
    'rajone',
    'rajoną',
    'rajonų',
    'raketų',
    'raktus',
    'ramino',
    'raminu',
    'ramios',
    'rampos',
    'ramstį',
    'ramune',
    'ramunė',
    'ramutė',
    'ramybe',
    'ramybė',
    'ramybę',
    'randus',
    'rangus',
    'rankoj',
    'rankom',
    'rankos',
    'rančos',
    'rasime',
    'rasite',
    'ratuko',
    'ratukų',
    'raudok',
    'raudos',
    'raudės',
    'rausti',
    'rausvo',
    'rausvą',
    'rausvų',
    'ravime',
    'ravėti',
    'razinų',
    'račius',
    'rašinį',
    'rašius',
    'rašomi',
    'rašomų',
    'rašote',
    'raštui',
    'raštus',
    'rašybą',
    'rašymo',
    'rašymą',
    'rašysi',
    'rašyti',
    'rašytą',
    'rašytų',
    'rašėme',
    'realią',
    'realių',
    'realus',
    'regula',
    'regėjo',
    'regėję',
    'regėti',
    'reiktų',
    'reikės',
    'reiškė',
    'remtis',
    'rengia',
    'rengta',
    'rengti',
    'rengęs',
    'replės',
    'retais',
    'retoms',
    'retėja',
    'režimo',
    'režimu',
    'režimą',
    'režimų',
    'riboja',
    'ribose',
    'ribota',
    'ribotą',
    'ridena',
    'riebus',
    'riekės',
    'riksmo',
    'riksmu',
    'riksmą',
    'rimsta',
    'rimtas',
    'rimtos',
    'rinkas',
    'rinkos',
    'rinkta',
    'rinktų',
    'rinose',
    'ritmas',
    'rišame',
    'rišant',
    'rodant',
    'rodyti',
    'rodytų',
    'rodėsi',
    'rojaus',
    'romane',
    'romaną',
    'romanų',
    'romėnu',
    'romėną',
    'romėnų',
    'ropinė',
    'rotušė',
    'rovėsi',
    'rubino',
    'rublio',
    'rublių',
    'rudais',
    'rudens',
    'rudenį',
    'ruimas',
    'ruonis',
    'ruošia',
    'ruošti',
    'ruožai',
    'ruožas',
    'rupūže',
    'rupūžė',
    'ruskių',
    'rutina',
    'rykštė',
    'rykštę',
    'rytais',
    'rytinė',
    'rytinį',
    'rytojų',
    'ryšius',
    'ryškia',
    'ryškią',
    'ryškių',
    'ryškus',
    'ryškūs',
    'ryžius',
    'ryžosi',
    'ryžtas',
    'ryžtis',
    'ryžtui',
    'rąstai',
    'rąžosi',
    'rėkiam',
    'rėmelį',
    'rėžiau',
    'rėžimo',
    'rėžius',
    'rūbais',
    'rūdija',
    'rūdžių',
    'rūkymą',
    'rūkyti',
    'rūkytų',
    'rūmais',
    'rūpimu',
    'rūpėjo',
    'rūpėti',
    'rūpėtų',
    'rūšies',
    'rūšims',
    'sagtim',
    'sagutę',
    'sakiny',
    'sakinį',
    'sakmių',
    'sakome',
    'sakyti',
    'sakytų',
    'saloje',
    'salone',
    'saloną',
    'salonų',
    'salėje',
    'saugią',
    'saugių',
    'saugok',
    'saujom',
    'saulei',
    'savitą',
    'savoje',
    'savumo',
    'savybe',
    'savybė',
    'savybę',
    'savyje',
    'savąjį',
    'scenoj',
    'schema',
    'schemą',
    'schemų',
    'sekami',
    'seklio',
    'sektai',
    'sekėjo',
    'sekėjų',
    'senato',
    'senatą',
    'senimo',
    'senoji',
    'senolė',
    'senovė',
    'senumo',
    'senumą',
    'senutė',
    'senutę',
    'senyva',
    'senyvo',
    'senąja',
    'senąjį',
    'serbai',
    'serija',
    'seriją',
    'sezoną',
    'siaubo',
    'siaubą',
    'siaurą',
    'siaurų',
    'siautė',
    'siejau',
    'siekta',
    'siektų',
    'sieloj',
    'sielon',
    'sienoj',
    'sijonu',
    'sijoną',
    'sijonų',
    'silpna',
    'silpno',
    'silpnu',
    'silpną',
    'silpnų',
    'sirena',
    'sirgau',
    'sirupą',
    'siunta',
    'siuntė',
    'siurbt',
    'siūlau',
    'skaito',
    'skaitė',
    'skalbė',
    'skaldo',
    'skaldė',
    'skaldų',
    'skalpo',
    'skambi',
    'skanią',
    'skardą',
    'skaudi',
    'skelbė',
    'skelia',
    'skendi',
    'skendo',
    'skiepo',
    'skiepų',
    'skiltį',
    'skirta',
    'skirto',
    'skirtą',
    'skirtų',
    'sklido',
    'sklypo',
    'sklypą',
    'sklypų',
    'skobnį',
    'skolai',
    'skoniu',
    'skrido',
    'skubią',
    'skubių',
    'skubėt',
    'skunde',
    'skundą',
    'skundė',
    'skurde',
    'skurdi',
    'skurdo',
    'skurdų',
    'skyrių',
    'skėčiu',
    'slapto',
    'slaptu',
    'slaptą',
    'slaptų',
    'slauną',
    'slegia',
    'slenka',
    'slepia',
    'slinko',
    'slogių',
    'slėgio',
    'slėgiu',
    'slėnio',
    'slėniu',
    'slėnių',
    'slėpti',
    'slūgti',
    'smagių',
    'smailu',
    'smailė',
    'smakro',
    'smakru',
    'smarkų',
    'smarve',
    'smarvė',
    'smarvę',
    'smeigė',
    'smeigę',
    'smerkė',
    'smilgų',
    'smiltį',
    'sminga',
    'smogta',
    'smuiko',
    'smuikų',
    'smukti',
    'smulki',
    'smulkų',
    'smunka',
    'smurto',
    'smurtą',
    'smėlio',
    'smėliu',
    'smūgio',
    'smūgiu',
    'smūgių',
    'snaigė',
    'sniego',
    'sniegu',
    'sniegą',
    'snigta',
    'sodina',
    'sodrių',
    'sodyba',
    'sodybą',
    'sodybų',
    'solidų',
    'sonatų',
    'spalio',
    'spalių',
    'spalvą',
    'spalvų',
    'spardo',
    'spardė',
    'sparno',
    'sparną',
    'sparnų',
    'spartą',
    'spaudi',
    'spaudą',
    'spaudė',
    'spazmų',
    'spenio',
    'spenių',
    'sperma',
    'spindį',
    'spinta',
    'spintą',
    'sporte',
    'sportu',
    'sportą',
    'spragą',
    'spragų',
    'spruko',
    'spuogą',
    'spurda',
    'spyriu',
    'spėjai',
    'spėjau',
    'spėjot',
    'spėria',
    'sraigė',
    'srauto',
    'srautą',
    'srautų',
    'sriuba',
    'sriubą',
    'srovei',
    'srovių',
    'sruoga',
    'sruogų',
    'srėbti',
    'stabdo',
    'stabdė',
    'staigų',
    'stalių',
    'stalui',
    'stambi',
    'stambų',
    'standų',
    'stačią',
    'stačių',
    'stažui',
    'stebiu',
    'stebėk',
    'stepių',
    'stiebų',
    'stiklo',
    'stiklu',
    'stiklą',
    'stiklų',
    'stinga',
    'stipkę',
    'stipru',
    'stiprų',
    'stirna',
    'stirnų',
    'stogai',
    'stogui',
    'stojam',
    'stojau',
    'stokai',
    'stovai',
    'stovim',
    'stoviu',
    'stovėk',
    'stočių',
    'stribų',
    'strigo',
    'strypo',
    'strėlę',
    'stuboj',
    'stulpo',
    'stulpų',
    'stumdo',
    'stumdė',
    'stumia',
    'stverk',
    'stvėrę',
    'stygių',
    'stūmio',
    'suardo',
    'suardė',
    'suarti',
    'subarė',
    'subyra',
    'subėga',
    'subėgo',
    'sucypė',
    'sudaro',
    'sudarė',
    'sudarę',
    'sudavė',
    'sudega',
    'sudegė',
    'sudyla',
    'sudėjo',
    'sudėję',
    'sudėti',
    'sudėti',
    'sudėtį',
    'sudėtų',
    'sueina',
    'sugavę',
    'sugeba',
    'sugedo',
    'sugiję',
    'sugrįš',
    'suimta',
    'suirtų',
    'sukalė',
    'suknia',
    'suknią',
    'sukčių',
    'sukėlę',
    'sukūrė',
    'sulygę',
    'sulčių',
    'sumalę',
    'sumanė',
    'sumeta',
    'sumetė',
    'suminė',
    'sumišo',
    'sumišę',
    'sumoje',
    'sumoka',
    'suneša',
    'sunkia',
    'sunkio',
    'sunkių',
    'suokia',
    'suomių',
    'supami',
    'supilk',
    'supyko',
    'supykę',
    'supylė',
    'surado',
    'suradę',
    'surijo',
    'suriko',
    'suryja',
    'suryti',
    'surytų',
    'sutaną',
    'sutapo',
    'suteka',
    'sutemo',
    'sutemę',
    'sutepk',
    'sutepė',
    'suterš',
    'sutiko',
    'sutikę',
    'sutūpė',
    'suvarė',
    'suveda',
    'suvedė',
    'suvežė',
    'suvokė',
    'suvokę',
    'sušalo',
    'sušalę',
    'sužino',
    'sužėri',
    'svaigo',
    'svarbi',
    'svarbu',
    'svarbą',
    'svarbų',
    'svarių',
    'sveika',
    'sveiko',
    'sveiku',
    'sveiką',
    'sveikų',
    'sveria',
    'sverti',
    'sverti',
    'svečio',
    'svečiu',
    'svečią',
    'svečią',
    'svečių',
    'sviedė',
    'svieto',
    'svirno',
    'svirną',
    'svoriu',
    'svočią',
    'svylam',
    'sąaugų',
    'sąjūdį',
    'sąlyga',
    'sąlygų',
    'sąlytį',
    'sąmatų',
    'sąmone',
    'sąmonė',
    'sąmonę',
    'sąmyšį',
    'sąnarį',
    'sąraše',
    'sąrašo',
    'sąrašu',
    'sąvoka',
    'sąvokų',
    'sąžine',
    'sąžinė',
    'sąžinę',
    'sėbrai',
    'sėbrui',
    'sėdimo',
    'sėdynę',
    'sėdžiu',
    'sėjimo',
    'sėklai',
    'sėkmei',
    'sėlenų',
    'sėlina',
    'sėmenų',
    'sūdytų',
    'sūkurį',
    'sūneli',
    'sūnelį',
    'sūnumi',
    'sūroką',
    'sūrumo',
    'sūrymu',
    'taboką',
    'taigos',
    'taikon',
    'taikos',
    'taikus',
    'taikūs',
    'takelį',
    'taksiu',
    'taliją',
    'talpos',
    'tamprų',
    'tamsią',
    'tamsių',
    'tamsoj',
    'tankio',
    'tankiu',
    'tankių',
    'tankmę',
    'tankus',
    'tankūs',
    'tapome',
    'tapusi',
    'tapusį',
    'tapybą',
    'tarifų',
    'tarimų',
    'tarkim',
    'tarmei',
    'tarmes',
    'tarmių',
    'tarmės',
    'tarnui',
    'taroje',
    'tarpus',
    'tarybą',
    'tarybų',
    'tarėsi',
    'taršos',
    'taupūs',
    'taures',
    'taurių',
    'taurėm',
    'taurės',
    'tavąjį',
    'taškus',
    'tebuvo',
    'tebėra',
    'tegali',
    'teikęs',
    'teismo',
    'teismą',
    'teismų',
    'tekina',
    'tekėjo',
    'teliką',
    'telkšo',
    'tembro',
    'tempai',
    'tempas',
    'tempia',
    'tempui',
    'teniso',
    'tepalo',
    'tepalu',
    'tepalą',
    'terasų',
    'terbos',
    'terpės',
    'teršia',
    'tešlai',
    'tešmuo',
    'težino',
    'tiesom',
    'tikram',
    'tikras',
    'tikros',
    'tikrus',
    'tikslo',
    'tikslu',
    'tikslu',
    'tikslą',
    'tikslų',
    'tikslų',
    'tikyba',
    'tikybą',
    'tikėjo',
    'tingūs',
    'tinkle',
    'tinklo',
    'tinklu',
    'tinklą',
    'tinklų',
    'tinkąs',
    'tipams',
    'tipelį',
    'tipeno',
    'tiradą',
    'tirpdo',
    'tirpdė',
    'tobula',
    'tobuli',
    'tobulą',
    'tobulų',
    'tokiam',
    'tokias',
    'tokiem',
    'tokius',
    'toksai',
    'tolima',
    'tolimą',
    'tolimų',
    'tolygu',
    'tramdė',
    'trapią',
    'trapūs',
    'trauks',
    'traukė',
    'traumą',
    'traumų',
    'trejas',
    'trejus',
    'trendo',
    'trenkė',
    'trečia',
    'trečio',
    'trečiu',
    'trečią',
    'trečių',
    'treška',
    'triadą',
    'trikdo',
    'trikdė',
    'triūbą',
    'triūso',
    'triūsą',
    'triūsė',
    'trobas',
    'troškę',
    'trukdo',
    'trukme',
    'trukmė',
    'trukmę',
    'trukęs',
    'trumpa',
    'trumpi',
    'trumpą',
    'trumpų',
    'trunka',
    'trąšai',
    'trąšas',
    'trąšom',
    'trąšos',
    'trąšūs',
    'tulpes',
    'tulpės',
    'tulžio',
    'tulžis',
    'tulžių',
    'tulžys',
    'tundrų',
    'tunelį',
    'tungas',
    'tupėjo',
    'turgūs',
    'turima',
    'turime',
    'turimą',
    'turimų',
    'turine',
    'turiny',
    'turinį',
    'turėjo',
    'turėję',
    'turėsi',
    'tuščia',
    'tuščio',
    'tuščią',
    'tuščių',
    'tvaiką',
    'tvanku',
    'tvarko',
    'tvarką',
    'tvarkė',
    'tviska',
    'tvojęs',
    'tvoras',
    'tvyrąs',
    'tykina',
    'tylaus',
    'tyleni',
    'tylios',
    'tyloje',
    'tylėjo',
    'tymais',
    'tyrime',
    'tyrimo',
    'tyrimą',
    'tyrimų',
    'tyrumo',
    'tyrėjų',
    'tyčinė',
    'tyčinį',
    'tyčios',
    'tąsoje',
    'tėkmei',
    'tėvais',
    'tėvams',
    'tėveli',
    'tėvelį',
    'tėvija',
    'tėvuko',
    'tėvukų',
    'tėvyne',
    'tėvynę',
    'tęsimo',
    'tęsinį',
    'tįsumą',
    'tūryje',
    'ugdant',
    'ugdoma',
    'ugdymo',
    'ugdymą',
    'ugdyti',
    'ugdytų',
    'ugnelė',
    'ugnelę',
    'ugnies',
    'ugnyje',
    'ulbėjo',
    'ulyčią',
    'unijas',
    'unijos',
    'uodega',
    'uodegą',
    'uodėme',
    'uodžia',
    'uogytė',
    'uosyje',
    'uosyne',
    'uošvis',
    'upelio',
    'upelių',
    'upelės',
    'upines',
    'upėmis',
    'utėles',
    'utėlių',
    'užantį',
    'užarti',
    'užbėga',
    'užbėgo',
    'užbūrė',
    'uždari',
    'uždaro',
    'uždarą',
    'uždarė',
    'uždarų',
    'uždega',
    'uždegė',
    'uždenk',
    'uždėjo',
    'uždėti',
    'užeina',
    'užgeso',
    'užgims',
    'užgros',
    'užgyja',
    'užgyti',
    'užimta',
    'užimtą',
    'užimtų',
    'užkasė',
    'užkišt',
    'užkopė',
    'užkūrė',
    'užlies',
    'užlipa',
    'užlipo',
    'užlips',
    'užmeta',
    'užmetė',
    'užmigo',
    'užmigę',
    'užmojų',
    'užmoka',
    'užnešė',
    'užpila',
    'užpilk',
    'užpils',
    'užpylė',
    'užrašo',
    'užrašo',
    'užrašą',
    'užrašė',
    'užrašę',
    'užrašų',
    'užriks',
    'užriša',
    'užsako',
    'užsakų',
    'užsega',
    'užsėdo',
    'užtarė',
    'užteko',
    'užteks',
    'užtepa',
    'užtiko',
    'užtilo',
    'užvirė',
    'užšalo',
    'užšalę',
    'užšoka',
    'užšąla',
    'vadink',
    'vadino',
    'vadins',
    'vadinu',
    'vadoms',
    'vagies',
    'vagims',
    'vagoje',
    'vagone',
    'vagoną',
    'vagonų',
    'vaikus',
    'vaikšo',
    'vaisto',
    'vaistų',
    'vaizde',
    'vaizdo',
    'vaizdu',
    'vaizdą',
    'vaizdų',
    'vaišes',
    'vaišės',
    'valdyt',
    'valdęs',
    'valgei',
    'valgio',
    'valgis',
    'valgių',
    'valgom',
    'valgyk',
    'valgys',
    'valgyt',
    'valgęs',
    'valios',
    'valius',
    'valizą',
    'valomi',
    'valosi',
    'valtis',
    'valtys',
    'valuže',
    'valymo',
    'valymą',
    'valyti',
    'vamzdį',
    'vanduo',
    'vangūs',
    'vanile',
    'vantos',
    'vapėjo',
    'vardui',
    'vardus',
    'vargti',
    'vargus',
    'vargšo',
    'vargšą',
    'vargšė',
    'vargšę',
    'vargšų',
    'varinė',
    'varinę',
    'varles',
    'varlių',
    'varlės',
    'varnos',
    'varnui',
    'varnės',
    'varomi',
    'varomų',
    'varpos',
    'varsnų',
    'varsto',
    'varstų',
    'vartus',
    'vartys',
    'varymo',
    'varyti',
    'varškė',
    'varškę',
    'varžtų',
    'vatoje',
    'vazonų',
    'vedami',
    'vedamų',
    'vedant',
    'vedasi',
    'vedimo',
    'vedlys',
    'vedlės',
    'vedybų',
    'vedėja',
    'vedėjo',
    'vedėju',
    'vedėją',
    'vedėjų',
    'veidan',
    'veidas',
    'veidus',
    'veikla',
    'veiklą',
    'veiklų',
    'veiktų',
    'veislė',
    'veislę',
    'vejasi',
    'velnio',
    'velniu',
    'velnią',
    'velnių',
    'velėna',
    'vengia',
    'vengiu',
    'vengro',
    'vengrų',
    'vengti',
    'vengtų',
    'vergas',
    'vergių',
    'vergės',
    'verkia',
    'verkti',
    'verkėt',
    'verkęs',
    'verpia',
    'verslo',
    'verslu',
    'verslą',
    'verslų',
    'versti',
    'verstų',
    'vertas',
    'vertos',
    'vertės',
    'verčia',
    'verčių',
    'veršio',
    'veršis',
    'veržli',
    'veržlė',
    'veržlę',
    'veržti',
    'vešlių',
    'vešlus',
    'vešlūs',
    'vežami',
    'vežant',
    'vežasi',
    'vežimo',
    'vežimu',
    'vežimą',
    'vežimų',
    'vežėju',
    'vežėją',
    'vežėjų',
    'vežėsi',
    'vidaus',
    'viduje',
    'vidury',
    'vidurį',
    'vielom',
    'vielos',
    'vienam',
    'vienam',
    'vienam',
    'vienas',
    'vienas',
    'vienas',
    'vienoj',
    'vienos',
    'vienos',
    'vienos',
    'vienus',
    'vienut',
    'vietas',
    'vieton',
    'vietos',
    'viešas',
    'viešos',
    'vikaro',
    'vikaru',
    'vikarą',
    'vikarų',
    'vikrūs',
    'vikšrą',
    'vilkas',
    'vilkus',
    'vilnas',
    'vilnos',
    'vilose',
    'viltys',
    'viralo',
    'viralą',
    'virpėt',
    'virsmą',
    'virsta',
    'virstų',
    'virtas',
    'virtos',
    'viruso',
    'virusų',
    'viršum',
    'viršus',
    'visame',
    'visatą',
    'viskam',
    'viskuo',
    'visoje',
    'visokį',
    'visuma',
    'visumą',
    'vištas',
    'vištoj',
    'vištos',
    'vogtam',
    'vogtas',
    'vyksta',
    'vykusį',
    'vylėsi',
    'vypsai',
    'vyrais',
    'vyrams',
    'vyruko',
    'vyrukų',
    'vyruti',
    'vytine',
    'vytinė',
    'vytinę',
    'vyšnia',
    'vyšnių',
    'vėdarų',
    'vėdina',
    'vėjais',
    'vėlais',
    'vėliau',
    'vėluos',
    'vėplio',
    'vėsoka',
    'vėžius',
    'vėžlio',
    'vėžliu',
    'vėžlių',
    'vėžlys',
    'ydinga',
    'zakonų',
    'zibalų',
    'zondas',
    'zulink',
    'zylėms',
    'ąžuolų',
    'čekius',
    'čerpių',
    'čiaupo',
    'čiaupą',
    'čigonė',
    'čirškė',
    'čiumpa',
    'čiupęs',
    'čiurlį',
    'čiurnų',
    'ėdžias',
    'ėdžios',
    'ėjimas',
    'ėsdavo',
    'įaugęs',
    'įbedęs',
    'įbrido',
    'įbruka',
    'įbruko',
    'įdavęs',
    'įdiegė',
    'įdomią',
    'įdomių',
    'įdomus',
    'įdomūs',
    'įdubęs',
    'įduoti',
    'įduotą',
    'įdėjai',
    'įgauti',
    'įgelti',
    'įgeltą',
    'įgijęs',
    'įgimto',
    'įgimtą',
    'įgriso',
    'įgrūdo',
    'įgrūst',
    'įguldė',
    'įgulos',
    'įgytas',
    'įgytos',
    'įgėlus',
    'įjungt',
    'įjungė',
    'įkaitu',
    'įkalnė',
    'įkalėm',
    'įkasti',
    'įkelta',
    'įkištų',
    'įkišus',
    'įkišęs',
    'įklotą',
    'įkopti',
    'įkrovą',
    'įkurta',
    'įkurti',
    'įkurti',
    'įkurto',
    'įkyriu',
    'įkyrių',
    'įkyrus',
    'įkąsta',
    'įkąstų',
    'įkėlęs',
    'įkūrus',
    'įkūręs',
    'įlanką',
    'įlankų',
    'įleidę',
    'įlekia',
    'įlindo',
    'įlipam',
    'įlipęs',
    'įmetus',
    'įmetęs',
    'įmokas',
    'įmonei',
    'įmones',
    'įmonių',
    'įmonėj',
    'įmonės',
    'įnagio',
    'įnašus',
    'įnešti',
    'įnikau',
    'įnorių',
    'įpratę',
    'įpuola',
    'įpuolė',
    'įpūsta',
    'įrangą',
    'įrašei',
    'įrašom',
    'įrašui',
    'įrašus',
    'įrašyk',
    'įrašyt',
    'įrašėm',
    'įremia',
    'įrieda',
    'įrišus',
    'įrodau',
    'įrodys',
    'įrėžęs',
    'įsidėk',
    'įsiuto',
    'įskilo',
    'įsmigo',
    'įsmuko',
    'įspėja',
    'įspėjo',
    'įspėję',
    'įspėti',
    'įspėti',
    'įstabi',
    'įstoja',
    'įstoję',
    'įstūmė',
    'įstūmę',
    'įsukto',
    'įsuktų',
    'įsunkę',
    'įsūniu',
    'įtaigų',
    'įtaiso',
    'įtaisų',
    'įtakos',
    'įtampą',
    'įtariu',
    'įtarus',
    'įtaręs',
    'įteiks',
    'įteikė',
    'įtrink',
    'įtrins',
    'įtūžio',
    'įvairų',
    'įvaldė',
    'įvaldę',
    'įveiką',
    'įveikė',
    'įveria',
    'įvesta',
    'įvesti',
    'įvežta',
    'įvežti',
    'įvores',
    'įvykdė',
    'įvykio',
    'įvykis',
    'įvykiu',
    'įvykių',
    'įvykti',
    'įvyktų',
    'įvykus',
    'įvykęs',
    'įėjimo',
    'įėjusi',
    'įšilus',
    'įžangų',
    'įžeidė',
    'įžengė',
    'įžengę',
    'įžymus',
    'įžymūs',
    'šachtų',
    'šakinė',
    'šaknis',
    'šaknys',
    'šakose',
    'šakutę',
    'šalies',
    'šalims',
    'šalimu',
    'šalnos',
    'šaltis',
    'šaltos',
    'šaltus',
    'šalyje',
    'šalyse',
    'šalčio',
    'šalčiu',
    'šalčių',
    'šarkos',
    'šarmus',
    'šaudyk',
    'šaudęs',
    'šauksi',
    'šaukti',
    'šauktų',
    'šaulio',
    'šaulių',
    'šaulys',
    'šaunių',
    'šaunūs',
    'šausim',
    'šeimas',
    'šeimoj',
    'šeimos',
    'šerdis',
    'šernai',
    'šernas',
    'šernus',
    'šernės',
    'šiaudo',
    'šiaudų',
    'šiaurė',
    'šiaurę',
    'šienas',
    'šilkas',
    'šiltas',
    'šiltoj',
    'šiltos',
    'šiltus',
    'šiluma',
    'šilumą',
    'šimtas',
    'šimtus',
    'širdyj',
    'širdys',
    'širmas',
    'širsta',
    'šitame',
    'šitoje',
    'šitoks',
    'šitokį',
    'šituos',
    'šiuose',
    'šlaite',
    'šlaito',
    'šlaitu',
    'šlaitą',
    'šlaitų',
    'šlapio',
    'šlapiu',
    'šlapią',
    'šlapių',
    'šlaunų',
    'šleifo',
    'šliurė',
    'šliužų',
    'šliūbo',
    'šliūžė',
    'šlovės',
    'šluoja',
    'šluota',
    'šluoti',
    'šluotą',
    'šmėklą',
    'šmėklų',
    'šmėkso',
    'šnekam',
    'šnekas',
    'šnekat',
    'šnekos',
    'šnekės',
    'šnekėt',
    'šokius',
    'šoksiu',
    'šokėjų',
    'šonais',
    'šovusi',
    'špagos',
    'špokai',
    'špokus',
    'šriftu',
    'šriftą',
    'šriftų',
    'šriubų',
    'štampo',
    'šulinį',
    'šumerų',
    'šuneli',
    'šunims',
    'šunyti',
    'šunėko',
    'šunėką',
    'šuolis',
    'šuoras',
    'švariu',
    'švarią',
    'švarko',
    'švarką',
    'švarus',
    'švarūs',
    'švedai',
    'šveisk',
    'šveitė',
    'švelni',
    'švelnų',
    'šventa',
    'šventi',
    'švento',
    'šventu',
    'šventą',
    'šventė',
    'šventę',
    'šventų',
    'šviesa',
    'šviest',
    'šviesu',
    'šviesą',
    'šviesų',
    'šviesų',
    'švietė',
    'švilpt',
    'švinta',
    'švęsit',
    'švęsti',
    'švęstu',
    'šypsnį',
    'šėlimo',
    'šėlsmą',
    'šėrimo',
    'šėrimą',
    'šėrimų',
    'šėtonu',
    'šėtoną',
    'šūkavo',
    'šūkius',
    'šūvius',
    'ūglius',
    'ūkanos',
    'ūkiams',
    'ūkines',
    'ūkinės',
    'ūkteli',
    'ūžimas',
    'žadino',
    'žadėjo',
    'žagrės',
    'žaibus',
    'žaislą',
    'žaislų',
    'žaismė',
    'žaizdų',
    'žaliom',
    'žalios',
    'žalius',
    'žalsvi',
    'žalsvo',
    'žalsvą',
    'žaltys',
    'žalčio',
    'žarijų',
    'žarnos',
    'žarose',
    'žarsto',
    'žarstė',
    'žavesį',
    'žavėjo',
    'žemina',
    'žemyno',
    'žengia',
    'žengti',
    'ženklo',
    'ženklu',
    'ženklą',
    'ženklų',
    'žentas',
    'žentus',
    'žiaunų',
    'žiaurų',
    'žibalo',
    'žibutė',
    'žiedas',
    'žiedus',
    'žiemas',
    'žiemos',
    'žievės',
    'žiloje',
    'žindęs',
    'žinoma',
    'žinome',
    'žinomą',
    'žinomų',
    'žinote',
    'žinotų',
    'žinovė',
    'žinovų',
    'žinute',
    'žinutė',
    'žinutę',
    'žinybų',
    'žiotys',
    'žirafą',
    'žirafų',
    'žirgas',
    'žiurkė',
    'žiurkę',
    'žiūrėk',
    'žiūrės',
    'žiūrėt',
    'žlegsi',
    'žlibas',
    'žlibės',
    'žlugdo',
    'žlugti',
    'žlugto',
    'žlunga',
    'žmogau',
    'žmogui',
    'žmogus',
    'žmonai',
    'žmones',
    'žmonių',
    'žmonės',
    'žodelį',
    'žodine',
    'žodinė',
    'žodyje',
    'žodyne',
    'žodynu',
    'žodyną',
    'žodynų',
    'žoline',
    'žolyne',
    'žolytė',
    'žolėje',
    'žolėms',
    'žudiko',
    'žudikę',
    'žudikų',
    'žudomi',
    'žudymo',
    'žudymą',
    'žudyti',
    'žuvelė',
    'žuvelę',
    'žuvies',
    'žuvimo',
    'žuvims',
    'žuvyje',
    'žuvytė',
    'žvakes',
    'žvakių',
    'žvakės',
    'žvalgų',
    'žvalūs',
    'žvejai',
    'žvejys',
    'žvelgi',
    'žvelgė',
    'žvynai',
    'žvynus',
    'žvyras',
    'žvėrie',
    'žvėris',
    'žvėrių',
    'žvėrys',
    'žydrės',
    'žydėjo',
    'žydėti',
    'žydėtų',
    'žygius',
    'žyminį',
    'žymios',
    'žymius',
    'žymėjo',
    'žymėti',
    'žymėtų',
    'žąslai',
    'žūklės',
]
