export const WORDS = [
    'abidvi',
    'abiejų',
    'abiems',
    'abrako',
    'abraką',
    'adatai',
    'adatos',
    'adreso',
    'adresu',
    'adresą',
    'agentė',
    'agentų',
    'aguonų',
    'agurko',
    'aikštė',
    'aikštę',
    'aimanų',
    'aimarų',
    'aistra',
    'aistrų',
    'aiškią',
    'aiškių',
    'aiškus',
    'aiškūs',
    'akcija',
    'akciją',
    'akcijų',
    'akcine',
    'akcinė',
    'akcinį',
    'akcizų',
    'akimis',
    'akinių',
    'akmens',
    'akmenį',
    'akmenų',
    'aktais',
    'aktorė',
    'aktyvi',
    'aktyvą',
    'aktyvų',
    'akylas',
    'albite',
    'aliejų',
    'alinės',
    'alkani',
    'alkanų',
    'alkūne',
    'alpsta',
    'alsaus',
    'alučio',
    'alyvas',
    'alyvos',
    'alėjoj',
    'amarai',
    'amatai',
    'amatas',
    'amatui',
    'amonio',
    'ampulę',
    'amteli',
    'amžina',
    'amžini',
    'amžino',
    'amžinu',
    'amžiną',
    'amžinų',
    'amžiui',
    'amžium',
    'amžius',
    'anasai',
    'angelo',
    'angelą',
    'angelų',
    'angina',
    'anglai',
    'anglas',
    'anglis',
    'anglių',
    'anketą',
    'anketų',
    'ankstų',
    'ankšta',
    'ankšti',
    'ankštą',
    'anokie',
    'anomis',
    'anonsą',
    'antele',
    'antena',
    'anteną',
    'antika',
    'antims',
    'antrai',
    'antram',
    'antras',
    'antroj',
    'antron',
    'antros',
    'antrus',
    'anuose',
    'anūkai',
    'anūkas',
    'anūkių',
    'anūkus',
    'aortai',
    'aortos',
    'apaugo',
    'apaugę',
    'apavas',
    'apačia',
    'apačią',
    'apdaro',
    'apdaru',
    'apdegė',
    'apdėjo',
    'apeiga',
    'apeigą',
    'apeigų',
    'apeiti',
    'apeitų',
    'apgavo',
    'apgins',
    'apgulė',
    'apgynė',
    'apimta',
    'apimti',
    'apimtį',
    'apkalė',
    'apkasą',
    'aplipę',
    'apmirė',
    'apmoka',
    'apnašų',
    'apniko',
    'apnikę',
    'apraše',
    'aprašo',
    'aprašo',
    'aprašą',
    'aprašė',
    'aprašų',
    'aprimo',
    'aprims',
    'aprišk',
    'aprodė',
    'aprėkė',
    'aprėpė',
    'apsakė',
    'apsals',
    'apsems',
    'apstos',
    'apsuka',
    'apsuko',
    'apsuks',
    'apsukę',
    'apsupa',
    'apsupo',
    'apsups',
    'apsupę',
    'apsėdo',
    'apsėmė',
    'apsėti',
    'aptarė',
    'aptemo',
    'aptiko',
    'aptikę',
    'apvers',
    'apvogė',
    'apvožė',
    'apynys',
    'apėjom',
    'apėmęs',
    'apšepę',
    'apšąla',
    'apžėlę',
    'arbata',
    'arbatą',
    'ardama',
    'ardymo',
    'ardyti',
    'arenos',
    'arešto',
    'ariama',
    'arijas',
    'arimai',
    'arimas',
    'arkada',
    'arklio',
    'arkliu',
    'arklių',
    'arklys',
    'armija',
    'armiją',
    'artelę',
    'artima',
    'artimi',
    'artimo',
    'artimu',
    'artimą',
    'artimų',
    'artina',
    'artojų',
    'artumo',
    'artumą',
    'artėja',
    'artėjo',
    'artėju',
    'artėti',
    'artėtų',
    'asirai',
    'asmens',
    'asmenį',
    'asmenų',
    'astmos',
    'atakas',
    'atakos',
    'atauga',
    'ataugą',
    'ataugų',
    'atbuko',
    'atbėga',
    'atbėgo',
    'atbėgs',
    'atbūti',
    'ateina',
    'ateini',
    'ateinu',
    'ateisi',
    'ateiti',
    'ateity',
    'ateitį',
    'ateitų',
    'ateivė',
    'ateivį',
    'atgavo',
    'atgijo',
    'atgiję',
    'atgimė',
    'atgins',
    'atguls',
    'atgulė',
    'atgyja',
    'atidus',
    'atidėt',
    'atidūs',
    'atimta',
    'atimti',
    'atjojo',
    'atkišo',
    'atkišę',
    'atkuto',
    'atkūrė',
    'atlapa',
    'atlapo',
    'atlapų',
    'atleis',
    'atlenk',
    'atliko',
    'atliks',
    'atlikę',
    'atlošę',
    'atlupt',
    'atlupu',
    'atlygį',
    'atlėgo',
    'atlėks',
    'atmeni',
    'atmenu',
    'atmeta',
    'atmetė',
    'atmetę',
    'atmynė',
    'atneša',
    'atneši',
    'atnešk',
    'atnešt',
    'atnešė',
    'atnešę',
    'atomai',
    'atomas',
    'atomus',
    'atpila',
    'atrado',
    'atradę',
    'atrama',
    'atramą',
    'atrasi',
    'atrast',
    'atrašė',
    'atriša',
    'atrodo',
    'atrodė',
    'atryti',
    'atrėmė',
    'atsako',
    'atsako',
    'atsaką',
    'atsakė',
    'atsegė',
    'atsekė',
    'atspėk',
    'atstok',
    'atstos',
    'atstot',
    'atsuko',
    'atsuku',
    'atvaro',
    'atvarė',
    'atveda',
    'atvedė',
    'atvedę',
    'atvejo',
    'atveju',
    'atvejį',
    'atvejų',
    'atvers',
    'atvesk',
    'atveža',
    'atvežk',
    'atvežt',
    'atvežė',
    'atvira',
    'atviri',
    'atviru',
    'atvirą',
    'atvirų',
    'atvyko',
    'atvykę',
    'atvėrė',
    'atvėrę',
    'atvėso',
    'atėjai',
    'atėjau',
    'atėjom',
    'atėjus',
    'atėjęs',
    'atšaka',
    'atšaką',
    'atšakų',
    'atšalo',
    'atšals',
    'atšilo',
    'atšilę',
    'atšipo',
    'atšoka',
    'atšoko',
    'atšąla',
    'atžalą',
    'atžalų',
    'audinį',
    'audras',
    'audros',
    'audėją',
    'audėjų',
    'augalo',
    'augalų',
    'augame',
    'augant',
    'augimo',
    'augimu',
    'augimą',
    'augina',
    'augino',
    'augint',
    'auginu',
    'auglių',
    'auglys',
    'augumo',
    'auklės',
    'aukoja',
    'aukojo',
    'aukota',
    'aukoti',
    'aukuru',
    'aukšta',
    'aukšte',
    'aukšti',
    'aukšto',
    'aukšto',
    'aukštu',
    'aukštu',
    'aukštą',
    'aukštą',
    'aukštį',
    'aukštų',
    'aukštų',
    'aulėje',
    'ausiai',
    'ausies',
    'ausimi',
    'ausims',
    'austrų',
    'ausyse',
    'autais',
    'aušina',
    'aušrai',
    'avansą',
    'avieną',
    'avinui',
    'avinus',
    'ašaras',
    'ašarom',
    'ašaros',
    'ašmenų',
    'aštrią',
    'aštrių',
    'aštrus',
    'aštrūs',
    'bacilų',
    'bagažo',
    'bagažu',
    'bagažą',
    'baigia',
    'baigiu',
    'baigta',
    'baigti',
    'baigti',
    'baigtį',
    'baigus',
    'baigėm',
    'baigęs',
    'bailys',
    'baimei',
    'baimių',
    'baimės',
    'baisia',
    'baisią',
    'baisių',
    'baisus',
    'bajorų',
    'bakūžė',
    'balais',
    'baldai',
    'baldus',
    'baleto',
    'baletą',
    'balinė',
    'balius',
    'balsai',
    'balsan',
    'balsas',
    'balsio',
    'balsių',
    'balsui',
    'balsus',
    'balsva',
    'baltai',
    'baltas',
    'baltom',
    'baltos',
    'baltus',
    'balute',
    'bambas',
    'bambly',
    'bambos',
    'bananų',
    'bandas',
    'bandau',
    'bandos',
    'bandyk',
    'bandys',
    'bandėm',
    'bandęs',
    'bangai',
    'bangas',
    'bangos',
    'bankai',
    'bankas',
    'bankui',
    'bankus',
    'barake',
    'barako',
    'baraku',
    'baraką',
    'barams',
    'baroko',
    'barsto',
    'barzda',
    'barzdą',
    'barška',
    'baržas',
    'baržos',
    'basoną',
    'batais',
    'baudas',
    'baudos',
    'baugūs',
    'bausme',
    'bausmė',
    'bausmę',
    'bausti',
    'bazinė',
    'bazinę',
    'bazėje',
    'bazėms',
    'bazėse',
    'bebrai',
    'bebrus',
    'bebuvo',
    'bebuvę',
    'bebūtų',
    'begale',
    'begali',
    'begalė',
    'begalę',
    'beimąs',
    'bejėgė',
    'bekojo',
    'bekojį',
    'bekonų',
    'belgas',
    'beliko',
    'belikę',
    'bendra',
    'bendri',
    'bendro',
    'bendru',
    'bendrą',
    'bendrų',
    'bendrų',
    'bepigu',
    'beribį',
    'bernai',
    'bernas',
    'bernui',
    'beržai',
    'beržas',
    'beržus',
    'betono',
    'betoną',
    'bijojo',
    'bijoma',
    'bijome',
    'bijota',
    'bijoti',
    'biksai',
    'biraus',
    'birios',
    'birius',
    'biržas',
    'biržoj',
    'biržos',
    'bitelė',
    'bitinų',
    'biurai',
    'biuras',
    'biurus',
    'biznis',
    'bjauri',
    'bjauru',
    'bjaurų',
    'blizga',
    'bliūdu',
    'blogam',
    'blogas',
    'blogio',
    'blogis',
    'blogos',
    'blogus',
    'blokas',
    'blokui',
    'blokus',
    'blusos',
    'blužnį',
    'blyksi',
    'blynas',
    'blynus',
    'blėsta',
    'blėsti',
    'blūdui',
    'bobutė',
    'bokalą',
    'boksas',
    'bokšte',
    'bokšto',
    'bokštu',
    'bokštą',
    'bokštų',
    'bolavo',
    'bombos',
    'bonios',
    'bortus',
    'bosnis',
    'bosnių',
    'botago',
    'botagą',
    'braižo',
    'braižė',
    'brangi',
    'brangu',
    'brangų',
    'braška',
    'brenda',
    'bristi',
    'bričką',
    'brokas',
    'brolio',
    'brolis',
    'broliu',
    'brolių',
    'bronza',
    'bronzą',
    'bruožu',
    'bruožą',
    'bruožų',
    'brydės',
    'brėško',
    'brėžia',
    'bręsta',
    'budina',
    'budriu',
    'budrus',
    'budrūs',
    'budėti',
    'budžiu',
    'bufeto',
    'bukais',
    'bukina',
    'bukumo',
    'bulves',
    'bulvių',
    'bulvėm',
    'bulvės',
    'buožės',
    'burnai',
    'burnas',
    'burnoj',
    'burnos',
    'buroke',
    'burtai',
    'burtis',
    'burtus',
    'butelį',
    'buvimo',
    'buvimu',
    'buvimą',
    'buvome',
    'buvusi',
    'buvusį',
    'bylant',
    'byloja',
    'byloje',
    'bylojo',
    'byrėti',
    'bėdoję',
    'bėgant',
    'bėgiai',
    'bėgius',
    'bėglių',
    'bėglys',
    'bėgsim',
    'bėgsiu',
    'bėgyje',
    'būdais',
    'būdama',
    'būdami',
    'būdavo',
    'būdavę',
    'būdelė',
    'būgnai',
    'būkime',
    'būkite',
    'būklei',
    'būklės',
    'būname',
    'būrelį',
    'būriai',
    'būrimo',
    'būriui',
    'būryje',
    'būsena',
    'būseną',
    'būsenų',
    'būsima',
    'būsime',
    'būsimi',
    'būsimo',
    'būsimu',
    'būsimą',
    'būsimų',
    'būsite',
    'būsiąs',
    'būstai',
    'būstas',
    'būstui',
    'būstus',
    'būties',
    'būtina',
    'būtini',
    'būtiną',
    'būtinų',
    'būtume',
    'būtybe',
    'būtybė',
    'būtybę',
    'būtyje',
    'būčiau',
    'cechai',
    'cechas',
    'cechui',
    'centai',
    'centre',
    'centro',
    'centru',
    'centrą',
    'centrų',
    'centus',
    'chaosą',
    'choras',
    'chorui',
    'chorus',
    'ciklai',
    'ciklas',
    'ciklui',
    'ciklus',
    'cirkas',
    'cirozė',
    'citata',
    'citatą',
    'colinę',
    'cukrus',
    'cypimo',
    'dabina',
    'dabita',
    'dagiai',
    'dagiui',
    'daigai',
    'daigus',
    'daigus',
    'daikto',
    'daiktu',
    'daiktą',
    'daiktų',
    'dailiu',
    'dailus',
    'dailės',
    'dainas',
    'dainoj',
    'dainom',
    'dainos',
    'dairos',
    'dalelė',
    'dalelę',
    'dalgis',
    'dalgiu',
    'dalgių',
    'daliai',
    'dalies',
    'dalijo',
    'dalimi',
    'dalims',
    'dalina',
    'daline',
    'dalino',
    'dalins',
    'dalyba',
    'dalybų',
    'dalyje',
    'dalyko',
    'dalyku',
    'dalyką',
    'dalykų',
    'dalyse',
    'dalyti',
    'dalyve',
    'danais',
    'danams',
    'dangos',
    'dangtį',
    'danguj',
    'dangum',
    'dangun',
    'dangus',
    'dantis',
    'dantys',
    'darant',
    'darbai',
    'darbas',
    'darbui',
    'darbus',
    'dariau',
    'darius',
    'darius',
    'darnių',
    'darnus',
    'daroma',
    'daromi',
    'daromą',
    'darosi',
    'darymą',
    'darysi',
    'daryta',
    'daryti',
    'darytų',
    'darėsi',
    'daržas',
    'daržus',
    'datyvo',
    'daubos',
    'dausas',
    'dausos',
    'daužei',
    'daužyk',
    'daviau',
    'davinį',
    'davusi',
    'davėme',
    'davėsi',
    'dažais',
    'dažnas',
    'dažnio',
    'dažnis',
    'dažniu',
    'dažnos',
    'dažnus',
    'dažosi',
    'dažysi',
    'dažyti',
    'dažytą',
    'debesį',
    'debesų',
    'debeto',
    'debitą',
    'dedama',
    'dedami',
    'dedasi',
    'degalų',
    'degant',
    'degimų',
    'degina',
    'degino',
    'degint',
    'deguto',
    'degėsi',
    'deives',
    'deivių',
    'deivės',
    'dejonė',
    'dejonę',
    'dejuos',
    'dekada',
    'dekadą',
    'dekano',
    'delnai',
    'delnus',
    'delsia',
    'delsti',
    'deltos',
    'demonų',
    'dengia',
    'dengti',
    'dengtų',
    'dengęs',
    'derama',
    'deramo',
    'deramą',
    'dergsi',
    'dergęs',
    'derina',
    'derint',
    'derinu',
    'derlių',
    'dermės',
    'dervos',
    'derybų',
    'derėjo',
    'derėtų',
    'detalė',
    'detalę',
    'devono',
    'devyni',
    'dešimt',
    'dešine',
    'dešinę',
    'dešinę',
    'dešinį',
    'dešros',
    'didele',
    'dideli',
    'didelė',
    'didelę',
    'didelį',
    'didikų',
    'didina',
    'didino',
    'didoką',
    'didumo',
    'didumą',
    'didybe',
    'didybė',
    'didybę',
    'didėja',
    'didėjo',
    'didėti',
    'didįjį',
    'didžia',
    'didžiu',
    'didžių',
    'diegia',
    'diegti',
    'dienai',
    'dienas',
    'dienom',
    'dienon',
    'dienos',
    'dietas',
    'dietos',
    'dievai',
    'dievas',
    'dievop',
    'dievui',
    'dievus',
    'dilbio',
    'dilema',
    'dilimo',
    'dimsty',
    'dinarą',
    'dinarų',
    'dingai',
    'dingti',
    'dingtų',
    'dingęs',
    'dirbai',
    'dirbam',
    'dirbat',
    'dirbau',
    'dirbom',
    'dirbta',
    'dirbti',
    'dirbto',
    'dirbtų',
    'dirbtų',
    'dirbęs',
    'dirses',
    'dirsių',
    'dirvos',
    'diržai',
    'diržas',
    'diržus',
    'diskai',
    'diskas',
    'dobilų',
    'dolerį',
    'domina',
    'domino',
    'domino',
    'domisi',
    'donjos',
    'donoro',
    'donorų',
    'dorais',
    'dorinį',
    'dorovė',
    'dosnus',
    'dovana',
    'dovaną',
    'dovanų',
    'dramai',
    'dramos',
    'draudė',
    'drauge',
    'draugo',
    'draugu',
    'draugą',
    'draugė',
    'draugę',
    'draugų',
    'drebia',
    'driežų',
    'drimba',
    'drobių',
    'drobės',
    'drovus',
    'drožti',
    'drugio',
    'drugių',
    'drugys',
    'druska',
    'druską',
    'druskų',
    'druzgo',
    'drąsiu',
    'drąsių',
    'drąsos',
    'drąsus',
    'drąsūs',
    'drėgmė',
    'drėgmę',
    'drėgna',
    'drėgni',
    'drėgno',
    'drėgną',
    'drįsęs',
    'drūtas',
    'dubens',
    'dubenį',
    'duburį',
    'duetas',
    'dugnas',
    'dujoms',
    'dukras',
    'dukrom',
    'dukros',
    'dulkes',
    'dulkių',
    'dulkės',
    'dumble',
    'dumblo',
    'dumblu',
    'dunksi',
    'dunkso',
    'duobes',
    'duobti',
    'duobėn',
    'duobės',
    'duodam',
    'duodat',
    'duokit',
    'duoklė',
    'duoklę',
    'duonos',
    'duosiu',
    'duotas',
    'duotum',
    'duotus',
    'durklą',
    'durnas',
    'durpes',
    'durpių',
    'durpės',
    'duryse',
    'duslių',
    'duslus',
    'dušais',
    'dvaras',
    'dvasas',
    'dvasia',
    'dvasią',
    'dvasių',
    'dvejas',
    'dvejus',
    'dvelkė',
    'dviejų',
    'dvynio',
    'dvynių',
    'dvynys',
    'dydžio',
    'dydžių',
    'dygaus',
    'dygimo',
    'dykuma',
    'dykumą',
    'dykumų',
    'dykyne',
    'dykynė',
    'dziudo',
    'dėduku',
    'dėduli',
    'dėjome',
    'dėjosi',
    'dėkoja',
    'dėkojo',
    'dėkoju',
    'dėmelė',
    'dėmelę',
    'dėmesį',
    'dėmėti',
    'dėsies',
    'dėsnio',
    'dėsnis',
    'dėsniu',
    'dėsnių',
    'dėstys',
    'dėtyje',
    'dėtųsi',
    'dėžutė',
    'dėžutę',
    'dėžėje',
    'dėžėse',
    'dūksta',
    'dūlinu',
    'dūlėja',
    'dūmais',
    'dūmiau',
    'dūsavo',
    'dūzgia',
    'dūžiai',
    'džiazo',
    'džinas',
    'džinsų',
    'džiugu',
    'džiugų',
    'džiūti',
    'džiūva',
    'efekto',
    'efektu',
    'efektą',
    'eglutė',
    'eglutę',
    'eglyne',
    'egzema',
    'egzodo',
    'egzotų',
    'eidama',
    'eidami',
    'eidavo',
    'eigoje',
    'eigulį',
    'eikite',
    'eiline',
    'eilinę',
    'eilinį',
    'eilute',
    'eilutė',
    'eilutę',
    'eilėje',
    'eilėms',
    'eilėse',
    'einama',
    'einame',
    'einant',
    'einasi',
    'eisena',
    'eiseną',
    'eisime',
    'eismas',
    'eitume',
    'eičiau',
    'eketės',
    'ekipos',
    'ekrane',
    'ekrano',
    'ekraną',
    'ekranų',
    'elgesį',
    'elgsis',
    'elgtis',
    'elgtis',
    'elgėsi',
    'elipse',
    'elniai',
    'emalio',
    'emaliu',
    'epocha',
    'epochą',
    'epochų',
    'erdves',
    'erdvių',
    'erdvės',
    'erelio',
    'erelis',
    'ereliu',
    'erosas',
    'ertmes',
    'ertmių',
    'erzelį',
    'erzina',
    'esamas',
    'esamos',
    'esamus',
    'esanti',
    'esantį',
    'esatys',
    'eskimų',
    'esminę',
    'esminį',
    'esmėje',
    'estams',
    'esybių',
    'esybės',
    'esėjus',
    'etapai',
    'etapas',
    'etapui',
    'etapus',
    'etatas',
    'etatus',
    'eterio',
    'eteriu',
    'etikai',
    'etikos',
    'etinis',
    'etinių',
    'etinės',
    'etninė',
    'etninę',
    'etosas',
    'ešeriu',
    'ežerai',
    'ežeras',
    'ežerus',
    'failai',
    'failas',
    'failus',
    'fainas',
    'fakelu',
    'faktai',
    'faktas',
    'faktus',
    'fasoną',
    'fazanų',
    'fazinę',
    'fazėje',
    'fermas',
    'fermos',
    'figūra',
    'figūrą',
    'figūrų',
    'fiksas',
    'filmai',
    'filmas',
    'filmus',
    'filtre',
    'filtro',
    'filtrą',
    'filtrų',
    'finais',
    'finale',
    'finalo',
    'fiordų',
    'firmai',
    'firmas',
    'firmos',
    'fizika',
    'fiziką',
    'fizikų',
    'fizine',
    'fizinė',
    'fizinę',
    'fizinį',
    'fluoro',
    'fobiją',
    'fokusą',
    'fondai',
    'fondas',
    'fondui',
    'fondus',
    'foninė',
    'fordas',
    'fordui',
    'formai',
    'formas',
    'formos',
    'fortai',
    'fortas',
    'forumą',
    'forumų',
    'frakas',
    'frankų',
    'frazių',
    'frazės',
    'fricai',
    'fricas',
    'fricus',
    'fronte',
    'fronto',
    'furija',
    'gabalo',
    'gabalu',
    'gabalą',
    'gabalų',
    'gabena',
    'gabeno',
    'gabens',
    'gabumų',
    'gadina',
    'gadinu',
    'gadynė',
    'gadynę',
    'gaidys',
    'gaires',
    'gairių',
    'gairės',
    'gaisro',
    'gaisrą',
    'gaisrų',
    'gaiviu',
    'gaivus',
    'gaivūs',
    'gaišta',
    'galais',
    'galiai',
    'galias',
    'galima',
    'galime',
    'galimi',
    'galimą',
    'galimų',
    'galine',
    'galinė',
    'galinę',
    'galinį',
    'galios',
    'galios',
    'galite',
    'galvai',
    'galvas',
    'galvoj',
    'galvok',
    'galvom',
    'galvon',
    'galvos',
    'galvot',
    'galybė',
    'galybę',
    'galėjo',
    'galėję',
    'galėsi',
    'galėtų',
    'galūnė',
    'galūnę',
    'gamina',
    'gamino',
    'gamins',
    'gaminį',
    'gamtai',
    'gamtoj',
    'gamton',
    'gamtos',
    'gamyba',
    'gamybą',
    'gandai',
    'gandas',
    'gandro',
    'gandrų',
    'gandus',
    'ganyti',
    'garavo',
    'garaže',
    'garažo',
    'garbei',
    'garbės',
    'gardai',
    'gardas',
    'gardus',
    'garnių',
    'garnys',
    'garsai',
    'garsas',
    'garsią',
    'garsių',
    'garsus',
    'garšva',
    'gatavų',
    'gatvei',
    'gatves',
    'gatvių',
    'gatvėj',
    'gatvėm',
    'gatvėn',
    'gatvės',
    'gaubia',
    'gaubto',
    'gaubtu',
    'gaubtą',
    'gaubtą',
    'gaujos',
    'gausai',
    'gausim',
    'gausis',
    'gausiu',
    'gausių',
    'gausmą',
    'gausos',
    'gausus',
    'gausūs',
    'gautas',
    'gautos',
    'gautum',
    'gautus',
    'gavimo',
    'gavome',
    'gavosi',
    'gavusi',
    'gavyba',
    'gavėja',
    'gavėjo',
    'gašlių',
    'gedimo',
    'gedimu',
    'gedimų',
    'gedulo',
    'gegnėm',
    'gegute',
    'gegutė',
    'geguže',
    'gegužė',
    'gegužę',
    'geibus',
    'geismų',
    'gelbėk',
    'gelbės',
    'gelbėt',
    'geldos',
    'geležį',
    'gelmes',
    'gelmių',
    'gelmėn',
    'gelsti',
    'gelsva',
    'gelsvu',
    'gelsvą',
    'gelsvų',
    'gemalo',
    'genijų',
    'gentys',
    'genčių',
    'gerais',
    'gerbia',
    'gerbiu',
    'gerbti',
    'gerbėm',
    'gerina',
    'gerkle',
    'gerklė',
    'gerklę',
    'gerklų',
    'geroje',
    'geroji',
    'gerojo',
    'geroka',
    'gerose',
    'gerove',
    'gerovė',
    'gerovę',
    'gersit',
    'gerumo',
    'gerumu',
    'gerumą',
    'gervių',
    'gervės',
    'gerąja',
    'gerąjį',
    'gerėja',
    'gerųjų',
    'gesint',
    'gestus',
    'giedra',
    'giedrą',
    'giesme',
    'giesmė',
    'giesmę',
    'gijimo',
    'gilaus',
    'gilias',
    'gilina',
    'gilios',
    'gilius',
    'gilumo',
    'gilumą',
    'gilužę',
    'gilėja',
    'gimdai',
    'gimdos',
    'gimiau',
    'gimimo',
    'gimimą',
    'giminė',
    'giminę',
    'gimsta',
    'gimtas',
    'gimtos',
    'gimtus',
    'gimusi',
    'gimusį',
    'gimėme',
    'ginama',
    'ginant',
    'ginasi',
    'ginklo',
    'ginklu',
    'ginklą',
    'ginklų',
    'ginsis',
    'gintis',
    'gintis',
    'gintis',
    'ginčai',
    'ginčas',
    'ginčui',
    'ginčus',
    'girdyk',
    'girdės',
    'girdėt',
    'girias',
    'girion',
    'girios',
    'girnas',
    'girnos',
    'girtut',
    'gitara',
    'gitarą',
    'glaudi',
    'glaudė',
    'glaudų',
    'glebus',
    'gležni',
    'gležną',
    'glismo',
    'glismu',
    'globai',
    'globia',
    'globos',
    'glokai',
    'glosto',
    'glostė',
    'glėbin',
    'glėbio',
    'godulį',
    'godumo',
    'gojaus',
    'gorila',
    'gotika',
    'govėda',
    'grafas',
    'grafos',
    'graikų',
    'gramai',
    'grasos',
    'grasūs',
    'graudu',
    'graužė',
    'gražia',
    'gražiu',
    'gražią',
    'gražių',
    'gražus',
    'gražūs',
    'greita',
    'greito',
    'greitu',
    'greitą',
    'greitį',
    'gresia',
    'gretas',
    'gretos',
    'griebk',
    'griebs',
    'griebė',
    'grieką',
    'griežt',
    'griežė',
    'grikių',
    'grindų',
    'griovą',
    'griovė',
    'griovį',
    'griovų',
    'gripas',
    'griuvo',
    'griūna',
    'griūni',
    'griūtį',
    'griūva',
    'grobas',
    'grobio',
    'grobis',
    'grojau',
    'grokit',
    'grosiu',
    'grotas',
    'grožio',
    'grožis',
    'grožiu',
    'grubiu',
    'grubus',
    'grubūs',
    'grunto',
    'gruntu',
    'gruntą',
    'gruntų',
    'gruodą',
    'gruodį',
    'grupei',
    'grupes',
    'grupių',
    'grupėj',
    'grupės',
    'gruzda',
    'gružlį',
    'grybai',
    'grybas',
    'grybus',
    'grynas',
    'grynos',
    'grąžos',
    'grąžto',
    'grėsme',
    'grėsmė',
    'grėsmę',
    'grėsti',
    'gręžia',
    'gręžti',
    'grįsto',
    'grįstą',
    'grįšim',
    'grįšiu',
    'grįžai',
    'grįžau',
    'grįžot',
    'grįžta',
    'grįžti',
    'grįžti',
    'grįžtu',
    'grįžtų',
    'grįžus',
    'grįžęs',
    'grūdai',
    'grūdus',
    'grūšio',
    'gudrus',
    'gudrūs',
    'gulbių',
    'gulbės',
    'gulkis',
    'gultai',
    'gultis',
    'gulėjo',
    'gulėsi',
    'gulėti',
    'gumbai',
    'gumbus',
    'gumele',
    'guminę',
    'guminį',
    'gumulą',
    'guolio',
    'guolis',
    'guoliu',
    'guolių',
    'gurnus',
    'gydant',
    'gydoma',
    'gydome',
    'gydomi',
    'gydosi',
    'gydyme',
    'gydymo',
    'gydymu',
    'gydymą',
    'gydyti',
    'gydyto',
    'gydytų',
    'gydytų',
    'gydėsi',
    'gylyje',
    'gynimo',
    'gynyba',
    'gynybą',
    'gynybų',
    'gynėjų',
    'gynėsi',
    'gyrimą',
    'gyrėsi',
    'gyslos',
    'gyvais',
    'gyvata',
    'gyvatė',
    'gyvatę',
    'gyvavo',
    'gyvena',
    'gyveni',
    'gyvenk',
    'gyveno',
    'gyvens',
    'gyvent',
    'gyvenu',
    'gyvenę',
    'gyviai',
    'gyvius',
    'gyvoji',
    'gyvojo',
    'gyvuly',
    'gyvulį',
    'gyvuok',
    'gyvuos',
    'gyvybe',
    'gyvybė',
    'gyvybę',
    'gyvąja',
    'gyvąją',
    'gyvūno',
    'gyvūną',
    'gyvūnų',
    'gyvųjų',
    'gėlyne',
    'gėlynų',
    'gėlyte',
    'gėlėms',
    'gėlėtu',
    'gėlųjų',
    'gėralo',
    'gėriau',
    'gėrimo',
    'gėrimu',
    'gėrimą',
    'gėrimų',
    'gėrisi',
    'gėrybė',
    'gėrėsi',
    'gėrėte',
    'gūbrys',
    'gūdžią',
    'gūnios',
    'gūžtos',
    'herojė',
    'herojų',
    'hetitų',
    'hienos',
    'hindus',
    'hiperį',
    'humoro',
    'husarų',
    'ideali',
    'idealo',
    'idealu',
    'idėjai',
    'idėjas',
    'idėjos',
    'ietimi',
    'ieškau',
    'ieškok',
    'ieškos',
    'ieškot',
    'iešmai',
    'ikonos',
    'ilgais',
    'ilgesį',
    'ilgisi',
    'ilgiui',
    'ilgoje',
    'ilgoji',
    'ilgojo',
    'ilgoko',
    'ilgumo',
    'ilgumą',
    'ilgėja',
    'ilgėti',
    'ilgšis',
    'ilgųjų',
    'ilsisi',
    'imamas',
    'imamės',
    'imanti',
    'imdami',
    'imdavo',
    'imlios',
    'imlumo',
    'imlumą',
    'imsime',
    'imtasi',
    'imties',
    'imtųsi',
    'imčiau',
    'indais',
    'indams',
    'indelį',
    'indėlį',
    'indėnų',
    'inkaro',
    'inkarą',
    'inkarų',
    'inkile',
    'inkilo',
    'inkilą',
    'inkilų',
    'inksto',
    'inkstų',
    'intakų',
    'irbiai',
    'irzlus',
    'islamo',
    'ispanų',
    'italai',
    'italui',
    'išalkę',
    'išauga',
    'išauga',
    'išaugo',
    'išaugs',
    'išaušę',
    'išbalo',
    'išbers',
    'išburs',
    'išbuvo',
    'išbyra',
    'išbėga',
    'išbėgo',
    'išbėrė',
    'išbūna',
    'išbūrė',
    'išdava',
    'išdavė',
    'išdavę',
    'išdidi',
    'išdidų',
    'išdroš',
    'išduos',
    'išdygo',
    'išdygs',
    'išdygt',
    'išdūmė',
    'išeigą',
    'išeina',
    'išeini',
    'išeinu',
    'išeiną',
    'išeisi',
    'išeiti',
    'išeitį',
    'išeitų',
    'išgama',
    'išgerk',
    'išgers',
    'išgert',
    'išgirs',
    'išgrūs',
    'išgyti',
    'išgėrė',
    'išgėrę',
    'išimti',
    'išimtį',
    'iširti',
    'iširus',
    'išjojo',
    'išjunk',
    'iškalt',
    'iškasė',
    'iškels',
    'iškepa',
    'iškeps',
    'iškept',
    'iškili',
    'iškilo',
    'iškils',
    'iškilę',
    'iškiša',
    'iškiši',
    'iškišk',
    'iškišo',
    'iškišę',
    'iškyla',
    'iškėlė',
    'išleis',
    'išlesa',
    'išliko',
    'išliks',
    'išlikt',
    'išlikę',
    'išlipa',
    'išlipo',
    'išlips',
    'išlipt',
    'išlipę',
    'išlupa',
    'išlupi',
    'išlupu',
    'išlygų',
    'išlėkė',
    'išlūžo',
    'išmano',
    'išmanė',
    'išmesk',
    'išmest',
    'išmeta',
    'išmetė',
    'išmetę',
    'išmirė',
    'išmoko',
    'išmoks',
    'išmokt',
    'išmokė',
    'išmokę',
    'išmokų',
    'išmonę',
    'išmuša',
    'išmušk',
    'išmušt',
    'išmušė',
    'išmėžt',
    'išnaša',
    'išnešt',
    'išnešė',
    'išniro',
    'išnirs',
    'išnyko',
    'išnyks',
    'išnykę',
    'išnyra',
    'išnėrė',
    'išorėn',
    'išorės',
    'išpešė',
    'išpina',
    'išplak',
    'išpuvo',
    'išpylė',
    'išrado',
    'išrašo',
    'išrašo',
    'išrašė',
    'išrovė',
    'išrėžė',
    'išsakė',
    'išsami',
    'išsamų',
    'išseko',
    'išseks',
    'išsiųs',
    'išstos',
    'išsuko',
    'ištakų',
    'ištars',
    'ištart',
    'ištarė',
    'išteka',
    'išteku',
    'ištepa',
    'ištiko',
    'ištiks',
    'ištikt',
    'ištirt',
    'ištisa',
    'ištisi',
    'ištisu',
    'ištisą',
    'ištisų',
    'ištižę',
    'ištyrė',
    'ištyrę',
    'ištįso',
    'išugdė',
    'išvada',
    'išvadą',
    'išvadų',
    'išvalė',
    'išvarė',
    'išvedė',
    'išveši',
    'išveža',
    'išvežu',
    'išvežė',
    'išvežę',
    'išvijo',
    'išvirk',
    'išvirs',
    'išvirė',
    'išvogė',
    'išvydo',
    'išvydę',
    'išvyko',
    'išvyks',
    'išvykę',
    'išvysi',
    'išvyta',
    'išvyti',
    'išvytą',
    'išėjai',
    'išėjau',
    'išėjus',
    'išėjęs',
    'iššoka',
    'iššoko',
    'iššokę',
    'iššovė',
    'iššūkį',
    'išūžei',
    'išžudė',
    'jachta',
    'jankių',
    'japono',
    'japonų',
    'jaspio',
    'jaukas',
    'jaukių',
    'jaukus',
    'jaunam',
    'jaunas',
    'jaunos',
    'jaunus',
    'jausmo',
    'jausmu',
    'jausmą',
    'jausmų',
    'jausti',
    'jaustų',
    'jautei',
    'jautri',
    'jaučia',
    'jaučio',
    'jaučiu',
    'jaučių',
    'javams',
    'jodavę',
    'jogais',
    'jojike',
    'jojimo',
    'jokiam',
    'jokias',
    'jokios',
    'jokius',
    'josite',
    'jovalu',
    'jovalą',
    'judama',
    'judesį',
    'judimo',
    'judino',
    'judinu',
    'judrūs',
    'judėjo',
    'judėjų',
    'judėti',
    'judėtų',
    'jungia',
    'jungti',
    'jungtį',
    'junkis',
    'juodam',
    'juodas',
    'juodom',
    'juodos',
    'juodus',
    'juokai',
    'juokas',
    'juokus',
    'juokės',
    'juosta',
    'juostą',
    'juostų',
    'juslių',
    'jutimo',
    'jutimu',
    'jutimų',
    'jėgoms',
    'jūroje',
    'kabalo',
    'kabina',
    'kabiną',
    'kabojo',
    'kadrai',
    'kadrus',
    'kailio',
    'kailis',
    'kailiu',
    'kailių',
    'kaimai',
    'kaimas',
    'kaimui',
    'kaimus',
    'kainai',
    'kainas',
    'kainos',
    'kairiu',
    'kairys',
    'kairėj',
    'kairės',
    'kairės',
    'kaista',
    'kaitos',
    'kaitra',
    'kaitri',
    'kaičia',
    'kajutę',
    'kaklas',
    'kaklus',
    'kaktas',
    'kakton',
    'kaktos',
    'kaladę',
    'kalama',
    'kalasi',
    'kalbai',
    'kalbam',
    'kalbas',
    'kalbat',
    'kalboj',
    'kalbos',
    'kalbėk',
    'kalbės',
    'kalbėt',
    'kalcio',
    'kalcis',
    'kalijų',
    'kalinį',
    'kalkių',
    'kalnai',
    'kalnas',
    'kalnus',
    'kaltas',
    'kaltes',
    'kaltos',
    'kaltus',
    'kaltės',
    'kalvas',
    'kalvio',
    'kalvių',
    'kalvon',
    'kalvos',
    'kalėjo',
    'kalėsi',
    'kalėti',
    'kamanų',
    'kamarų',
    'kamera',
    'kamerą',
    'kaminu',
    'kaminą',
    'kampai',
    'kampas',
    'kampui',
    'kampus',
    'kamštį',
    'kamžas',
    'kanale',
    'kanalo',
    'kanalu',
    'kanalą',
    'kanalų',
    'kanojų',
    'kanone',
    'kanono',
    'kanopų',
    'kančia',
    'kančių',
    'kaplių',
    'kaplys',
    'kapoja',
    'kaponę',
    'kapoti',
    'kapoto',
    'kapotą',
    'kapšas',
    'karatė',
    'karatų',
    'kardai',
    'kardas',
    'kariai',
    'karkia',
    'karklo',
    'karklų',
    'karmai',
    'karmos',
    'karosą',
    'karste',
    'karsto',
    'karstą',
    'karstų',
    'kartai',
    'kartas',
    'kartis',
    'kartok',
    'kartos',
    'kartui',
    'kartus',
    'kartūs',
    'karvei',
    'karves',
    'karvių',
    'karvės',
    'karčią',
    'karčių',
    'karšta',
    'karšto',
    'karštu',
    'karštį',
    'karštų',
    'karūna',
    'kasama',
    'kasant',
    'kasetė',
    'kasimo',
    'kasose',
    'kasosi',
    'katino',
    'katinų',
    'katėms',
    'kaukas',
    'kaukia',
    'kaukių',
    'kaukėm',
    'kaukės',
    'kaulai',
    'kaulan',
    'kaulas',
    'kaului',
    'kaulus',
    'kaupia',
    'kaupti',
    'kauptų',
    'kaupus',
    'kausto',
    'kavinę',
    'kavutę',
    'kazokų',
    'kazusų',
    'kaštai',
    'kaštus',
    'kažkam',
    'kažkas',
    'kažkuo',
    'keblią',
    'kedais',
    'kefyrą',
    'keikia',
    'keisis',
    'keista',
    'keisti',
    'keisti',
    'keistų',
    'keičia',
    'keičiu',
    'kekėse',
    'keleri',
    'keleto',
    'keletu',
    'keletą',
    'keliai',
    'kelias',
    'kelias',
    'keliom',
    'kelios',
    'keliui',
    'kelius',
    'kelkis',
    'kelmai',
    'kelmas',
    'kelmus',
    'kelnes',
    'kelnių',
    'kelnėm',
    'kelnės',
    'kelsis',
    'keltas',
    'keltis',
    'kelutį',
    'kelyje',
    'kenkia',
    'kenkti',
    'kenčia',
    'kenčiu',
    'kepalą',
    'kepalų',
    'kepant',
    'kepenų',
    'kepimo',
    'kepina',
    'kepsnį',
    'keptas',
    'keptos',
    'kepure',
    'kepurė',
    'kepurę',
    'kerams',
    'keroja',
    'kertėj',
    'kertėn',
    'kertės',
    'keršto',
    'kerštu',
    'keršys',
    'ketaus',
    'ketina',
    'ketini',
    'ketino',
    'ketinu',
    'keturi',
    'kevalo',
    'kevalu',
    'kečujų',
    'kiaulė',
    'kiaulę',
    'kiaunė',
    'kiaunę',
    'kiaurą',
    'kiaurų',
    'kiaute',
    'kiautą',
    'kibire',
    'kibiro',
    'kibiru',
    'kibirą',
    'kibirų',
    'kibkit',
    'kibucų',
    'kiekio',
    'kiekis',
    'kiekiu',
    'kielių',
    'kiemai',
    'kiemas',
    'kiemus',
    'kietas',
    'kietos',
    'kiečių',
    'kikena',
    'kilimo',
    'kilimą',
    'kilkės',
    'kilmės',
    'kiloja',
    'kilpos',
    'kilusi',
    'kilusį',
    'kimius',
    'kimšos',
    'kioske',
    'kiosko',
    'kipšas',
    'kirmis',
    'kirpta',
    'kirpti',
    'kirsta',
    'kirsti',
    'kirtis',
    'kirvio',
    'kirvis',
    'kirviu',
    'kirvių',
    'kirčio',
    'kitais',
    'kitame',
    'kitiem',
    'kitimo',
    'kitimu',
    'kitimą',
    'kitkas',
    'kitkuo',
    'kitoje',
    'kitoks',
    'kitokį',
    'kitoms',
    'kitose',
    'kitybę',
    'kišasi',
    'kišenė',
    'kišenę',
    'kiškio',
    'kiškis',
    'kiškių',
    'kištis',
    'klaida',
    'klaidą',
    'klaidų',
    'klaiku',
    'klajos',
    'klampi',
    'klasei',
    'klases',
    'klasių',
    'klasta',
    'klasėj',
    'klasės',
    'klausi',
    'klausk',
    'klauso',
    'klaust',
    'klausė',
    'klerkų',
    'klevai',
    'klevas',
    'klijai',
    'kliudė',
    'kliuvo',
    'klizmą',
    'klišių',
    'kliūtį',
    'kliūtų',
    'kliūva',
    'kloakų',
    'klodai',
    'klombe',
    'klombo',
    'kloniu',
    'klubai',
    'klubas',
    'klubui',
    'klumpu',
    'kluono',
    'kluoną',
    'klusni',
    'klusnų',
    'klykti',
    'klysta',
    'klysti',
    'klūpos',
    'kmynai',
    'knieti',
    'knygas',
    'knygoj',
    'knygos',
    'kodais',
    'kojele',
    'kojose',
    'kokiai',
    'kokiam',
    'kokias',
    'kokioj',
    'kokiom',
    'kokios',
    'kokius',
    'koklių',
    'koksai',
    'koktūs',
    'kokybe',
    'kokybė',
    'kokybę',
    'kolege',
    'kolegė',
    'kolegę',
    'kolegų',
    'kolito',
    'kolona',
    'koloną',
    'kolūkį',
    'kometa',
    'kometų',
    'komiso',
    'komodą',
    'kompas',
    'kopija',
    'kopiją',
    'kopijų',
    'kopimą',
    'kopėme',
    'koralų',
    'kortas',
    'kortos',
    'kotelį',
    'kotukų',
    'kovinė',
    'kovinį',
    'kovoja',
    'kovoje',
    'kovojo',
    'kovoję',
    'kovoti',
    'kovėsi',
    'kozirį',
    'kožnam',
    'krabas',
    'krachą',
    'kraige',
    'kraiko',
    'kraipo',
    'kraipė',
    'kraitį',
    'kramtė',
    'kranai',
    'krante',
    'kranto',
    'krantu',
    'krantą',
    'krantų',
    'kratau',
    'kratos',
    'kratyk',
    'kraujo',
    'krauju',
    'kraują',
    'krauna',
    'krauti',
    'krašte',
    'krašto',
    'kraštu',
    'kraštą',
    'kraštų',
    'kreida',
    'kreiva',
    'kreivi',
    'kreivė',
    'kremai',
    'kremas',
    'kremus',
    'krenta',
    'krentu',
    'krepšį',
    'kresni',
    'krečia',
    'krinta',
    'krioki',
    'kristi',
    'kritus',
    'kritęs',
    'krivės',
    'krizei',
    'krizes',
    'krizių',
    'krizės',
    'kroatų',
    'kronus',
    'krosnį',
    'kruopų',
    'krušos',
    'kryptį',
    'kryžių',
    'krėsle',
    'krėslo',
    'krėslą',
    'krėsti',
    'krūmai',
    'krūmas',
    'krūmus',
    'krūtis',
    'krūvas',
    'krūvio',
    'krūvis',
    'krūvių',
    'krūvon',
    'krūvos',
    'kubilą',
    'kuilių',
    'kukavo',
    'kuklią',
    'kuklus',
    'kuklūs',
    'kulbės',
    'kulkom',
    'kulkos',
    'kulnas',
    'kulnus',
    'kulono',
    'kulsim',
    'kultai',
    'kultas',
    'kultis',
    'kultui',
    'kultus',
    'kumelė',
    'kumelę',
    'kumpis',
    'kumpiu',
    'kumpių',
    'kumštį',
    'kunige',
    'kunigo',
    'kunigu',
    'kunigą',
    'kunigė',
    'kunigų',
    'kuokos',
    'kuparu',
    'kupetų',
    'kupina',
    'kupini',
    'kuplus',
    'kupros',
    'kupsto',
    'kuriai',
    'kuriam',
    'kurias',
    'kuriem',
    'kurioj',
    'kuriom',
    'kurion',
    'kurios',
    'kuriuo',
    'kurmio',
    'kursai',
    'kursas',
    'kursto',
    'kurstė',
    'kursui',
    'kursus',
    'kurtis',
    'kurumų',
    'kurėnų',
    'kuršių',
    'kuteno',
    'kutota',
    'kušetė',
    'kvaila',
    'kvaile',
    'kvaili',
    'kvailą',
    'kvapas',
    'kvapus',
    'kvarco',
    'kvepia',
    'kvietė',
    'kvitus',
    'kvortą',
    'kvotos',
    'kvykia',
    'kybojo',
    'kylama',
    'kylant',
    'kyšojo',
    'kąskit',
    'kąsnis',
    'kąsniu',
    'kėbulo',
    'kėbulų',
    'kėdėje',
    'kėdėse',
    'kėdžių',
    'kėlimo',
    'kėlimu',
    'kėlimą',
    'kėlusį',
    'kėlėsi',
    'kėslai',
    'kėslus',
    'kūdikį',
    'kūdros',
    'kūginė',
    'kūginę',
    'kūlimo',
    'kūnais',
    'kūnams',
    'kūnelį',
    'kūrenu',
    'kūriku',
    'kūrime',
    'kūrimo',
    'kūrimu',
    'kūrimą',
    'kūrinį',
    'kūryba',
    'kūrybą',
    'kūrėja',
    'kūrėjo',
    'kūrėju',
    'kūrėją',
    'kūrėjų',
    'kūrėsi',
    'labumo',
    'ladinų',
    'lagery',
    'laibos',
    'laidai',
    'laidas',
    'laidos',
    'laidus',
    'laikai',
    'laikas',
    'laikau',
    'laikei',
    'laikos',
    'laikot',
    'laikui',
    'laikus',
    'laikyk',
    'laikys',
    'laikyt',
    'laikęs',
    'laimei',
    'laimės',
    'laimės',
    'laiptų',
    'laisto',
    'laistė',
    'laisva',
    'laisve',
    'laisvi',
    'laisvo',
    'laisvu',
    'laisvą',
    'laisvė',
    'laisvę',
    'laisvų',
    'laivai',
    'laivas',
    'laivus',
    'laiške',
    'laiško',
    'laišką',
    'laiškų',
    'laksto',
    'lakstė',
    'lakšte',
    'lakūno',
    'lakūną',
    'langai',
    'langas',
    'langus',
    'lankai',
    'lankas',
    'lankau',
    'lankys',
    'lankyt',
    'lapais',
    'lapams',
    'lapelį',
    'lapija',
    'lapoja',
    'latakų',
    'latras',
    'latvio',
    'latvis',
    'latvių',
    'laukai',
    'laukan',
    'laukas',
    'laukei',
    'laukia',
    'laukit',
    'laukiu',
    'laukių',
    'lauksi',
    'laukti',
    'lauktų',
    'laukui',
    'laukus',
    'laukėm',
    'laukęs',
    'laurai',
    'laužas',
    'laužia',
    'laužti',
    'laužui',
    'lavina',
    'laviną',
    'lavinų',
    'lavonu',
    'lavoną',
    'lavonų',
    'lavėja',
    'lavėti',
    'lazdas',
    'lazdos',
    'lašais',
    'ledais',
    'ledams',
    'ledine',
    'ledinė',
    'ledinį',
    'legalu',
    'leidom',
    'leidus',
    'leidęs',
    'leisiu',
    'leista',
    'leisti',
    'leistų',
    'lelija',
    'lelijų',
    'lempom',
    'lempos',
    'lemtis',
    'lendam',
    'lengva',
    'lengvi',
    'lengvo',
    'lengvu',
    'lengvą',
    'lenkai',
    'lenkas',
    'lenkia',
    'lenkiu',
    'lenkti',
    'lenkus',
    'lentas',
    'lentos',
    'lesalu',
    'lesalą',
    'lesalų',
    'lesina',
    'letena',
    'liaudį',
    'liaukų',
    'liejas',
    'liejau',
    'liejom',
    'liekam',
    'liekna',
    'liemuo',
    'liepia',
    'liepos',
    'liepta',
    'liepti',
    'liepto',
    'lieptą',
    'liepęs',
    'liesas',
    'liesis',
    'liesos',
    'liesti',
    'lietis',
    'lietui',
    'lietum',
    'lietus',
    'lietūs',
    'liečia',
    'ligoje',
    'ligoms',
    'ligonė',
    'ligonį',
    'ligoti',
    'likimo',
    'likimu',
    'likimą',
    'likimų',
    'likome',
    'liksim',
    'liksiu',
    'likusi',
    'likusį',
    'limitą',
    'lingių',
    'linija',
    'liniją',
    'linijų',
    'lininį',
    'linkiu',
    'linkme',
    'linksi',
    'linkus',
    'linkęs',
    'lipdyk',
    'lipidų',
    'lipina',
    'lipnia',
    'lipnus',
    'litais',
    'litams',
    'litrai',
    'litras',
    'litrui',
    'litrus',
    'litukų',
    'liudys',
    'lizdai',
    'lizdas',
    'lizdui',
    'lizdus',
    'liūdna',
    'liūdni',
    'liūdną',
    'liūdnų',
    'liūsti',
    'liūtai',
    'liūtis',
    'liūtys',
    'liūčių',
    'lobiai',
    'logika',
    'logiką',
    'loginė',
    'loginį',
    'logoso',
    'logosą',
    'lokiui',
    'lopais',
    'lopšio',
    'lopšys',
    'lordai',
    'lordas',
    'lotynų',
    'lovoje',
    'lovyje',
    'lovytė',
    'lošimo',
    'lubose',
    'luisto',
    'luistų',
    'luitas',
    'lukšto',
    'lukštą',
    'luomas',
    'luotas',
    'luošio',
    'luošus',
    'luošys',
    'lupami',
    'lupasi',
    'lupate',
    'lupeną',
    'lupenų',
    'lupkis',
    'lydavo',
    'lydeka',
    'lydekų',
    'lyderį',
    'lydimi',
    'lydymų',
    'lydytu',
    'lydėjo',
    'lydėti',
    'lygaus',
    'lygiai',
    'lygias',
    'lygina',
    'lygint',
    'lygios',
    'lygiui',
    'lygius',
    'lygmuo',
    'lygoje',
    'lyguma',
    'lygumų',
    'lygybe',
    'lygybė',
    'lygybę',
    'lygyje',
    'lyjant',
    'lynais',
    'lyrikų',
    'lyrinė',
    'lysvės',
    'lyties',
    'lytine',
    'lytinį',
    'lytėjo',
    'lyviai',
    'lėkštę',
    'lėmėja',
    'lėtina',
    'lėtinė',
    'lėtinę',
    'lėtinį',
    'lūžiai',
    'lūžiui',
    'lūžusi',
    'lūžusį',
    'mafija',
    'magija',
    'magiją',
    'magmos',
    'maigai',
    'mainai',
    'mainus',
    'maisto',
    'maistu',
    'maistą',
    'maišai',
    'maišas',
    'maišto',
    'maištą',
    'maišus',
    'majoro',
    'maketo',
    'maketą',
    'malajų',
    'maldai',
    'maldas',
    'maldos',
    'malimo',
    'malkas',
    'malkos',
    'maloni',
    'malonu',
    'malonė',
    'malonę',
    'malonų',
    'maltus',
    'malūne',
    'malūno',
    'malūną',
    'malūnų',
    'mamele',
    'mamelė',
    'mamelę',
    'mamoms',
    'mamule',
    'mamutė',
    'mamyte',
    'mamytė',
    'manant',
    'maniau',
    'manija',
    'manimi',
    'manoma',
    'manome',
    'manosi',
    'manote',
    'mansių',
    'mantos',
    'mantra',
    'mantrą',
    'manyje',
    'manymu',
    'manyta',
    'manyti',
    'manytų',
    'manąjį',
    'manėme',
    'maorių',
    'margių',
    'margos',
    'marias',
    'marios',
    'markių',
    'markės',
    'marlės',
    'marčią',
    'maršas',
    'masažo',
    'masažą',
    'masinė',
    'masinį',
    'mastai',
    'mastas',
    'masyve',
    'masyvo',
    'masyvu',
    'masyvą',
    'masyvų',
    'masėms',
    'matais',
    'matant',
    'matavo',
    'matiko',
    'matiku',
    'matoma',
    'matome',
    'matomi',
    'matomą',
    'matosi',
    'matote',
    'matymo',
    'matysi',
    'matyti',
    'matyti',
    'matyti',
    'matytų',
    'matytų',
    'matėme',
    'matėsi',
    'mausti',
    'mazgai',
    'mazgas',
    'mazgus',
    'mačiau',
    'mačius',
    'mašalų',
    'mašina',
    'mašiną',
    'mašinų',
    'mažais',
    'mažame',
    'mažiau',
    'mažina',
    'mažini',
    'mažinu',
    'mažoje',
    'mažoji',
    'mažojo',
    'mažoka',
    'mažoms',
    'mažose',
    'mažumą',
    'mažumų',
    'mažute',
    'mažutė',
    'mažutę',
    'mažyli',
    'mažylė',
    'mažylę',
    'mažylį',
    'mažyti',
    'mažytė',
    'mažytį',
    'mažąją',
    'mažėja',
    'mažėjo',
    'mažėti',
    'mažųjų',
    'medalį',
    'medaus',
    'medija',
    'mediko',
    'medikų',
    'medinė',
    'medinę',
    'medinį',
    'medumi',
    'medyje',
    'medžio',
    'medžiu',
    'medžių',
    'megzti',
    'meilei',
    'meilių',
    'meilės',
    'melavo',
    'melisų',
    'melski',
    'melsti',
    'melsva',
    'melsvo',
    'melsvu',
    'melsvą',
    'melžia',
    'melžti',
    'menkai',
    'menkas',
    'menkos',
    'mentės',
    'menėje',
    'menėse',
    'mergai',
    'mergas',
    'mergom',
    'mergos',
    'merkia',
    'mesijo',
    'mesiją',
    'metais',
    'metale',
    'metalo',
    'metalą',
    'metalų',
    'metama',
    'metams',
    'metimu',
    'metimą',
    'metinė',
    'metinį',
    'metode',
    'metodo',
    'metodu',
    'metodą',
    'metodų',
    'metrai',
    'metrus',
    'metukų',
    'metusį',
    'metėsi',
    'mečiau',
    'meškas',
    'meškos',
    'midaus',
    'miegas',
    'miegos',
    'miegot',
    'miegus',
    'mielai',
    'mielas',
    'mieles',
    'mielių',
    'mielos',
    'mielus',
    'mielės',
    'mieste',
    'miesto',
    'miestu',
    'miestą',
    'miestų',
    'miežių',
    'miglas',
    'miglos',
    'miklus',
    'miltai',
    'miltus',
    'mimika',
    'minant',
    'miniai',
    'minima',
    'minimi',
    'minimų',
    'minioj',
    'minios',
    'minkai',
    'mintim',
    'mintis',
    'mintis',
    'mintys',
    'minusą',
    'minusų',
    'minute',
    'minutė',
    'minutę',
    'minčių',
    'minėjo',
    'minėję',
    'minėsi',
    'minėta',
    'minėti',
    'minėti',
    'minėtu',
    'minėtų',
    'mirksi',
    'mirsiu',
    'mirtim',
    'mirtis',
    'mirtys',
    'mirusi',
    'mirusį',
    'mirčių',
    'miršta',
    'miršti',
    'misija',
    'misiją',
    'misijų',
    'mityba',
    'mitybą',
    'mišias',
    'mišinį',
    'mišios',
    'miškai',
    'miškan',
    'miškas',
    'miškus',
    'mišrus',
    'mišrūs',
    'modelį',
    'modulį',
    'mokama',
    'mokami',
    'mokamo',
    'mokant',
    'mokaus',
    'mokino',
    'mokinė',
    'mokinį',
    'mokoma',
    'mokomi',
    'mokosi',
    'moksle',
    'mokslo',
    'mokslu',
    'mokslą',
    'mokslų',
    'mokymo',
    'mokymu',
    'mokymą',
    'mokymų',
    'mokyti',
    'mokyti',
    'mokėjo',
    'mokėsi',
    'mokėti',
    'mokėtų',
    'moliai',
    'molinę',
    'moneta',
    'monetą',
    'monetų',
    'moralė',
    'moralę',
    'morkas',
    'morkos',
    'mostai',
    'moters',
    'moterį',
    'moterų',
    'motina',
    'motiną',
    'motinų',
    'motoro',
    'motulė',
    'motyvo',
    'motyvu',
    'motyvą',
    'motyvų',
    'movėsi',
    'mozūro',
    'mozūrą',
    'mozūrų',
    'mugėse',
    'muilai',
    'muilas',
    'muitai',
    'mulkis',
    'mumija',
    'mumiją',
    'mumyse',
    'murkdė',
    'muzika',
    'muziku',
    'muziką',
    'muzikų',
    'mušimo',
    'mušimą',
    'muškit',
    'muštas',
    'muštis',
    'mylimo',
    'mylimu',
    'mylimą',
    'mylisi',
    'mylėjo',
    'mylėję',
    'mylėti',
    'mąstai',
    'mąstau',
    'mąstyt',
    'mėgina',
    'mėgink',
    'mėgino',
    'mėginu',
    'mėginį',
    'mėgsta',
    'mėgsti',
    'mėgstu',
    'mėgėją',
    'mėgėjų',
    'mėlyna',
    'mėlyni',
    'mėlyną',
    'mėlynė',
    'mėlynę',
    'mėlynų',
    'mėnesį',
    'mėsoje',
    'mėsytė',
    'mėsytę',
    'mėtomi',
    'mėtosi',
    'mėtyti',
    'mėšlas',
    'mėšlui',
    'mėžiko',
    'mįslės',
    'mūrijo',
    'mūrysi',
    'mūryti',
    'mūšius',
    'mūšyje',
    'nacija',
    'naftos',
    'nagais',
    'naivių',
    'naivus',
    'naivūs',
    'naktis',
    'nakvos',
    'namais',
    'namams',
    'namelį',
    'naminį',
    'namuos',
    'nariai',
    'nariui',
    'narius',
    'narsūs',
    'narvus',
    'nasrus',
    'natelė',
    'natrio',
    'natūra',
    'naudai',
    'naudos',
    'naujai',
    'naujam',
    'naujas',
    'naujoj',
    'naujos',
    'naujus',
    'navahų',
    'navikų',
    'našius',
    'našliu',
    'našlių',
    'našlės',
    'našumo',
    'našumu',
    'našumą',
    'naščių',
    'neardo',
    'neauga',
    'nebado',
    'nebark',
    'nebarė',
    'nebijo',
    'nebuvo',
    'nebuvę',
    'nebėgo',
    'nebėgs',
    'nebėra',
    'nebūna',
    'nebūni',
    'nebūsi',
    'nebūta',
    'nebūti',
    'nebūtį',
    'nebūtų',
    'nedaro',
    'nedarė',
    'nedavė',
    'nededa',
    'nededu',
    'nedega',
    'nedegs',
    'nedegė',
    'nedera',
    'nedora',
    'nedorų',
    'nedrįs',
    'neduok',
    'nedyla',
    'neengė',
    'negali',
    'negaus',
    'negavo',
    'negavę',
    'negera',
    'negeri',
    'negero',
    'negerą',
    'negilu',
    'negimė',
    'negins',
    'negrai',
    'negrok',
    'negrus',
    'negyva',
    'negyvi',
    'negyvu',
    'negyvą',
    'negyvų',
    'neigia',
    'neikit',
    'neilgą',
    'neilgų',
    'neinam',
    'neisim',
    'neisiu',
    'nejuda',
    'nejudu',
    'nejunk',
    'nejutę',
    'nekels',
    'nekilo',
    'nekils',
    'nekito',
    'nekišk',
    'nekoks',
    'nekris',
    'nekyla',
    'nekėlė',
    'nelauk',
    'neleis',
    'nelies',
    'neliko',
    'neliks',
    'nelikę',
    'nelipk',
    'nemano',
    'nemanė',
    'nemato',
    'nematė',
    'nemaža',
    'nemaži',
    'nemažą',
    'nemažų',
    'nemetė',
    'nemiga',
    'nemirs',
    'nemirė',
    'nemoka',
    'nemoki',
    'nemoku',
    'nemokė',
    'nemyli',
    'nemėgo',
    'nendrė',
    'nenori',
    'nenoru',
    'nenorą',
    'nenyko',
    'neperi',
    'nepešė',
    'nepuls',
    'nepūtų',
    'nerado',
    'neramu',
    'neramų',
    'nerasi',
    'nereik',
    'nerimo',
    'nerimo',
    'nerimu',
    'nerimą',
    'nerodo',
    'nervai',
    'nervus',
    'neršia',
    'nerūpi',
    'nesako',
    'nesakė',
    'nesama',
    'nesame',
    'nesant',
    'nesavi',
    'nesavu',
    'neseka',
    'neseną',
    'nesenų',
    'neskęs',
    'nespės',
    'nesuka',
    'nesėdi',
    'netapo',
    'neteko',
    'neteks',
    'netekę',
    'netiki',
    'netiko',
    'netikę',
    'neturi',
    'neturį',
    'netyla',
    'neugdo',
    'nevalą',
    'nevaro',
    'nevarė',
    'neveda',
    'nevedė',
    'neverk',
    'nevyko',
    'nevykę',
    'nevėlu',
    'nečeža',
    'neįdės',
    'nešant',
    'nešasi',
    'nešate',
    'nešaus',
    'nešimu',
    'nešina',
    'nešini',
    'neškim',
    'neškis',
    'neškit',
    'nešmuo',
    'nešoko',
    'nešuos',
    'nešusi',
    'nešėją',
    'nešėsi',
    'nežada',
    'nežavi',
    'neženk',
    'nežino',
    'nežuvo',
    'nežymų',
    'niekai',
    'niekam',
    'niekas',
    'niekis',
    'niekuo',
    'niekus',
    'niežti',
    'niūrus',
    'niūrūs',
    'nokina',
    'noragų',
    'norais',
    'norams',
    'norima',
    'norime',
    'norimi',
    'norimu',
    'norimą',
    'norimų',
    'norint',
    'norisi',
    'norite',
    'normai',
    'normas',
    'normos',
    'norėjo',
    'norėję',
    'norėsi',
    'norėta',
    'norėti',
    'norėtų',
    'nosiai',
    'nosies',
    'nosimi',
    'nosine',
    'nosinė',
    'nosinę',
    'novelė',
    'novelę',
    'nuaugo',
    'nubyra',
    'nubėga',
    'nubėgo',
    'nubėgu',
    'nudažė',
    'nudega',
    'nudegė',
    'nudobė',
    'nudvės',
    'nudūrė',
    'nueina',
    'nueini',
    'nueinu',
    'nueisi',
    'nueita',
    'nueiti',
    'nueito',
    'nueitų',
    'nugaiš',
    'nugali',
    'nugara',
    'nugarą',
    'nugarų',
    'nugirs',
    'nugulė',
    'nuimti',
    'nujaus',
    'nujoja',
    'nujojo',
    'nujoję',
    'nujoti',
    'nukasa',
    'nukasę',
    'nukovė',
    'nukris',
    'nukėlė',
    'nukūrė',
    'nuline',
    'nulinė',
    'nulipa',
    'nulipo',
    'nuliui',
    'nulėkė',
    'nulėmė',
    'nulūžo',
    'numato',
    'numatė',
    'numerį',
    'numeta',
    'numetė',
    'numirs',
    'numirė',
    'numoja',
    'numoju',
    'numovė',
    'numykė',
    'nuneša',
    'nuneši',
    'nunešk',
    'nunešt',
    'nunešė',
    'nuniro',
    'nuodai',
    'nuodus',
    'nuogas',
    'nuogos',
    'nuomai',
    'nuomos',
    'nupieš',
    'nupina',
    'nupirk',
    'nuraus',
    'nurijo',
    'nurimo',
    'nurodo',
    'nurodė',
    'nurėžė',
    'nusako',
    'nusakė',
    'nusega',
    'nusekė',
    'nusenę',
    'nusuka',
    'nusėda',
    'nutapė',
    'nutarė',
    'nutarę',
    'nuteis',
    'nutiko',
    'nutiks',
    'nutilk',
    'nutilo',
    'nutilt',
    'nutolo',
    'nutolę',
    'nutukę',
    'nutyla',
    'nutęsė',
    'nutūpė',
    'nuvalė',
    'nuvalę',
    'nuveda',
    'nuvedė',
    'nuveja',
    'nuvežė',
    'nuvyko',
    'nuvylė',
    'nuvytu',
    'nuvėrė',
    'nuėjau',
    'nuėjom',
    'nuėjot',
    'nuėmus',
    'nušauk',
    'nušaus',
    'nušaut',
    'nušoko',
    'nušovė',
    'nužudo',
    'nužudė',
    'nužudę',
    'nykias',
    'nykimo',
    'nykimu',
    'nykimą',
    'nyksta',
    'nykusi',
    'nėriau',
    'nėščia',
    'nėščių',
    'obelis',
    'obelys',
    'obuolį',
    'odinio',
    'odiniu',
    'ogonių',
    'okeaną',
    'oktava',
    'olandė',
    'olimpo',
    'operos',
    'opinei',
    'opinio',
    'optinė',
    'optinį',
    'orbita',
    'orbitą',
    'orbitų',
    'orderį',
    'ordino',
    'ordinų',
    'organo',
    'organu',
    'organą',
    'organų',
    'orgijų',
    'orumas',
    'orėmis',
    'ostiją',
    'ovalas',
    'ošesiu',
    'ošesys',
    'ožinis',
    'ožiuką',
    'ožkoms',
    'ožkytę',
    'paaugt',
    'paaugę',
    'pabiro',
    'pabirų',
    'pabodo',
    'pabudo',
    'pabėga',
    'pabėgo',
    'pabėgt',
    'pabūnu',
    'pabūti',
    'padala',
    'padare',
    'padaro',
    'padaro',
    'padarą',
    'padarė',
    'padarę',
    'padavė',
    'padažų',
    'padeda',
    'padedi',
    'padedu',
    'padegt',
    'padegė',
    'padori',
    'paduok',
    'paduos',
    'padėjo',
    'padėję',
    'padėka',
    'padėką',
    'padėsi',
    'padėti',
    'padėtį',
    'padėtų',
    'paeina',
    'paeisi',
    'pagalį',
    'pagano',
    'pagauk',
    'pagavo',
    'pagyti',
    'paikas',
    'pailgo',
    'pailgu',
    'pailgą',
    'pailsi',
    'pailso',
    'paimam',
    'paimsi',
    'paimta',
    'paimti',
    'paimti',
    'paimtų',
    'painią',
    'painus',
    'paišei',
    'paišęs',
    'pajamų',
    'pajaus',
    'pajuda',
    'pajuto',
    'pajutę',
    'pajėgi',
    'pajėgs',
    'pajėgė',
    'pajėgų',
    'pajūrį',
    'pakako',
    'pakakę',
    'pakeis',
    'pakels',
    'pakelt',
    'pakely',
    'pakelį',
    'paketo',
    'paketu',
    'paketą',
    'paketų',
    'pakibo',
    'pakili',
    'pakilo',
    'pakils',
    'pakilę',
    'pakito',
    'pakitę',
    'pakiša',
    'pakišo',
    'pakišu',
    'pakopa',
    'pakopą',
    'pakopų',
    'pakorė',
    'pakyla',
    'pakyla',
    'pakylu',
    'pakėlė',
    'palata',
    'palatą',
    'palatų',
    'palauk',
    'paleis',
    'palesę',
    'palies',
    'paliko',
    'paliks',
    'palikt',
    'palikę',
    'palipo',
    'paluby',
    'palydi',
    'palyti',
    'palėpę',
    'pamanė',
    'pamato',
    'pamato',
    'pamatu',
    'pamatą',
    'pamatė',
    'pamatę',
    'pamatų',
    'pamaus',
    'pamena',
    'pameni',
    'pamenu',
    'pametė',
    'pametę',
    'pamilo',
    'pamina',
    'pamini',
    'pamirš',
    'pamoka',
    'pamoką',
    'pamokę',
    'pamokų',
    'panaši',
    'panašu',
    'panašų',
    'panele',
    'panelė',
    'panelę',
    'panika',
    'paniką',
    'panikė',
    'pantis',
    'pančiu',
    'pančių',
    'panėrė',
    'papkes',
    'papkių',
    'papuls',
    'papėdę',
    'papūga',
    'papūgą',
    'papūsi',
    'papūtė',
    'paraką',
    'parama',
    'paramą',
    'parašo',
    'parašo',
    'parašą',
    'parašė',
    'parašų',
    'pareis',
    'pareit',
    'paremt',
    'pariši',
    'parkas',
    'paroda',
    'parodo',
    'parodą',
    'parodė',
    'parodų',
    'paroms',
    'parveš',
    'parėjo',
    'parėmė',
    'paršai',
    'paršas',
    'parūpo',
    'pasaga',
    'pasaka',
    'pasako',
    'pasaką',
    'pasakė',
    'pasakę',
    'pasakų',
    'paseka',
    'pasekė',
    'pasenę',
    'pasiųs',
    'paslėk',
    'pastos',
    'pasuka',
    'pasuko',
    'pasuks',
    'pasukt',
    'pasėdi',
    'pasėjo',
    'pasėta',
    'patale',
    'patalo',
    'patalą',
    'patalų',
    'patars',
    'patarė',
    'patarę',
    'pateko',
    'pateks',
    'patekę',
    'patelė',
    'patelę',
    'patepk',
    'pateps',
    'paties',
    'patiki',
    'patiko',
    'patiks',
    'patikt',
    'patino',
    'patinų',
    'patogi',
    'patogu',
    'patogų',
    'patosu',
    'patosą',
    'patsai',
    'patyrė',
    'patyrę',
    'patūno',
    'paupin',
    'pauzių',
    'pauzės',
    'pavara',
    'pavarą',
    'paveda',
    'pavedė',
    'paveža',
    'pavirs',
    'pavogs',
    'pavogė',
    'pavogę',
    'pavojų',
    'pavydi',
    'pavydi',
    'pavydo',
    'pavydu',
    'pavyko',
    'pavyks',
    'pavykę',
    'pačiai',
    'pačiam',
    'pačias',
    'pačioj',
    'pačios',
    'pačios',
    'pačius',
    'paėmei',
    'paėmęs',
    'pašaro',
    'pašaru',
    'pašarą',
    'pašarų',
    'pašoka',
    'pašoko',
    'pašovė',
    'paštas',
    'paštui',
    'paštus',
    'pašėrę',
    'paūšim',
    'pažado',
    'pažadu',
    'pažadu',
    'pažadą',
    'pažadų',
    'pažeis',
    'pažeme',
    'pažino',
    'pažint',
    'pažiro',
    'pažyma',
    'pažymi',
    'pažymą',
    'pažymį',
    'pažymų',
    'pažyra',
    'pažėrė',
    'peikia',
    'peikti',
    'peilis',
    'peiliu',
    'peilių',
    'pelene',
    'pelenė',
    'pelenų',
    'pelkes',
    'pelkių',
    'pelkės',
    'pelnas',
    'pelynų',
    'pelėda',
    'pelėdų',
    'pelėms',
    'pelėsį',
    'pempės',
    'penimi',
    'penkis',
    'penkių',
    'penkta',
    'penkti',
    'penkto',
    'penktą',
    'penktų',
    'pensnė',
    'pereis',
    'perima',
    'perims',
    'perkam',
    'perkat',
    'perlai',
    'peruką',
    'perėjo',
    'perėmė',
    'perėti',
    'peršti',
    'peties',
    'petimi',
    'pečiai',
    'pečius',
    'peštis',
    'piemuo',
    'pienas',
    'pienių',
    'pienės',
    'pietus',
    'pietys',
    'pietūs',
    'pievas',
    'pievoj',
    'pievos',
    'piešia',
    'piešiu',
    'piešti',
    'piešti',
    'pigaus',
    'pigiam',
    'pigios',
    'pigius',
    'pigųjį',
    'pikete',
    'piketo',
    'piktai',
    'piktam',
    'piktas',
    'piktus',
    'pilant',
    'pildyt',
    'pilies',
    'pilkas',
    'pilkis',
    'pilkos',
    'pilkus',
    'pilnas',
    'pilnom',
    'pilnos',
    'pilnus',
    'pilnut',
    'pilsim',
    'pilsto',
    'pilstė',
    'piltis',
    'pilvas',
    'pilvui',
    'pilyje',
    'pilyse',
    'pinigo',
    'pinigą',
    'pinigų',
    'pintas',
    'pintis',
    'pipiro',
    'pipirų',
    'piratu',
    'pirkai',
    'pirkau',
    'pirkių',
    'pirkom',
    'pirkti',
    'pirkti',
    'pirktą',
    'pirktų',
    'pirmai',
    'pirmam',
    'pirmas',
    'pirmoj',
    'pirmos',
    'pirmus',
    'pirtis',
    'piršti',
    'piršto',
    'pirštu',
    'pirštą',
    'pirštų',
    'pizidų',
    'pižama',
    'pjauni',
    'pjauti',
    'pjesei',
    'pjesių',
    'pjesės',
    'pjovęs',
    'pjūklu',
    'pjūklą',
    'pjūvio',
    'pjūvis',
    'plakti',
    'plakti',
    'planai',
    'planas',
    'planui',
    'planus',
    'platus',
    'platūs',
    'plauko',
    'plauko',
    'plauks',
    'plauką',
    'plaukė',
    'plaukų',
    'plauni',
    'plauti',
    'plazda',
    'plazma',
    'plačia',
    'plačiu',
    'plačią',
    'plačių',
    'pledas',
    'pledus',
    'plento',
    'plentu',
    'plečia',
    'pliaže',
    'pliekė',
    'plieno',
    'plikis',
    'plikos',
    'plikės',
    'plinka',
    'plinta',
    'plisti',
    'pliusų',
    'plomba',
    'plombą',
    'plombų',
    'plonas',
    'plonos',
    'plotai',
    'plotas',
    'plotis',
    'plotmę',
    'plotui',
    'plotus',
    'pločio',
    'plukės',
    'plutai',
    'plutos',
    'plynių',
    'plynus',
    'plytas',
    'plytos',
    'plyšių',
    'plyšta',
    'plyšti',
    'plyšys',
    'plėsis',
    'plėsti',
    'plėstų',
    'plėtra',
    'plėtrą',
    'plėšia',
    'plūgus',
    'plūsta',
    'plūsti',
    'pobūdį',
    'poelgį',
    'poemai',
    'poemos',
    'poetai',
    'poetas',
    'poetus',
    'poetės',
    'poilsį',
    'pojūtį',
    'pokylį',
    'pokštų',
    'poliai',
    'polinė',
    'polkos',
    'pomėgį',
    'ponams',
    'ponios',
    'poodis',
    'porelė',
    'posakį',
    'posmus',
    'postai',
    'postus',
    'posėdį',
    'posūkį',
    'požymį',
    'pradas',
    'pradus',
    'pradės',
    'pradėt',
    'praeis',
    'praneš',
    'pranyk',
    'praras',
    'praryk',
    'prasme',
    'prasmė',
    'prasmę',
    'prasta',
    'prasti',
    'prasto',
    'prastą',
    'prastų',
    'praėjo',
    'praėję',
    'prašai',
    'prašau',
    'prašei',
    'prašyk',
    'prašyt',
    'prašęs',
    'praūžė',
    'pražus',
    'prekes',
    'prekių',
    'prekės',
    'presai',
    'pridėk',
    'priede',
    'priedo',
    'priedu',
    'priedą',
    'priedų',
    'prieis',
    'priekį',
    'priešo',
    'priešu',
    'priešą',
    'priešų',
    'priima',
    'priimi',
    'priims',
    'princą',
    'prionų',
    'prizai',
    'prizas',
    'prizmė',
    'prizmę',
    'priėjo',
    'priėmė',
    'progai',
    'progas',
    'progos',
    'protas',
    'protui',
    'protus',
    'prozos',
    'prūsai',
    'pudros',
    'puikia',
    'puikiu',
    'puikią',
    'puikių',
    'puikus',
    'puikūs',
    'pulkai',
    'pulkas',
    'pulkus',
    'pulsas',
    'pultas',
    'punkte',
    'punkto',
    'punktu',
    'punktą',
    'punktų',
    'puodai',
    'puodas',
    'puodus',
    'puolęs',
    'puotoj',
    'puotos',
    'puošia',
    'pupyte',
    'pupytė',
    'purena',
    'purkšt',
    'purslų',
    'purtau',
    'purvas',
    'pusnys',
    'pusėje',
    'pusėms',
    'pusėse',
    'putiną',
    'putinų',
    'putlūs',
    'putnūs',
    'putoja',
    'putojo',
    'putoti',
    'puvenų',
    'pušies',
    'pušyne',
    'pušynų',
    'pykina',
    'pykosi',
    'pyksta',
    'pyksti',
    'pykstu',
    'pyktis',
    'pyktis',
    'pykčio',
    'pyliau',
    'pylimo',
    'pylimą',
    'pylimų',
    'pylėsi',
    'pynėsi',
    'pypkių',
    'pypkės',
    'pyrago',
    'pyragu',
    'pyragą',
    'pėdina',
    'pėdose',
    'pėsčia',
    'pūdinį',
    'pūdymo',
    'pūkais',
    'pūkelį',
    'pūkinę',
    'pūslių',
    'pūslės',
    'pūtiko',
    'pūtiką',
    'pūtikų',
    'pūtimu',
    'rabinų',
    'raciją',
    'radija',
    'radijo',
    'radiją',
    'radijų',
    'radome',
    'radosi',
    'radote',
    'radusi',
    'ragana',
    'raganų',
    'ragelį',
    'ragina',
    'ragino',
    'raginu',
    'raguva',
    'raidai',
    'raidei',
    'raides',
    'raidos',
    'raidės',
    'rainis',
    'rainių',
    'raitas',
    'raiška',
    'raižau',
    'rajone',
    'rajono',
    'rajoną',
    'rajonų',
    'raketų',
    'rakina',
    'raktas',
    'raktus',
    'ramaus',
    'ramias',
    'ramina',
    'ramino',
    'raminu',
    'ramios',
    'rampos',
    'ramstį',
    'ramuma',
    'ramune',
    'ramunė',
    'ramutė',
    'ramybe',
    'ramybė',
    'ramybę',
    'randai',
    'randam',
    'randas',
    'randus',
    'rangas',
    'rangus',
    'rankas',
    'rankoj',
    'rankom',
    'rankon',
    'rankos',
    'rančos',
    'rapsai',
    'rasalo',
    'rasime',
    'rasite',
    'rastas',
    'rastis',
    'ratais',
    'ratuko',
    'ratuku',
    'ratukų',
    'raudok',
    'raudos',
    'raudės',
    'raugas',
    'raumuo',
    'rausti',
    'rausva',
    'rausvo',
    'rausvą',
    'rausvų',
    'rausės',
    'ravime',
    'ravėti',
    'razinų',
    'račius',
    'rašalo',
    'rašalu',
    'rašant',
    'rašiau',
    'rašinį',
    'rašius',
    'rašoma',
    'rašomi',
    'rašomų',
    'rašote',
    'raštai',
    'raštas',
    'raštui',
    'raštus',
    'rašyba',
    'rašybą',
    'rašymo',
    'rašymą',
    'rašysi',
    'rašyti',
    'rašytą',
    'rašytų',
    'rašėme',
    'realią',
    'realių',
    'realus',
    'regime',
    'regula',
    'regėjo',
    'regėję',
    'regėti',
    'reikia',
    'reikią',
    'reiktų',
    'reikės',
    'reisai',
    'reisas',
    'reisus',
    'reiškė',
    'remtis',
    'rengia',
    'rengta',
    'rengti',
    'rengęs',
    'replės',
    'retais',
    'retoms',
    'retėja',
    'retųjų',
    'režimo',
    'režimu',
    'režimą',
    'režimų',
    'ribinė',
    'ribinį',
    'riboja',
    'ribose',
    'ribota',
    'riboti',
    'riboto',
    'ribotą',
    'ridena',
    'ridinį',
    'riebiu',
    'riebių',
    'riebus',
    'riekės',
    'rietis',
    'rijimo',
    'rikiai',
    'rikiui',
    'riksmo',
    'riksmu',
    'riksmą',
    'rimsta',
    'rimtam',
    'rimtas',
    'rimtis',
    'rimtos',
    'ringių',
    'rinkai',
    'rinkas',
    'rinkos',
    'rinkta',
    'rinkti',
    'rinktų',
    'rinose',
    'riogso',
    'ritasi',
    'ritino',
    'ritmas',
    'ritmui',
    'ritosi',
    'rizika',
    'riziką',
    'rišame',
    'rišant',
    'rišlių',
    'riūkia',
    'roboto',
    'robotą',
    'robotų',
    'rodant',
    'rodoma',
    'rodomi',
    'rodomų',
    'rodosi',
    'rodyti',
    'rodytų',
    'rodėsi',
    'rojaus',
    'rojuje',
    'romane',
    'romano',
    'romaną',
    'romanų',
    'romėnu',
    'romėną',
    'romėnų',
    'ropinė',
    'rotušė',
    'rovėsi',
    'rubino',
    'rublio',
    'rublių',
    'rudais',
    'rudens',
    'rudenį',
    'rudųjų',
    'ruimas',
    'ruonio',
    'ruonis',
    'ruošia',
    'ruošos',
    'ruošti',
    'ruožai',
    'ruožas',
    'ruožus',
    'rupūže',
    'rupūžė',
    'rusams',
    'ruskių',
    'rutina',
    'rykštė',
    'rykštę',
    'rytais',
    'rytinė',
    'rytinį',
    'rytojų',
    'ryšiai',
    'ryšius',
    'ryškia',
    'ryškią',
    'ryškių',
    'ryškus',
    'ryškūs',
    'ryžiai',
    'ryžius',
    'ryžosi',
    'ryžtas',
    'ryžtis',
    'ryžtui',
    'rąstai',
    'rąstus',
    'rąžosi',
    'rėkiam',
    'rėmelį',
    'rėmimo',
    'rėmėjų',
    'rėmėsi',
    'rėžiau',
    'rėžimo',
    'rėžius',
    'rėžėsi',
    'rūbais',
    'rūdija',
    'rūdžių',
    'rūkymą',
    'rūkyti',
    'rūkytų',
    'rūmais',
    'rūpimu',
    'rūpėjo',
    'rūpėti',
    'rūpėtų',
    'rūsiai',
    'rūsius',
    'rūstus',
    'rūstūs',
    'rūšiai',
    'rūšies',
    'rūšimi',
    'rūšims',
    'sagose',
    'sagtim',
    'sagutę',
    'saitai',
    'saitus',
    'sakalo',
    'sakant',
    'sakiau',
    'sakiny',
    'sakinį',
    'sakmių',
    'sakmės',
    'sakoma',
    'sakome',
    'sakosi',
    'sakysi',
    'sakyta',
    'sakyti',
    'sakytų',
    'sakėsi',
    'saldus',
    'saldūs',
    'salelę',
    'saloje',
    'salone',
    'salono',
    'saloną',
    'salonų',
    'salose',
    'salvės',
    'salėje',
    'salėse',
    'samanų',
    'samtis',
    'sandus',
    'sapnai',
    'sapnas',
    'sargas',
    'sargus',
    'saugai',
    'saugią',
    'saugių',
    'saugok',
    'saugos',
    'saugos',
    'saugus',
    'saugūs',
    'saujom',
    'saulei',
    'saulės',
    'sausai',
    'sausas',
    'sausio',
    'sausos',
    'sausra',
    'sausrą',
    'sausrų',
    'savais',
    'savame',
    'savana',
    'savimi',
    'savitą',
    'savoje',
    'savojo',
    'savumo',
    'savybe',
    'savybė',
    'savybę',
    'savyje',
    'savąja',
    'savąją',
    'savąjį',
    'savųjų',
    'scenas',
    'scenoj',
    'scenos',
    'schema',
    'schemą',
    'schemų',
    'seanso',
    'seansą',
    'seansų',
    'seifas',
    'seilių',
    'seilės',
    'seimas',
    'seimui',
    'sekama',
    'sekami',
    'sekasi',
    'seklio',
    'seklys',
    'seksas',
    'seksis',
    'sektai',
    'sektis',
    'sektos',
    'sekėjo',
    'sekėjų',
    'sekėsi',
    'semsis',
    'senais',
    'sename',
    'senate',
    'senato',
    'senatą',
    'senele',
    'seneli',
    'senelė',
    'senelę',
    'senelį',
    'seniai',
    'senimo',
    'seniui',
    'senius',
    'senoje',
    'senoji',
    'senojo',
    'senolė',
    'senose',
    'senovė',
    'sensta',
    'senumo',
    'senumą',
    'senutė',
    'senutę',
    'senyva',
    'senyvo',
    'senąja',
    'senąją',
    'senąjį',
    'senųjų',
    'sepsio',
    'sepsis',
    'sepsiu',
    'serbai',
    'serbas',
    'serbus',
    'sererų',
    'serija',
    'seriją',
    'serume',
    'seselė',
    'sesers',
    'seserį',
    'seserų',
    'sesija',
    'sesiją',
    'sesute',
    'sesutė',
    'sesutę',
    'sezono',
    'sezoną',
    'sferas',
    'sferos',
    'siaubo',
    'siaubą',
    'siaura',
    'siauri',
    'siaurą',
    'siaurų',
    'siautė',
    'siejau',
    'siekia',
    'siekio',
    'siekis',
    'siekiu',
    'siekiu',
    'siekių',
    'siekta',
    'siekti',
    'siektų',
    'sielai',
    'sielas',
    'sieloj',
    'sielon',
    'sielos',
    'sienas',
    'sienoj',
    'sienos',
    'sifilį',
    'sijonu',
    'sijoną',
    'sijonų',
    'silkių',
    'silkės',
    'siloso',
    'silpna',
    'silpni',
    'silpno',
    'silpnu',
    'silpną',
    'silpnų',
    'sinodo',
    'sirena',
    'sirgau',
    'sirgti',
    'sirgus',
    'sirupu',
    'sirupą',
    'siunta',
    'siuntė',
    'siurbt',
    'siūlai',
    'siūlai',
    'siūlas',
    'siūlau',
    'siūles',
    'siūlus',
    'siūlės',
    'siūlęs',
    'siųsiu',
    'siųsti',
    'skaito',
    'skaitė',
    'skalbė',
    'skaldo',
    'skaldė',
    'skaldų',
    'skalpo',
    'skalės',
    'skamba',
    'skambi',
    'skania',
    'skanią',
    'skanus',
    'skanūs',
    'skarda',
    'skardą',
    'skaros',
    'skauda',
    'skaudi',
    'skaudu',
    'skelbė',
    'skelia',
    'skendi',
    'skendo',
    'skersa',
    'skiepo',
    'skiepų',
    'skilti',
    'skiltį',
    'skinti',
    'skiria',
    'skirta',
    'skirti',
    'skirti',
    'skirto',
    'skirtą',
    'skirtų',
    'sklido',
    'sklist',
    'sklypo',
    'sklypą',
    'sklypų',
    'skobnį',
    'skolai',
    'skolas',
    'skolos',
    'skonio',
    'skonis',
    'skoniu',
    'skrido',
    'skrisk',
    'skrist',
    'skubią',
    'skubių',
    'skubos',
    'skubus',
    'skubėk',
    'skubės',
    'skubėt',
    'skunde',
    'skundą',
    'skundė',
    'skurde',
    'skurdi',
    'skurdo',
    'skurdų',
    'skusta',
    'skutęs',
    'skvere',
    'skydas',
    'skydus',
    'skyles',
    'skylės',
    'skyrių',
    'skyręs',
    'skysti',
    'skysto',
    'skystu',
    'skystį',
    'skystų',
    'skėlęs',
    'skėtis',
    'skėčiu',
    'skęsta',
    'skųsti',
    'slapta',
    'slapto',
    'slaptu',
    'slaptą',
    'slaptų',
    'slauga',
    'slauną',
    'slavai',
    'slegia',
    'slenka',
    'slepia',
    'slinko',
    'slogių',
    'slogos',
    'slogus',
    'slysmo',
    'slysta',
    'slėgio',
    'slėgis',
    'slėgiu',
    'slėnio',
    'slėnis',
    'slėniu',
    'slėnių',
    'slėpti',
    'slūgso',
    'slūgti',
    'smagia',
    'smagių',
    'smagūs',
    'smailu',
    'smailė',
    'smakai',
    'smakro',
    'smakru',
    'smakus',
    'smarkų',
    'smarve',
    'smarvė',
    'smarvę',
    'smeigė',
    'smeigę',
    'smerkė',
    'smigti',
    'smilgų',
    'smiltį',
    'sminga',
    'smogta',
    'smuiko',
    'smuiku',
    'smuikų',
    'smukau',
    'smukti',
    'smulki',
    'smulku',
    'smulkų',
    'smunka',
    'smurto',
    'smurtu',
    'smurtą',
    'smėlio',
    'smėlis',
    'smėliu',
    'smūgio',
    'smūgis',
    'smūgiu',
    'smūgių',
    'snaigė',
    'snapai',
    'sniege',
    'sniego',
    'sniegu',
    'sniegą',
    'snigta',
    'sninga',
    'snukiu',
    'snūdas',
    'snūsta',
    'snūsti',
    'sodais',
    'sodams',
    'sodina',
    'sodini',
    'sodins',
    'sodrių',
    'sodyba',
    'sodybą',
    'sodybų',
    'sojoje',
    'solidi',
    'solidų',
    'sonatų',
    'sostai',
    'sostas',
    'sostus',
    'sočioj',
    'spalio',
    'spalių',
    'spalva',
    'spalvą',
    'spalvų',
    'spardo',
    'spardė',
    'sparno',
    'sparną',
    'sparnų',
    'sparta',
    'spartą',
    'spauda',
    'spaudi',
    'spaudą',
    'spaudė',
    'spausi',
    'spausk',
    'spaust',
    'spazmų',
    'spenio',
    'spenių',
    'sperma',
    'spindi',
    'spindį',
    'spinta',
    'spintą',
    'spiria',
    'spokso',
    'sporas',
    'sporte',
    'sporto',
    'sportu',
    'sportą',
    'spraga',
    'spragą',
    'spragų',
    'sprogo',
    'sprogs',
    'spruko',
    'spruks',
    'spręst',
    'spuogą',
    'spurda',
    'spynos',
    'spyriu',
    'spąstų',
    'spėjai',
    'spėjau',
    'spėjot',
    'spėjęs',
    'spėria',
    'spėsim',
    'spėsiu',
    'spūstį',
    'sraigė',
    'srauto',
    'srautą',
    'srautų',
    'sritim',
    'sritis',
    'sritys',
    'sriuba',
    'sriubą',
    'sričių',
    'srovei',
    'sroves',
    'srovių',
    'srovės',
    'sruoga',
    'sruogų',
    'srėbti',
    'stabai',
    'stabas',
    'stabdo',
    'stabdė',
    'staigi',
    'staigų',
    'stalai',
    'stalas',
    'stalių',
    'stalui',
    'stalus',
    'stambi',
    'stambų',
    'standų',
    'starta',
    'starto',
    'status',
    'statys',
    'statyt',
    'statūs',
    'stazės',
    'stačia',
    'stačią',
    'stačių',
    'stažas',
    'stažui',
    'stebis',
    'stebiu',
    'stebėk',
    'stelos',
    'stende',
    'stepių',
    'stiebų',
    'stigti',
    'stiklo',
    'stiklu',
    'stiklą',
    'stiklų',
    'stilių',
    'stinga',
    'stipkę',
    'stipri',
    'stipru',
    'stiprų',
    'stirna',
    'stirnų',
    'stogai',
    'stogas',
    'stogui',
    'stojam',
    'stojau',
    'stojus',
    'stokai',
    'stokis',
    'stokit',
    'stokos',
    'storas',
    'storio',
    'storis',
    'storos',
    'storus',
    'stotis',
    'stotis',
    'stotys',
    'stovai',
    'stovim',
    'stoviu',
    'stovėk',
    'stovės',
    'stovįs',
    'stočių',
    'streso',
    'stresą',
    'stresų',
    'stribų',
    'strigo',
    'strypo',
    'strėlė',
    'strėlę',
    'stuboj',
    'stulpo',
    'stulpų',
    'stumdo',
    'stumdė',
    'stumia',
    'stumiu',
    'stumti',
    'stverk',
    'stvėrė',
    'stvėrę',
    'stygių',
    'stūkso',
    'stūksą',
    'stūmio',
    'stūmos',
    'suardo',
    'suardė',
    'suarti',
    'subarė',
    'subyra',
    'subėga',
    'subėgo',
    'subėgs',
    'sucypė',
    'sudaro',
    'sudarė',
    'sudarę',
    'sudavė',
    'sudeda',
    'sudega',
    'sudegė',
    'sudurt',
    'sudužo',
    'sudygs',
    'sudyla',
    'sudėjo',
    'sudėję',
    'sudėsi',
    'sudėti',
    'sudėti',
    'sudėtu',
    'sudėtį',
    'sudėtų',
    'sudūgs',
    'sueina',
    'sueiti',
    'sugavę',
    'sugeba',
    'sugebu',
    'sugedo',
    'sugiję',
    'sugrįš',
    'sugrūs',
    'sugula',
    'sugulė',
    'sugurs',
    'sugėrė',
    'suimta',
    'suimti',
    'suimti',
    'suirtų',
    'suirus',
    'sujuda',
    'sujudo',
    'sujunk',
    'sukako',
    'sukaks',
    'sukalė',
    'sukasi',
    'sukels',
    'sukiši',
    'suknia',
    'suknią',
    'suknės',
    'sukosi',
    'suksim',
    'suktis',
    'sukurk',
    'sukčių',
    'sukėlė',
    'sukėlę',
    'sukūrė',
    'sulesa',
    'sulips',
    'sultis',
    'sultys',
    'sulygę',
    'sulčių',
    'sumals',
    'sumalę',
    'sumanė',
    'sumeta',
    'sumetė',
    'suminė',
    'sumišo',
    'sumišę',
    'sumoje',
    'sumojo',
    'sumoka',
    'sumoku',
    'sumuša',
    'sumuši',
    'sumušt',
    'sumušė',
    'suneša',
    'sunkia',
    'sunkio',
    'sunkis',
    'sunkiu',
    'sunkių',
    'sunkus',
    'sunkūs',
    'suokia',
    'suolas',
    'suomis',
    'suomių',
    'suopis',
    'supama',
    'supami',
    'supilk',
    'suposi',
    'supyko',
    'supyks',
    'supykę',
    'supylė',
    'surado',
    'suradę',
    'surast',
    'surijo',
    'suriko',
    'suruko',
    'suryja',
    'suryti',
    'surytų',
    'susekė',
    'susiję',
    'suspės',
    'sustos',
    'sustot',
    'susuka',
    'susėda',
    'susėdo',
    'susėdę',
    'sutana',
    'sutaną',
    'sutapo',
    'suteka',
    'sutemo',
    'sutemę',
    'sutepk',
    'sutepė',
    'suterš',
    'sutiko',
    'sutiks',
    'sutikt',
    'sutikę',
    'sutūpė',
    'suvarė',
    'suveda',
    'suvedė',
    'suvežė',
    'suvokė',
    'suvokę',
    'suėjęs',
    'suėmus',
    'suėmęs',
    'suėsti',
    'sušalo',
    'sušalę',
    'sušoko',
    'sušuko',
    'sužino',
    'sužėri',
    'svaigo',
    'svajos',
    'svarba',
    'svarbi',
    'svarbu',
    'svarbą',
    'svarbų',
    'svarių',
    'svarus',
    'sveika',
    'sveiki',
    'sveiko',
    'sveiku',
    'sveiką',
    'sveikų',
    'sveria',
    'sverti',
    'sverti',
    'svečio',
    'svečiu',
    'svečią',
    'svečią',
    'svečių',
    'sviedė',
    'sviesi',
    'svieto',
    'svirno',
    'svirną',
    'svorio',
    'svoris',
    'svoriu',
    'svočią',
    'svylam',
    'sykius',
    'sąaugų',
    'sąjūdį',
    'sąlyga',
    'sąlygą',
    'sąlygų',
    'sąlytį',
    'sąmatą',
    'sąmatų',
    'sąmone',
    'sąmonė',
    'sąmonę',
    'sąmyšį',
    'sąnarį',
    'sąraše',
    'sąrašo',
    'sąrašu',
    'sąrašą',
    'sąsaja',
    'sąsają',
    'sąsajų',
    'sąvadą',
    'sąvoka',
    'sąvoką',
    'sąvokų',
    'sąžine',
    'sąžinė',
    'sąžinę',
    'sėbrai',
    'sėbrui',
    'sėdimo',
    'sėdosi',
    'sėdynę',
    'sėdėjo',
    'sėdėsi',
    'sėdėti',
    'sėdėtų',
    'sėdžiu',
    'sėjasi',
    'sėjimo',
    'sėjėjo',
    'sėklai',
    'sėklas',
    'sėklos',
    'sėkmei',
    'sėkmės',
    'sėlenų',
    'sėlina',
    'sėmenų',
    'sėmėsi',
    'sėskis',
    'sėsliu',
    'sėstis',
    'sūdytų',
    'sūkurį',
    'sūnaus',
    'sūneli',
    'sūnelį',
    'sūnumi',
    'sūnums',
    'sūriai',
    'sūrius',
    'sūroką',
    'sūrumo',
    'sūrymu',
    'tabako',
    'taboka',
    'taboką',
    'taigos',
    'taikon',
    'taikos',
    'taikus',
    'taikyk',
    'taikūs',
    'taisai',
    'taisys',
    'taisęs',
    'takais',
    'takelį',
    'taksai',
    'taksiu',
    'taksus',
    'taktai',
    'taktas',
    'taktui',
    'talija',
    'taliją',
    'talpos',
    'tamprų',
    'tamsia',
    'tamsią',
    'tamsių',
    'tamsoj',
    'tamsos',
    'tamsta',
    'tamstą',
    'tamsus',
    'tamsūs',
    'tankai',
    'tankia',
    'tankio',
    'tankiu',
    'tankių',
    'tankmę',
    'tankus',
    'tankūs',
    'tapati',
    'tapatu',
    'tapome',
    'tapote',
    'tapusi',
    'tapusį',
    'tapyba',
    'tapybą',
    'tapytų',
    'tariam',
    'tariat',
    'tariau',
    'tarifų',
    'tarimų',
    'tarkim',
    'tarmei',
    'tarmes',
    'tarmių',
    'tarmės',
    'tarnas',
    'tarnui',
    'taroje',
    'tarpas',
    'tarpti',
    'tarpus',
    'tartis',
    'tartis',
    'taryba',
    'tarybą',
    'tarybų',
    'tarėsi',
    'taršai',
    'taršos',
    'taukus',
    'taupau',
    'taupus',
    'taupūs',
    'tauras',
    'taures',
    'taurių',
    'taurui',
    'taurus',
    'taurėm',
    'taurės',
    'tautai',
    'tautas',
    'tautos',
    'tavimi',
    'tavąjį',
    'taškai',
    'taškas',
    'taškus',
    'teatre',
    'teatro',
    'teatrą',
    'teatrų',
    'tebesi',
    'tebesu',
    'tebuvo',
    'tebėra',
    'teeina',
    'tegali',
    'teigia',
    'teigti',
    'teikia',
    'teikta',
    'teikto',
    'teiktų',
    'teikęs',
    'teisei',
    'teises',
    'teisių',
    'teisme',
    'teismo',
    'teismą',
    'teismų',
    'teisti',
    'teistu',
    'teistą',
    'teistų',
    'teisus',
    'teisės',
    'teisūs',
    'tekina',
    'tekste',
    'teksto',
    'tekstu',
    'tekstą',
    'tekstų',
    'tekėjo',
    'tekėti',
    'teliką',
    'telkšo',
    'temato',
    'tembro',
    'temoje',
    'temoms',
    'tempai',
    'tempas',
    'tempia',
    'tempti',
    'tempui',
    'temsta',
    'teniso',
    'tenoro',
    'tepalo',
    'tepalu',
    'tepalą',
    'terasa',
    'terasų',
    'terbos',
    'teroro',
    'terpei',
    'terpės',
    'teršia',
    'teršti',
    'testai',
    'testas',
    'testus',
    'tesėti',
    'tetuli',
    'tetulę',
    'teturi',
    'tezėse',
    'tešlai',
    'tešmuo',
    'težino',
    'tiekia',
    'tiesas',
    'tiesia',
    'tiesiu',
    'tiesom',
    'tiesos',
    'tiesti',
    'tiesus',
    'tigrai',
    'tikima',
    'tikime',
    'tikimą',
    'tikina',
    'tikino',
    'tikint',
    'tikisi',
    'tikrai',
    'tikram',
    'tikras',
    'tikros',
    'tikrus',
    'tiksli',
    'tikslo',
    'tikslu',
    'tikslu',
    'tikslą',
    'tikslų',
    'tikslų',
    'tikyba',
    'tikybą',
    'tikėjo',
    'tikėsi',
    'tikėta',
    'tikėti',
    'tilptų',
    'tiltai',
    'tiltas',
    'tiltus',
    'tingim',
    'tingiu',
    'tingūs',
    'tinkle',
    'tinklo',
    'tinklu',
    'tinklą',
    'tinklų',
    'tinkąs',
    'tinsta',
    'tipams',
    'tipelį',
    'tipeno',
    'tipinę',
    'tiradą',
    'tirpdo',
    'tirpdė',
    'tirpių',
    'tirpti',
    'tirtis',
    'tirtos',
    'tiršta',
    'tiršto',
    'tirštu',
    'tirštą',
    'titulo',
    'titulą',
    'tobula',
    'tobuli',
    'tobulu',
    'tobulą',
    'tobulų',
    'tokiai',
    'tokiam',
    'tokias',
    'tokiem',
    'tokioj',
    'tokios',
    'tokius',
    'toksai',
    'tolima',
    'tolimi',
    'tolimą',
    'tolimų',
    'tolsta',
    'tolygu',
    'tortas',
    'trakte',
    'trakto',
    'traktą',
    'tramdė',
    'tranas',
    'trapią',
    'trapūs',
    'trasas',
    'trauka',
    'trauks',
    'traukė',
    'trauma',
    'traumą',
    'traumų',
    'trejas',
    'trejus',
    'trendo',
    'trenkė',
    'trečia',
    'trečio',
    'trečiu',
    'trečią',
    'trečių',
    'treška',
    'triadą',
    'trikdo',
    'trikdė',
    'trimis',
    'trintų',
    'triūbą',
    'triūso',
    'triūsą',
    'triūsė',
    'trobas',
    'troboj',
    'trobos',
    'troško',
    'troškę',
    'trukdo',
    'trukme',
    'trukmė',
    'trukmę',
    'trukti',
    'truktų',
    'trukęs',
    'trumpa',
    'trumpi',
    'trumpu',
    'trumpą',
    'trumpų',
    'trunka',
    'trynys',
    'trąšai',
    'trąšas',
    'trąšom',
    'trąšos',
    'trąšūs',
    'tręšta',
    'trūkti',
    'trūktų',
    'tulpes',
    'tulpės',
    'tulžio',
    'tulžis',
    'tulžių',
    'tulžys',
    'tundrų',
    'tunelį',
    'tungas',
    'tuntas',
    'tupint',
    'tupėjo',
    'turgui',
    'turgus',
    'turgūs',
    'turima',
    'turime',
    'turimi',
    'turimu',
    'turimą',
    'turimų',
    'turine',
    'turint',
    'turiny',
    'turinį',
    'turite',
    'turtas',
    'turtui',
    'turtus',
    'turėjo',
    'turėję',
    'turėsi',
    'turėti',
    'turėtų',
    'tuščia',
    'tuščio',
    'tuščią',
    'tuščių',
    'tvaiką',
    'tvanai',
    'tvanku',
    'tvarka',
    'tvarko',
    'tvarką',
    'tvarkė',
    'tvarte',
    'tvarto',
    'tvartą',
    'tvartų',
    'tverti',
    'tvirta',
    'tvirto',
    'tvirtu',
    'tvirtą',
    'tvirtų',
    'tviska',
    'tvojęs',
    'tvoras',
    'tvoros',
    'tvyrąs',
    'tykina',
    'tykojo',
    'tykšta',
    'tylaus',
    'tyleni',
    'tylint',
    'tylios',
    'tyloje',
    'tylėjo',
    'tylėti',
    'tylėtų',
    'tymais',
    'tyrime',
    'tyrimo',
    'tyrimą',
    'tyrimų',
    'tyrumo',
    'tyrėjų',
    'tysojo',
    'tysoti',
    'tyčinė',
    'tyčinį',
    'tyčios',
    'tąsoje',
    'tėkmei',
    'tėkmės',
    'tėtuli',
    'tėvais',
    'tėvams',
    'tėveli',
    'tėvelį',
    'tėvija',
    'tėvuko',
    'tėvukų',
    'tėvyne',
    'tėvynė',
    'tėvynę',
    'tėčiai',
    'tęsimo',
    'tęsinį',
    'tęstis',
    'tęsėsi',
    'tįsumą',
    'tūnojo',
    'tūriui',
    'tūryje',
    'ugdant',
    'ugdoma',
    'ugdymo',
    'ugdymu',
    'ugdymą',
    'ugdyti',
    'ugdytų',
    'ugnelė',
    'ugnelę',
    'ugnies',
    'ugnimi',
    'ugnyje',
    'ulbėjo',
    'ulyčią',
    'unijas',
    'unijos',
    'uodega',
    'uodegą',
    'uodėme',
    'uodžia',
    'uogytė',
    'uolaus',
    'uolumo',
    'uolumu',
    'uostas',
    'uosyje',
    'uosyne',
    'uošvio',
    'uošvis',
    'upelio',
    'upeliu',
    'upelių',
    'upelės',
    'upines',
    'upinių',
    'upėmis',
    'utėles',
    'utėlių',
    'uzbono',
    'užakus',
    'užanka',
    'užantį',
    'užarti',
    'užauga',
    'užaugo',
    'užaugs',
    'užaugę',
    'užbėga',
    'užbėgo',
    'užbūrė',
    'uždara',
    'uždari',
    'uždaro',
    'uždaru',
    'uždarą',
    'uždarė',
    'uždarų',
    'uždeda',
    'uždega',
    'uždegė',
    'uždenk',
    'uždėjo',
    'uždėti',
    'užeina',
    'užeiti',
    'užgeso',
    'užgims',
    'užgros',
    'užgulu',
    'užgulę',
    'užgyja',
    'užgyti',
    'užgėrė',
    'užimta',
    'užimti',
    'užimtą',
    'užimtų',
    'užkasė',
    'užkišt',
    'užkopė',
    'užkuri',
    'užkurt',
    'užkėlė',
    'užkūrė',
    'užlies',
    'užlipa',
    'užlipi',
    'užlipo',
    'užlips',
    'užlūžo',
    'užmeta',
    'užmetu',
    'užmetė',
    'užmigo',
    'užmigę',
    'užmojo',
    'užmojų',
    'užmoka',
    'užmuša',
    'užmušk',
    'užmušė',
    'užnešė',
    'užpila',
    'užpilk',
    'užpils',
    'užpylė',
    'užrašo',
    'užrašo',
    'užrašą',
    'užrašė',
    'užrašę',
    'užrašų',
    'užriks',
    'užriša',
    'užsako',
    'užsakų',
    'užsega',
    'užstos',
    'užsuko',
    'užsuks',
    'užsuku',
    'užsukę',
    'užsėdo',
    'užtarė',
    'užteko',
    'užteks',
    'užtepa',
    'užtiko',
    'užtilo',
    'užuodė',
    'užupio',
    'užvedu',
    'užvirė',
    'užėjus',
    'užšalo',
    'užšalę',
    'užšoka',
    'užšąla',
    'vabalu',
    'vabalų',
    'vadais',
    'vadams',
    'vadina',
    'vadink',
    'vadino',
    'vadins',
    'vadinu',
    'vadoms',
    'vadove',
    'vadovo',
    'vadovu',
    'vadovą',
    'vadovė',
    'vadovų',
    'vadyba',
    'vagies',
    'vagims',
    'vagiui',
    'vagoje',
    'vagone',
    'vagono',
    'vagoną',
    'vagonų',
    'vaikai',
    'vaikam',
    'vaikas',
    'vaikis',
    'vaikui',
    'vaikus',
    'vaikšo',
    'vairas',
    'vaisių',
    'vaisto',
    'vaistų',
    'vaizde',
    'vaizdo',
    'vaizdu',
    'vaizdą',
    'vaizdų',
    'vaišes',
    'vaišių',
    'vaišės',
    'vakare',
    'vakaro',
    'vakaru',
    'vakarą',
    'vakarų',
    'valakų',
    'valdas',
    'valdyt',
    'valdęs',
    'valgai',
    'valgau',
    'valgei',
    'valgio',
    'valgis',
    'valgių',
    'valgom',
    'valgyk',
    'valgys',
    'valgyt',
    'valgęs',
    'valiai',
    'valiau',
    'valios',
    'valius',
    'valizą',
    'valomi',
    'valosi',
    'valsas',
    'valtis',
    'valtys',
    'valuže',
    'valymo',
    'valymą',
    'valyti',
    'vamzdį',
    'vanago',
    'vanagu',
    'vanduo',
    'vangūs',
    'vanile',
    'vantas',
    'vantos',
    'vapėjo',
    'vardai',
    'vardas',
    'vardui',
    'vardus',
    'vargas',
    'vargti',
    'vargus',
    'vargšo',
    'vargšą',
    'vargšė',
    'vargšę',
    'vargšų',
    'varinė',
    'varinę',
    'varles',
    'varlių',
    'varlės',
    'varnas',
    'varnos',
    'varnui',
    'varnės',
    'varomi',
    'varomų',
    'varpai',
    'varpas',
    'varpos',
    'varsnų',
    'varsto',
    'varstų',
    'vartai',
    'vartai',
    'vartus',
    'vartys',
    'vartyt',
    'varymo',
    'varyti',
    'varškė',
    'varškę',
    'varžtų',
    'vasara',
    'vasarą',
    'vasarį',
    'vasarų',
    'vatoje',
    'vazonų',
    'vaškas',
    'vedama',
    'vedami',
    'vedamų',
    'vedant',
    'vedasi',
    'vedimo',
    'vedlei',
    'vedlys',
    'vedlės',
    'vedybų',
    'vedėja',
    'vedėjo',
    'vedėju',
    'vedėją',
    'vedėjų',
    'veidai',
    'veidan',
    'veidas',
    'veidus',
    'veikei',
    'veikia',
    'veikiu',
    'veikla',
    'veiklą',
    'veiklų',
    'veiksi',
    'veikti',
    'veiktų',
    'veisia',
    'veislė',
    'veislę',
    'veisti',
    'vejasi',
    'veleno',
    'velnio',
    'velniu',
    'velnią',
    'velnių',
    'velėna',
    'vengia',
    'vengiu',
    'vengro',
    'vengrų',
    'vengti',
    'vengtų',
    'veninį',
    'vergas',
    'vergių',
    'vergės',
    'verkei',
    'verkia',
    'verkti',
    'verkėt',
    'verkęs',
    'verpia',
    'versle',
    'verslo',
    'verslu',
    'verslą',
    'verslų',
    'versti',
    'verstų',
    'vertas',
    'vertei',
    'vertes',
    'vertos',
    'vertės',
    'verčia',
    'verčių',
    'veršio',
    'veršis',
    'veržle',
    'veržli',
    'veržlė',
    'veržlę',
    'veržti',
    'vestis',
    'vešlių',
    'vešlus',
    'vešlūs',
    'vežami',
    'vežant',
    'vežasi',
    'vežime',
    'vežimo',
    'vežimu',
    'vežimą',
    'vežimų',
    'vežėju',
    'vežėją',
    'vežėjų',
    'vežėsi',
    'vidaus',
    'vidine',
    'vidinė',
    'vidinę',
    'vidinį',
    'viduje',
    'vidumi',
    'vidury',
    'vidurį',
    'vielom',
    'vielos',
    'vienai',
    'vienam',
    'vienam',
    'vienam',
    'vienas',
    'vienas',
    'vienas',
    'vienis',
    'vienoj',
    'vienon',
    'vienos',
    'vienos',
    'vienos',
    'vienus',
    'vienut',
    'vietai',
    'vietas',
    'vieton',
    'vietos',
    'viešai',
    'viešas',
    'viešos',
    'vikaro',
    'vikaru',
    'vikarą',
    'vikarų',
    'vikrių',
    'vikrūs',
    'vikšrą',
    'vilkai',
    'vilkas',
    'vilkis',
    'vilkui',
    'vilkus',
    'vilnas',
    'vilnos',
    'vilose',
    'viltis',
    'viltys',
    'vilčių',
    'vingio',
    'vingis',
    'vingiu',
    'vingių',
    'vinies',
    'vinimi',
    'viralo',
    'viralą',
    'virpėt',
    'virsit',
    'virsmą',
    'virsta',
    'virsti',
    'virstų',
    'virtas',
    'virtos',
    'viruso',
    'virusu',
    'virusų',
    'virves',
    'virvės',
    'viršum',
    'viršus',
    'visais',
    'visame',
    'visata',
    'visatą',
    'visiem',
    'viskam',
    'viskas',
    'viskio',
    'viskuo',
    'visoje',
    'visoks',
    'visokį',
    'visoms',
    'visose',
    'visuma',
    'visumą',
    'vizija',
    'viziją',
    'vizijų',
    'vizito',
    'vizitu',
    'vizitą',
    'vizitų',
    'vištas',
    'vištoj',
    'vištos',
    'vogtam',
    'vogtas',
    'volofų',
    'vonioj',
    'vonios',
    'voverė',
    'vydavo',
    'vykdys',
    'vyksta',
    'vykusį',
    'vylėsi',
    'vypsai',
    'vyrais',
    'vyrams',
    'vyravo',
    'vyravę',
    'vyruko',
    'vyruku',
    'vyrukų',
    'vyruti',
    'vytine',
    'vytinė',
    'vytinę',
    'vyšnia',
    'vyšnių',
    'vėdarų',
    'vėdina',
    'vėjais',
    'vėlais',
    'vėlavo',
    'vėliau',
    'vėluos',
    'vėlyva',
    'vėlyvi',
    'vėlyvo',
    'vėlyvą',
    'vėmimo',
    'vėplio',
    'vėsaus',
    'vėsias',
    'vėsius',
    'vėsoka',
    'vėtant',
    'vėžiai',
    'vėžiui',
    'vėžius',
    'vėžlio',
    'vėžliu',
    'vėžlių',
    'vėžlys',
    'vėžėse',
    'ydinga',
    'zakonų',
    'zenite',
    'zibalų',
    'zondas',
    'zonoje',
    'zonose',
    'zuikis',
    'zulink',
    'zylėms',
    'ąžuolo',
    'ąžuolu',
    'ąžuolą',
    'ąžuolų',
    'čekiai',
    'čekius',
    'čerpių',
    'čečėnų',
    'čiaupo',
    'čiaupą',
    'čigonė',
    'čirškė',
    'čiumpa',
    'čiupęs',
    'čiurlį',
    'čiurnų',
    'ėdžias',
    'ėdžios',
    'ėjimas',
    'ėjimui',
    'ėmusis',
    'ėmėmės',
    'ėsdavo',
    'įaugęs',
    'įausta',
    'įbedęs',
    'įbrido',
    'įbruka',
    'įbruko',
    'įdavęs',
    'įdiegė',
    'įdirbį',
    'įdomią',
    'įdomių',
    'įdomus',
    'įdomūs',
    'įdubęs',
    'įduoti',
    'įduotą',
    'įdėjai',
    'įeikit',
    'įgauna',
    'įgauti',
    'įgelti',
    'įgeltą',
    'įgijai',
    'įgijęs',
    'įgimto',
    'įgimtą',
    'įgriso',
    'įgrūdo',
    'įgrūst',
    'įguldė',
    'įgulos',
    'įgytas',
    'įgytos',
    'įgėlus',
    'įjungt',
    'įjungė',
    'įkainį',
    'įkaitu',
    'įkalnė',
    'įkalta',
    'įkalėm',
    'įkasti',
    'įkelta',
    'įkišai',
    'įkišti',
    'įkištų',
    'įkišus',
    'įkišęs',
    'įklotą',
    'įkopti',
    'įkrovą',
    'įkurta',
    'įkurti',
    'įkurti',
    'įkurto',
    'įkvėpė',
    'įkyriu',
    'įkyrių',
    'įkyrus',
    'įkąsta',
    'įkąstų',
    'įkėlęs',
    'įkūrus',
    'įkūręs',
    'įlanka',
    'įlanką',
    'įlankų',
    'įleidę',
    'įlekia',
    'įlindo',
    'įlipam',
    'įlipti',
    'įlipęs',
    'įmetus',
    'įmetęs',
    'įmokas',
    'įmokos',
    'įmonei',
    'įmones',
    'įmonių',
    'įmonėj',
    'įmonės',
    'įnagio',
    'įnašai',
    'įnašas',
    'įnašus',
    'įnešti',
    'įnikau',
    'įniršį',
    'įnorių',
    'įpratę',
    'įpuola',
    'įpuolė',
    'įpūsta',
    'įranga',
    'įrangą',
    'įrankį',
    'įrašai',
    'įrašas',
    'įrašei',
    'įrašom',
    'įrašui',
    'įrašus',
    'įrašyk',
    'įrašyt',
    'įrašėm',
    'įremia',
    'įrieda',
    'įrišus',
    'įrodau',
    'įrodys',
    'įrėžęs',
    'įsakęs',
    'įsidėk',
    'įsiuto',
    'įsiėdė',
    'įsiūti',
    'įskilo',
    'įsmigo',
    'įsmuko',
    'įspėja',
    'įspėjo',
    'įspėję',
    'įspėti',
    'įspėti',
    'įspūdį',
    'įstabi',
    'įstato',
    'įstatų',
    'įstoja',
    'įstojo',
    'įstoję',
    'įstota',
    'įstoti',
    'įstūmė',
    'įstūmę',
    'įsukto',
    'įsuktų',
    'įsunkę',
    'įsčias',
    'įsūnis',
    'įsūniu',
    'įtaigi',
    'įtaigų',
    'įtaiso',
    'įtaisų',
    'įtakai',
    'įtakos',
    'įtampa',
    'įtampą',
    'įtaria',
    'įtariu',
    'įtarta',
    'įtarti',
    'įtarti',
    'įtartų',
    'įtarus',
    'įtaręs',
    'įteiks',
    'įteikė',
    'įtikti',
    'įtrink',
    'įtrins',
    'įtūžio',
    'įvadas',
    'įvaikį',
    'įvairi',
    'įvairų',
    'įvaldė',
    'įvaldę',
    'įveiką',
    'įveikė',
    'įveria',
    'įvesta',
    'įvesti',
    'įvežta',
    'įvežti',
    'įvores',
    'įvykdė',
    'įvykio',
    'įvykis',
    'įvykiu',
    'įvykių',
    'įvykti',
    'įvyktų',
    'įvykus',
    'įvykęs',
    'įėjimo',
    'įėjusi',
    'įšalas',
    'įšilus',
    'įžangų',
    'įžeidė',
    'įžengė',
    'įžengę',
    'įžiūri',
    'įžymus',
    'įžymūs',
    'įžūlūs',
    'šachtų',
    'šakinė',
    'šaknis',
    'šaknys',
    'šakose',
    'šakutę',
    'šaliai',
    'šalies',
    'šalimi',
    'šalims',
    'šalimu',
    'šalnos',
    'šaltas',
    'šaltis',
    'šaltos',
    'šaltus',
    'šalyje',
    'šalyse',
    'šalčio',
    'šalčiu',
    'šalčių',
    'šamano',
    'šamanų',
    'šansus',
    'šapalo',
    'šarkos',
    'šarmus',
    'šaudai',
    'šaudyk',
    'šaudęs',
    'šaukia',
    'šaukiu',
    'šauksi',
    'šaukti',
    'šauktų',
    'šaulio',
    'šaulių',
    'šaulys',
    'šaunių',
    'šaunus',
    'šaunūs',
    'šausim',
    'šausiu',
    'šašams',
    'šeimai',
    'šeimas',
    'šeimoj',
    'šeimos',
    'šepetį',
    'šerdis',
    'šeriai',
    'šernai',
    'šernas',
    'šernus',
    'šernės',
    'šešeri',
    'šešias',
    'šešios',
    'šeštai',
    'šeštam',
    'šeštas',
    'šeštoj',
    'šeštos',
    'šešėlį',
    'šiaudo',
    'šiaudų',
    'šiaurė',
    'šiaurę',
    'šienas',
    'šienui',
    'šiknių',
    'šilelį',
    'šilkas',
    'šiltas',
    'šiltoj',
    'šiltos',
    'šiltus',
    'šiluma',
    'šilumą',
    'šimtai',
    'šimtas',
    'šimtus',
    'šiokiu',
    'šiokią',
    'šiomis',
    'širdim',
    'širdin',
    'širdis',
    'širdyj',
    'širdys',
    'širmas',
    'širsta',
    'širšių',
    'širšės',
    'šitais',
    'šitame',
    'šitiem',
    'šitoje',
    'šitoks',
    'šitokį',
    'šituos',
    'šiuose',
    'šiurpi',
    'šiurpu',
    'šlaite',
    'šlaito',
    'šlaitu',
    'šlaitą',
    'šlaitų',
    'šlapia',
    'šlapio',
    'šlapiu',
    'šlapią',
    'šlapių',
    'šlaunų',
    'šleifo',
    'šliurė',
    'šliužų',
    'šliūbo',
    'šliūžė',
    'šlovės',
    'šluoja',
    'šluota',
    'šluoti',
    'šluotą',
    'šlynan',
    'šmikis',
    'šmėklą',
    'šmėklų',
    'šmėkso',
    'šnekam',
    'šnekas',
    'šnekat',
    'šnekos',
    'šnekėk',
    'šnekės',
    'šnekėt',
    'šokiai',
    'šokimo',
    'šokius',
    'šoksiu',
    'šokėjų',
    'šonais',
    'šoninė',
    'šoninį',
    'šovusi',
    'špagos',
    'špilio',
    'špiliu',
    'špokai',
    'špokus',
    'šratai',
    'šriftu',
    'šriftą',
    'šriftų',
    'šriubų',
    'štabas',
    'štampo',
    'šulinį',
    'šumerų',
    'šuneli',
    'šunimi',
    'šunims',
    'šuniui',
    'šunyti',
    'šunėko',
    'šunėką',
    'šuolis',
    'šuoras',
    'švariu',
    'švarią',
    'švarko',
    'švarką',
    'švarus',
    'švarūs',
    'švedai',
    'šveisk',
    'šveitė',
    'švelni',
    'švelnų',
    'šventa',
    'šventi',
    'švento',
    'šventu',
    'šventą',
    'šventė',
    'šventę',
    'šventų',
    'šviesa',
    'šviesi',
    'šviest',
    'šviesu',
    'šviesą',
    'šviesų',
    'šviesų',
    'švietė',
    'švieži',
    'švilpt',
    'švinta',
    'švisti',
    'švęsit',
    'švęsti',
    'švęstu',
    'šypsnį',
    'šypsos',
    'šėlimo',
    'šėlsmą',
    'šėrimo',
    'šėrimą',
    'šėrimų',
    'šėtono',
    'šėtonu',
    'šėtoną',
    'šūkavo',
    'šūkiai',
    'šūkius',
    'šūviai',
    'šūviui',
    'šūvius',
    'ūglius',
    'ūkanos',
    'ūkiais',
    'ūkiams',
    'ūkines',
    'ūkinio',
    'ūkinis',
    'ūkinių',
    'ūkinės',
    'ūkiški',
    'ūkiško',
    'ūkišku',
    'ūkteli',
    'ūminis',
    'ūminiu',
    'ūminių',
    'ūžesys',
    'ūžimas',
    'žadama',
    'žadate',
    'žadino',
    'žadėjo',
    'žagrės',
    'žaibas',
    'žaibus',
    'žaislą',
    'žaislų',
    'žaismė',
    'žaisti',
    'žaizda',
    'žaizdų',
    'žaliai',
    'žalias',
    'žaliom',
    'žalios',
    'žalius',
    'žaloja',
    'žalsva',
    'žalsvi',
    'žalsvo',
    'žalsvą',
    'žaltys',
    'žaluma',
    'žalčio',
    'žalųjų',
    'žandai',
    'žanrai',
    'žanras',
    'žarijų',
    'žarnas',
    'žarnos',
    'žarose',
    'žarsto',
    'žarstė',
    'žavesį',
    'žavisi',
    'žavėjo',
    'žemelė',
    'žemelę',
    'žemina',
    'žemoje',
    'žemumų',
    'žemyne',
    'žemyno',
    'žemėje',
    'žemėms',
    'žemėse',
    'žengia',
    'žengti',
    'ženklo',
    'ženklu',
    'ženklą',
    'ženklų',
    'žentas',
    'žentus',
    'žiaunų',
    'žiauri',
    'žiauru',
    'žiaurų',
    'žibalo',
    'žibutė',
    'židiny',
    'židinį',
    'žiebia',
    'žiedai',
    'žiedas',
    'žiedus',
    'žiemai',
    'žiemas',
    'žiemos',
    'žievės',
    'žiloje',
    'žinant',
    'žindęs',
    'žinias',
    'žinios',
    'žinojo',
    'žinoma',
    'žinome',
    'žinomi',
    'žinomą',
    'žinomų',
    'žinosi',
    'žinote',
    'žinoti',
    'žinotų',
    'žinovo',
    'žinovė',
    'žinovų',
    'žinute',
    'žinutė',
    'žinutę',
    'žinybų',
    'žinynų',
    'žiogai',
    'žiotys',
    'žiočių',
    'žirafa',
    'žirafą',
    'žirafų',
    'žirgai',
    'žirgas',
    'žirnio',
    'žirnių',
    'žiurkė',
    'žiurkę',
    'žiūrim',
    'žiūrit',
    'žiūriu',
    'žiūrėk',
    'žiūrės',
    'žiūrėt',
    'žlegsi',
    'žlibas',
    'žlibių',
    'žlibės',
    'žlugdo',
    'žlugti',
    'žlugto',
    'žlugus',
    'žlunga',
    'žmogau',
    'žmogui',
    'žmogus',
    'žmonai',
    'žmones',
    'žmonių',
    'žmonos',
    'žmonėm',
    'žmonės',
    'žodelį',
    'žodine',
    'žodinė',
    'žodyje',
    'žodyne',
    'žodyno',
    'žodynu',
    'žodyną',
    'žodynų',
    'žodžio',
    'žodžiu',
    'žodžių',
    'žoline',
    'žolyne',
    'žolytė',
    'žolėje',
    'žolėms',
    'žudiko',
    'žudikę',
    'žudikų',
    'žudomi',
    'žudymo',
    'žudymą',
    'žudyti',
    'žuvavo',
    'žuvele',
    'žuvelė',
    'žuvelę',
    'žuvies',
    'žuvimi',
    'žuvimo',
    'žuvims',
    'žuvimu',
    'žuvusi',
    'žuvyje',
    'žuvytė',
    'žvakes',
    'žvakių',
    'žvakės',
    'žvalgų',
    'žvalūs',
    'žvanga',
    'žvejai',
    'žvejys',
    'žvelgi',
    'žvelgė',
    'žvynai',
    'žvynus',
    'žvyras',
    'žvėrie',
    'žvėris',
    'žvėrių',
    'žvėrys',
    'žydrės',
    'žydėjo',
    'žydėti',
    'žydėtų',
    'žygiai',
    'žygiui',
    'žygius',
    'žymima',
    'žymimi',
    'žyminį',
    'žymios',
    'žymius',
    'žymėjo',
    'žymėti',
    'žymėtų',
    'žyniai',
    'žąsims',
    'žąslai',
    'žėrėjo',
    'žūklės',
    'žūtimi',
]
